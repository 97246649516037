import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useLayoutEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useBoard from '@hooks/useBoard'
import useSessionStorage from '@hooks/useSessionStorage'
import { useUpdatesEvents } from '../analytics/useUpdatesEvents'
import { getIsLoading, selectBoardsCount } from '../../../selectors'
import { QUERY_PARAMS } from '../../constants/routes'
import { SESSION_STORAGE_CONSTANTS } from '../../../constants/sessionStoage'

export type UpdatesToolsState = {
  isOpen: boolean
  shouldAutoOpenMessagesSection: boolean
  setShouldAutoOpenMessagesSection: (value: boolean) => void
  isAvailable: boolean
  disabled: boolean
  close: () => void
  toggle: () => void
}

export const enum SHOW_MESSAGE_TYPE {
  AUTO = 'auto',
  MANUAL = 'manual',
}

const defaultState: UpdatesToolsState = {
  isOpen: false,
  shouldAutoOpenMessagesSection: false,
  setShouldAutoOpenMessagesSection: () => {},
  isAvailable: false,
  disabled: false,
  close: () => {},
  toggle: () => {},
}

export const UpdatesContext = createContext<UpdatesToolsState>({
  ...defaultState,
})

const UpdatesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { updatesPaneClose } = useUpdatesEvents()
  const [searchParams, setSearchParams] = useSearchParams()
  const loading = useSelectorFactory(getIsLoading)
  const boardsCount = useSelectorFactory(selectBoardsCount)

  const {
    totalBoardMembersCount,
    boardId,
    isEditor,
    isOwnBoard,
    totalBoardMessagesCount,
  } = useBoard()

  const isOpen = useMemo(
    () => searchParams.has(QUERY_PARAMS.showMessage),
    [searchParams],
  )
  const [shouldAutoOpenMessagesSection, setShouldAutoOpenMessagesSection] =
    useState(false)

  const [userMessageFeedPreferences, setUserMessageFeedPreferences] =
    useSessionStorage<{ manuallyOpened: string[]; manuallyClosed: string[] }>(
      SESSION_STORAGE_CONSTANTS.userMessageFeedPreferences,
      { manuallyOpened: [], manuallyClosed: [] },
    )

  const open = useCallback(
    (type = SHOW_MESSAGE_TYPE.MANUAL) => {
      if (type === SHOW_MESSAGE_TYPE.MANUAL) {
        setShouldAutoOpenMessagesSection(false)
        setUserMessageFeedPreferences((prev) => ({
          manuallyOpened: [...prev.manuallyOpened, boardId],
          manuallyClosed: prev.manuallyClosed.filter(
            (value: string) => value !== boardId,
          ),
        }))
      } else if (type === SHOW_MESSAGE_TYPE.AUTO) {
        setShouldAutoOpenMessagesSection(true)
      }
      searchParams.set(QUERY_PARAMS.showMessage, 'true')
      setSearchParams(searchParams, { replace: true })
    },
    [setSearchParams, searchParams, setUserMessageFeedPreferences, boardId],
  )

  const close = useCallback(() => {
    updatesPaneClose()
    setShouldAutoOpenMessagesSection(false)
    searchParams.delete(QUERY_PARAMS.showMessage)
    setSearchParams(searchParams, { replace: true })
    setUserMessageFeedPreferences((prev) => ({
      manuallyOpened: prev.manuallyOpened.filter(
        (value: string) => value !== boardId,
      ),
      manuallyClosed: [...prev.manuallyClosed, boardId],
    }))
  }, [
    updatesPaneClose,
    boardId,
    searchParams,
    setSearchParams,
    setUserMessageFeedPreferences,
  ])

  const toggle = useCallback(
    () => (isOpen ? close() : open()),
    [isOpen, open, close],
  )

  const handleMessageFeedVisibilityWhenBoardChange = useCallback(() => {
    const { manuallyOpened, manuallyClosed } = userMessageFeedPreferences

    // Check user preferences first
    if (manuallyOpened.includes(boardId)) {
      open(SHOW_MESSAGE_TYPE.AUTO)
      return
    }

    if (manuallyClosed.includes(boardId)) {
      return
    }

    // Apply visibility logic if there is no user preference
    const shouldOpenAutomatically =
      ((isEditor || isOwnBoard) && totalBoardMembersCount > 0) ||
      totalBoardMessagesCount > 0

    if (shouldOpenAutomatically) {
      open(SHOW_MESSAGE_TYPE.AUTO)
    }
  }, [
    userMessageFeedPreferences,
    boardId,
    isEditor,
    isOwnBoard,
    totalBoardMembersCount,
    open,
    totalBoardMessagesCount,
  ])

  useLayoutEffect(() => {
    handleMessageFeedVisibilityWhenBoardChange()
    // eslint-disable-next-line
  }, [boardId])

  const data: UpdatesToolsState = useMemo(
    () => ({
      isAvailable: !loading && !!boardsCount,
      disabled: false,
      isOpen,
      shouldAutoOpenMessagesSection,
      setShouldAutoOpenMessagesSection,
      close,
      toggle,
    }),
    [
      loading,
      isOpen,
      boardsCount,
      toggle,
      close,
      shouldAutoOpenMessagesSection,
      setShouldAutoOpenMessagesSection,
    ],
  )

  return (
    <UpdatesContext.Provider value={data}>{children}</UpdatesContext.Provider>
  )
}

export default UpdatesProvider
