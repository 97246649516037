import React, { FC, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import {
  BackIcon,
  ButtonIcon,
  If,
  Tooltip,
  TooltipContent,
} from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'

import styles from './EditButtonHeader.module.css'

type EditButtonHeaderProps = PropsWithChildren<{
  onClose: () => void
  onBack?: () => void
}>
const EditButtonHeader: FC<EditButtonHeaderProps> = ({
  onClose,
  onBack,
  children,
}) => {
  return (
    <div className={styles.Root}>
      <If state={!!onBack}>
        <Tooltip
          data={
            <TooltipContent>
              <Trans i18nKey={'action_back'} />
            </TooltipContent>
          }
        >
          <ButtonIcon size={38} variant="gray" onClick={onBack} round>
            <IconWrapper color="gray">
              <BackIcon />
            </IconWrapper>
          </ButtonIcon>
        </Tooltip>
      </If>

      <If state={!onBack}>
        <div className={styles.Space}></div>
      </If>

      <div>{children}</div>

      <CloseButtonWithTooltip onClose={onClose} gray />
    </div>
  )
}

export default EditButtonHeader
