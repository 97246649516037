import { FC, ReactElement } from 'react'

import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import styles from './SelectContentDialogHeader.module.css'

interface Props {
  category: string | ReactElement
}
export const SelectContentDialogHeader: FC<Props> = ({ category }) => {
  const { title } = useContentNavigation(ContentPickersEnum.SourcePicker)

  return (
    <div className={styles.Header}>
      <span className={styles.Mode}>{category}</span>

      <span className={styles.Title}>{title}</span>
    </div>
  )
}
