import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonNew,
  Card,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  CardBodyTitle,
  Icon,
  WarningBigIcon,
} from 'boards-web-ui'

import styles from './FormsSubmitWarning.module.css'

type FormsSubmitWarningProps = {
  title?: string
  text?: string
  warningMessage?: ReactNode
  actionSubmit?: string
  actionCancel?: string
  onSubmit: () => void
  onCancel: () => void
} & HTMLAttributes<HTMLDivElement>
const FormsSubmitWarning: FC<FormsSubmitWarningProps> = ({
  title = 'Payment provider not added',
  text = 'To collect payments you need to add a payment provider.',
  warningMessage,
  actionSubmit = 'Continue anyway',
  actionCancel = 'Back to editing',
  onSubmit,
  onCancel,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Card {...rest}>
      <CardHeader
        bg="transparent"
        icon={
          <Icon className={styles.Icon}>
            <WarningBigIcon />
          </Icon>
        }
      />
      <CardBody className={styles.Body}>
        <CardBodyTitle>{t(title)}</CardBodyTitle>
        <CardBodyContent>{warningMessage || t(text)}</CardBodyContent>
        <CardActions>
          <ButtonNew fullWidth color="warning" size="large" onClick={onSubmit}>
            {t(actionSubmit)}
          </ButtonNew>
          <ButtonNew
            fullWidth
            color="secondary"
            size="large"
            onClick={onCancel}
          >
            {t(actionCancel)}
          </ButtonNew>
        </CardActions>
      </CardBody>
    </Card>
  )
}

export default React.memo(FormsSubmitWarning)
