import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { Timestamp } from 'firebase/firestore'

import { EventCategories } from '@features/analytics/models/logEvents'

import { firebaseEventsAsyncActions } from '../../../actions'

type Params = {
  [key: string]:
    | string
    | number
    | Timestamp
    | undefined
    | Date
    | boolean
    | string[]
}
type EventHandler = (
  category: EventCategories | string,
  params?: Params,
) => void
type Handler = (name: string, params?: { [key: string]: unknown }) => void

export const useLogEvents = () => {
  const dispatch = useDispatch()

  const trigger: EventHandler = useCallback(
    (eventName, params?) => {
      dispatch(firebaseEventsAsyncActions.logEvent(eventName, params))
    },
    [dispatch],
  )

  const tooltipView: Handler = (name, params?) =>
    trigger(EventCategories.APP_TOOLTIP_VIEW, { tooltip: name, ...params })
  const tooltipClose: Handler = (name) =>
    trigger(EventCategories.APP_TOOLTIP_CLOSED, { tooltip: name })
  const buttonClick: Handler = (name, params?) =>
    trigger(EventCategories.APP_BUTTON_CLICK, { button: name, ...params })
  const screenView: Handler = (name, params?) =>
    trigger(EventCategories.APP_SCREEN_VIEW, { screen: name, ...params })
  const boardView = (params: Params) =>
    trigger(EventCategories.APP_BOARD_VIEW, params)
  const pagesBoardViewEvent = ({ activePage }: { activePage: boolean }) =>
    trigger(EventCategories.APP_PAGES_BOARD_VIEW, { active_page: activePage })

  return {
    trigger,
    tooltipView,
    tooltipClose,
    buttonClick,
    screenView,
    boardView,
    pagesBoardViewEvent,
  } as const
}
