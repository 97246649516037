import { FC } from 'react'
import { Trans } from 'react-i18next'
import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  CardBodyTitle,
  CardHeader,
  Icon,
  BoardsFrameIcon,
} from 'boards-web-ui'

import styles from './ValentineMarathonDialog.module.css'

interface Props {
  onClick: () => void
}
const ValentineMarathonDialog: FC<Props> = ({ onClick }) => (
  <div className={styles.Root}>
    <CardHeader
      className={styles.IconContainer}
      bg="white"
      icon={
        <Icon size="100px">
          <BoardsFrameIcon />
        </Icon>
      }
    />
    <CardBody>
      <CardBodyTitle>
        <Trans i18nKey="trial_paywall_velentines_marathon_title" />
      </CardBodyTitle>
      <CardBodyContent className={styles.Content}>
        <Trans i18nKey="trial_paywall_velentines_marathon_content" />
      </CardBodyContent>
      <CardActions>
        <ButtonNew fullWidth size="large" onClick={onClick}>
          <Trans i18nKey="action_got_it" />
        </ButtonNew>
      </CardActions>
    </CardBody>
  </div>
)

export default ValentineMarathonDialog
