import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { BackIcon, ButtonIcon, Tooltip, TooltipContent } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import useBoard from '../../../../hooks/useBoard'

import { getParentFolder } from '../../../../selectors'

type Props = {
  className?: string
}
const GoToParentButton: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { boardId, folderId, isRoot } = useBoard()

  const nextParent = useSelectorFactory(getParentFolder, boardId, folderId)

  if (isRoot || !nextParent || typeof nextParent === 'string') {
    return null
  }

  const goToParent = () => navigate(`/${boardId}/${nextParent.id}${search}`)

  return (
    <Tooltip
      className={className}
      data={<TooltipContent>{nextParent.title}</TooltipContent>}
    >
      <ButtonIcon
        round
        variant="gray"
        name="go to parent folder"
        size={38}
        disabled={isRoot}
        onClick={goToParent}
      >
        <IconWrapper color="gray">
          <BackIcon />
        </IconWrapper>
      </ButtonIcon>
    </Tooltip>
  )
}

export default GoToParentButton
