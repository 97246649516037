import React, { PropsWithChildren } from 'react'

import { Tooltip, TooltipPositions } from 'boards-web-ui'
import { AnimatedArrows } from '../../../../components/AnimatedArrows'

import styles from './CreateBoardTooltip.module.css'

type Props = PropsWithChildren<{
  isOpen: boolean
}>
const CreateBoardTooltip: React.FC<Props> = ({ children, isOpen }) => (
  <Tooltip
    as="div"
    disabled
    followCursorY
    isOpen={isOpen}
    style={{ padding: '10px 15px' }}
    position={TooltipPositions.RIGHT}
    data={<AnimatedArrows className={styles.Margin} />}
  >
    {children}
  </Tooltip>
)

export default React.memo(CreateBoardTooltip)
