import React, { PropsWithChildren, ReactNode } from 'react'
import { Tooltip, TooltipContent, TooltipPositions } from 'boards-web-ui'

type Props = PropsWithChildren<{
  data: ReactNode
  disabled: boolean
  isOpen?: boolean
  className?: string
  position?: TooltipPositions
}>
const DisableStateTooltip: React.FC<Props> = ({
  data,
  children,
  isOpen,
  disabled,
  className,
  position = TooltipPositions.BOTTOM_LEFT,
}) => (
  <Tooltip
    as="div"
    isOpen={isOpen}
    disabled={disabled}
    className={className}
    position={position}
    data={<TooltipContent>{data}</TooltipContent>}
  >
    {children}
  </Tooltip>
)

export default React.memo(DisableStateTooltip)
