import React, { memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import clsx from 'clsx'

import useBoard from '@hooks/useBoard'
import UpdatesSectionHeader from './UpdatesSectionHeader'
import UpdatesScrollArea from './UpdatesScrollArea'
import { QUERY_PARAMS } from '../../../constants/routes'
import styles from './Updates.module.css'

export const Updates = () => {
  const { isOwnBoard, isEditor } = useBoard()
  const [searchParams] = useSearchParams()
  const selectedMessageId = searchParams.get(QUERY_PARAMS.message)

  const shouldDisplayComposeTrigger =
    (isEditor || isOwnBoard) && !selectedMessageId

  return (
    <div
      className={clsx(
        styles.Root,
        shouldDisplayComposeTrigger && styles.withComposeTrigger,
      )}
    >
      <UpdatesSectionHeader
        shouldDisplayComposeTrigger={shouldDisplayComposeTrigger}
      />
      <UpdatesScrollArea />
    </div>
  )
}

export default memo(Updates)
