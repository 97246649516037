import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'

import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  SomethingWentWrongIcon,
} from 'boards-web-ui'
import styles from './AcademyPageErrorModal.module.css'

export const AcademyPageErrorModal = ({
  onClose,
  onReloadClick,
}: {
  onClose: () => void
  onReloadClick: () => void
}) => {
  const handleButtonClick = useCallback(() => {
    onReloadClick()
    onClose()
  }, [onClose, onReloadClick])

  return (
    <div className={styles.Root}>
      <CardBody className={styles.Container}>
        <CardBodyContent className={styles.Content}>
          <div className={styles.IconContainer}>
            <SomethingWentWrongIcon width={131} height={132} />
          </div>

          <div className={styles.Message}>
            <Trans i18nKey="optimistic_ui_failed" />
          </div>
        </CardBodyContent>

        <CardActions className={styles.Actions}>
          <ButtonNew fullWidth size="large" onClick={handleButtonClick}>
            <Trans i18nKey="action_retry" />
          </ButtonNew>
        </CardActions>
      </CardBody>
    </div>
  )
}
