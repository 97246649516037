import React, { useEffect, useMemo } from 'react'
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom'
import { theme, useDialog } from 'boards-web-ui'

import {
  TemplateCategoryModel,
  TemplateModel,
} from '@features/templateGallery/models/Template'
import { UserProfilePlan } from '@models/UserProfile'
import TemplatePreview from '@features/templateGallery/components/TemplatePreview'
import useTemplatePickerEvents from '@features/analytics/useTemplatePickerEvents'
import { getTemplatePreviewCloseUrl } from '@features/templateGallery/helpers/templateUrlHelper'
import { Folder } from '../../../models'

export type Props = {
  isPagesBoardRoute: boolean
  boardId: string
  folderId: string
  category: TemplateCategoryModel
  allTemplates: TemplateModel[]
  templateId: string
  plan: UserProfilePlan
  onCopyTemplateToBoard: (
    selectedPage: Folder,
    templateCategory: TemplateCategoryModel,
  ) => void
}

export const TemplatePreviewWrapper = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    isPagesBoardRoute,
    boardId,
    folderId,
    category,
    allTemplates,
    templateId,
    plan,
    onCopyTemplateToBoard,
  } = useOutletContext<Props>()
  const { pageTemplatePreviewEvent } = useTemplatePickerEvents()

  const currentTemplate = useMemo(() => {
    return allTemplates.find((t) => t.id === templateId)
  }, [allTemplates, templateId])

  useEffect(() => {
    if (currentTemplate && category) {
      pageTemplatePreviewEvent(
        category.nameFormatted,
        currentTemplate.data.title,
      )
    }
  }, [currentTemplate, category, pageTemplatePreviewEvent])

  const { open, close } = useDialog()

  useEffect(() => {
    if (!category) {
      return undefined
    }
    open(
      <TemplatePreview
        nid={templateId}
        hasCrown={!plan.features.themes}
        templates={allTemplates}
        isPagesBoardRoute={isPagesBoardRoute}
        boardId={boardId}
        folderId={folderId}
        onChooseThisTemplateClick={(selectedPage) =>
          onCopyTemplateToBoard(selectedPage, category)
        }
      />,
      {
        fullScreen: true,
        closeOnEscapeButton: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        withFullScreenDialogContainer: false,
        onEscape: () => {
          navigate(
            getTemplatePreviewCloseUrl({
              searchParams,
              isPagesBoardRoute,
              boardId,
              folderId,
            }),
          )
        },
      },
    )
    return () => {
      close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  return <div />
}
