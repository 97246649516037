import { Dispatch, SetStateAction, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { VideoMetaData } from '@hooks/useUploadFileMetadata'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { videoActions } from '../../../../../actions'
import { NodeType, Node } from '../../../../models'

type VideoStreamingNode = Node & {
  type: NodeType.VIDEO_STREAMING
  width: number
  height: number
}

interface UseSaveVideoStreamingNodeProps {
  isTryFree?: boolean
  title?: string
  currentNodeId?: string
  setNode?: Dispatch<SetStateAction<Node | undefined>>
  onSave: ICreateOrUpdateNode
}

type SaveVideoStreamingNode = (
  videoStreamingUrl: string,
  videoMetadata: VideoMetaData,
  thumbnail?: string,
) => VideoStreamingNode | undefined

const useSaveVideoStreamingNode = ({
  isTryFree,
  title = '',
  currentNodeId,
  setNode,
  onSave,
}: UseSaveVideoStreamingNodeProps): SaveVideoStreamingNode => {
  const dispatch = useDispatch()

  return useMemo(() => {
    return (
      videoStreamingUrl: string,
      videoMetadata: VideoMetaData,
      thumbnail?: string,
    ) => {
      if (!videoMetadata) {
        return undefined
      }

      dispatch(videoActions.setVideoStreamingUrl(videoStreamingUrl))

      const nodeProps = {
        title,
        text: videoStreamingUrl,
        type: NodeType.VIDEO_STREAMING,
        thumbnail: thumbnail || '',
        isProcessing: true,
        width: videoMetadata.width,
        height: videoMetadata.height,
      } as VideoStreamingNode

      const id =
        onSave(nodeProps, { try_free: Boolean(isTryFree).toString() }) ||
        currentNodeId

      if (typeof id === 'string') {
        const newNode: VideoStreamingNode = { ...nodeProps, id }
        if (setNode) {
          setNode(newNode)
        }
        return newNode
      }

      return undefined
    }
  }, [title, currentNodeId, isTryFree, onSave, setNode, dispatch])
}

export default useSaveVideoStreamingNode
