import { Folder, Node, NodeType } from '../../../app/models'

import { addNodes, nodesWithNewKeys } from '../../FolderManagement'
import { AppState } from '../../../app/models/appState'
import { initialState } from '../../initialState'
import { clearProperties } from '../../../_firebase/utils'

interface ICopyNodesReducer {
  targetBoardId: string
  targetFolderId: string
  template: string
  key: string
  page: Folder
  location?: 'start'
}
export function copyTemplateReducer(
  state: AppState,
  { targetBoardId, targetFolderId, key, page, location }: ICopyNodesReducer,
  undoStackWithContentAdded: (
    state: AppState,
    boardId: string,
  ) => AppState['app']['undoStack'],
): AppState {
  const nodesToAdd = [
    {
      id: page.id,
      icon: page.icon,
      shortcut: page.shortcut,
      thumbnail: '',
      title: page.title,
      type: NodeType.PAGE,
    } as Node,
  ].map((node) => clearProperties(node))

  const { nodes, folders } = nodesWithNewKeys(
    {
      ...state.folders[targetBoardId],
      [page.id]: page,
    },
    nodesToAdd,
    key,
    true,
  )

  const foldersWithNewNodes = addNodes({
    folders: {
      ...state.folders,
      [targetBoardId]: folders,
    },
    boardId: targetBoardId,
    fid: targetFolderId,
    nodes,
    ...(location === 'start' && { beforeNodeIndex: 0 }),
  })

  return {
    ...state,
    folders: foldersWithNewNodes,
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, targetBoardId),
      bulk: initialState.app.bulk,
    },
  }
}
