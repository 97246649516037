import {
  AnimatedDialog,
  AttachIcon,
  ButtonIcon,
  ButtonNew,
  CloseSmallIcon,
  eEnvironment,
  Node,
  PremiumCrownIcon,
  theme,
} from 'boards-web-ui'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import { Trans, useTranslation } from 'react-i18next'
import React, {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import useEditTextAPI from '@features/nodeEditor/hooks/useNodeAPI'
import InputFieldWithEmojiPicker from '@features/contactButtons/components/InputFieldWithEmojiPicker'
import { BoardInfo } from '@features/updates/components/ComposeModal/BoardInfo'
import { TextAreaFieldWithEmojiPicker } from '@features/contactButtons/components/TextAreaFieldWithEmojiPicker'
import { IconWrapper } from '@ui/components/IconWrapper'
import useBoard from '@hooks/useBoard'
import { useProfileName } from '@features/profile/hooks/useProfileName'
import { useComposePicker } from '@features/pickers/hooks/useComposePicker'
import FolderListItem from '@features/board/components/FolderListItem'
import { buildLayout } from '@features/board/helpers/layout'
import useConfirmDialog from '@hooks/useConfirmDialog'
import usePageNodeTransformer from '@features/nodes/hooks/usePageNodeTransformer'
import { useAttachContentToMessagesEvents } from '@features/analytics/useAttachContentToMessagesEvents'
import useBodyResizeHandler from '@ui/hooks/useBodyResizeHandler'
import { newKey } from '@helpers/NodeIdGenerator'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import usePostMessage from '@features/updates/hooks/usePostMessage'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useToast } from '@hooks/useToast'
import { useUpdatesTranslations } from '@features/updates/hooks/useUpdatesTranslations'
import { ModalEditWithConfirmationAndTitle } from '../../../../../components/ModalEditWithConfirmation'
import styles from './ComposeModal.module.css'
import { Node as NodeModel } from '../../../../models'

interface Props {
  withOpenContentPicker?: boolean
  onCloseDialog: () => void
  onOpenConfirmModal: (params: { onPostMessage: () => void }) => void
}

const MAX_MODAL_HEIGHT = 800
const HEADER_HEIGHT = 48
const FOOTER_HEIGHT = 70
const PADDING_BUFFER = 50

export const ComposeModal = ({
  onCloseDialog,
  onOpenConfirmModal,
  withOpenContentPicker = false,
}: Props) => {
  const { t } = useTranslation()
  const { name } = useProfileName()

  const { title, text, setText, setTitle, hasChanges } = useEditTextAPI('', '')
  const [attachment, setAttachment] = useState<NodeModel | undefined>(undefined)

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  const { board, totalBoardMembersCount } = useBoard()
  const { postMessage } = usePostMessage()
  const { contentAttachClickEvent, contentAttachedEvent } =
    useAttachContentToMessagesEvents()

  const key = newKey()

  const { selectContent } = useComposePicker({
    initiallyOpenPicker: withOpenContentPicker,
    action: ({ nodes }) => {
      setAttachment(nodes[0])
      contentAttachedEvent({
        boardId: board?.id || '',
        fileType: nodes.map(({ type }) => type),
        keyId: key,
      })
    },
  })

  const handleAttachButtonClick = () => {
    selectContent()
    contentAttachClickEvent({ boardId: board?.id || '' })
  }

  const node = attachment ? buildLayout([attachment]).tiles[0] : undefined

  const confirmDialog = useConfirmDialog()

  const handleCloseModal = () => {
    if (hasChanges || attachment) {
      confirmDialog({
        title: t('discard_changes_title'),
        message: t('discard_changes_body'),
        cancelText: t('activate_dialog_button_cancel'),
        confirmText: t('action_discard'),
        onConfirm: onCloseDialog,
      })

      return
    }

    onCloseDialog()
  }

  const transformPageNode = usePageNodeTransformer()

  const contentRef = useRef<HTMLDivElement>(null)

  const [maxPopupHeight, setMaxPopupHeight] = useState<number | undefined>(
    undefined,
  )
  useBodyResizeHandler((_, height) => {
    if (height && height < MAX_MODAL_HEIGHT + PADDING_BUFFER * 2) {
      setMaxPopupHeight(height - PADDING_BUFFER * 2)
    }
  }, true)

  const [modalHeight, setModalHeight] = useState('620px')

  function adjustHeight() {
    if (!contentRef.current) {
      return
    }

    const height = contentRef.current.clientHeight

    setModalHeight(
      `${height + HEADER_HEIGHT + FOOTER_HEIGHT + PADDING_BUFFER}px`,
    )
  }

  useLayoutEffect(adjustHeight, [text, attachment, title])

  const { isFreePlanUser } = useReverseTrialContext()
  const { openPaywall } = usePaywall()

  const toast = useToast()
  const { toastSent } = useUpdatesTranslations()

  const handlePostMessage = useCallback(() => {
    postMessage({
      title,
      text,
      attachment,
      keyId: key,
    })
    onCloseDialog()

    toast(toastSent(), true, 300)
  }, [
    attachment,
    key,
    onCloseDialog,
    postMessage,
    text,
    title,
    toast,
    toastSent,
  ])

  const handlePostClick = useCallback(() => {
    if (isFreePlanUser) {
      openPaywall({ source: PaywallSourceEnum.POST_MESSAGE })
      return
    }

    if (totalBoardMembersCount === 0) {
      handlePostMessage()
      return
    }

    onOpenConfirmModal({
      onPostMessage: handlePostMessage,
    })
  }, [
    handlePostMessage,
    isFreePlanUser,
    onOpenConfirmModal,
    openPaywall,
    totalBoardMembersCount,
  ])

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        closeOnEscapeButton: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          ref={ref}
          headerControls={
            <div className={styles.HeaderRoot}>
              <div className={styles.Header}>
                <span className={styles.Title}>
                  <Trans i18nKey="message_input_text" />
                </span>

                <CloseButtonWithTooltip onClose={handleCloseModal} gray />
              </div>
            </div>
          }
          footerControls={
            <ButtonNew
              disabled={!text && !title}
              onClick={handlePostClick}
              startIcon={
                isFreePlanUser ? (
                  <IconWrapper>
                    <PremiumCrownIcon />
                  </IconWrapper>
                ) : null
              }
            >
              <Trans i18nKey="action_post" />
            </ButtonNew>
          }
          responsiveHeight
          withBorders
          className={styles.Modal}
          style={{ height: modalHeight, maxHeight: maxPopupHeight }}
        >
          <div className={styles.Content} ref={contentRef}>
            <BoardInfo icon={board?.icon} title={board?.title} name={name} />

            <div className={styles.Divider} />

            <div className={styles.Subject}>
              <InputFieldWithEmojiPicker
                label={t('message_subject_title')}
                placeholder={t('message_subject_empty')}
                value={title}
                onChange={setTitle}
                autoFocus
                maxLength={125}
                index={1}
              />
            </div>

            <div className={styles.Message}>
              <TextAreaFieldWithEmojiPicker
                value={text}
                label={t('message_body_title')}
                placeholder={t('message_body_empty')}
                onChange={setText}
                index={2}
              />
            </div>

            <div
              className={styles.Attachment}
              style={{ height: node?.rect.height }}
            >
              {node ? (
                <>
                  <ButtonIcon
                    onClick={() => setAttachment(undefined)}
                    size={32}
                    round
                    variant={'gray'}
                    className={styles.AttachmentRemove}
                  >
                    <IconWrapper size={16}>
                      <CloseSmallIcon />
                    </IconWrapper>
                  </ButtonIcon>

                  <FolderListItem
                    id={node.node.id}
                    rect={node.rect}
                    index={0}
                    onDrag={() => {}}
                    onDragEnd={() => {}}
                    className={styles.AttachmentListItem}
                  >
                    <Node
                      node={transformPageNode(node.node).node}
                      titleBreakLineThreshold={100}
                      environment={eEnvironment.Editor}
                    />
                  </FolderListItem>
                </>
              ) : (
                <ButtonNew
                  color={'secondary'}
                  size={'medium'}
                  onClick={handleAttachButtonClick}
                  startIcon={
                    <IconWrapper>
                      <AttachIcon />
                    </IconWrapper>
                  }
                >
                  <Trans i18nKey={'message_attach_content_button'} />
                </ButtonNew>
              )}
            </div>
          </div>
        </ModalEditWithConfirmationAndTitle>
      }
    />
  )
}
