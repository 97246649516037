import { addNodes, getNodes, nodesWithNewKeys } from '../../FolderManagement'
import { AppState } from '../../../app/models/appState'
import { initialState } from '../../initialState'
import { clearProperties } from '../../../_firebase/utils'

interface ICopyNodesReducer {
  targetBoardId: string
  targetFolderId?: string
  nodes: string[]
  key: string
  fromParams: {
    fromBoardId: string
    fromFolderId: string
    fromShortcutId?: string
  }
}
export function copyNodesReducer(
  state: AppState,
  {
    targetBoardId,
    targetFolderId,
    nodes: nodesToCopy,
    key,
    fromParams: { fromFolderId, fromBoardId, fromShortcutId },
  }: ICopyNodesReducer,
  undoStackWithContentAdded: (
    state: AppState,
    boardId: string,
  ) => AppState['app']['undoStack'],
): AppState {
  if (!targetFolderId) {
    return { ...state }
  }

  const nodesToAdd = getNodes(
    fromShortcutId ? state.app.sharedFolder.folders : state.folders,
    fromBoardId,
    fromFolderId,
    nodesToCopy,
    fromShortcutId,
  )

  const { nodes, folders } = nodesWithNewKeys(
    state.folders[targetBoardId],
    nodesToAdd.map((node) => clearProperties(node)),
    key,
    state.folders[targetBoardId][targetFolderId]?.isPage,
  )

  const foldersWithNewNodes = addNodes({
    folders: { ...state.folders, [targetBoardId]: folders },
    boardId: targetBoardId,
    fid: targetFolderId,
    nodes,
  })

  return {
    ...state,
    folders: foldersWithNewNodes,
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, targetBoardId),
      bulk: initialState.app.bulk,
    },
  }
}
