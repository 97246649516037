import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import styles from './ReadMoreText.module.css'

type Props = PropsWithChildren<{
  className?: string
}>

export const ReadMoreText: React.FC<Props> = ({ children, className }) => {
  const textRef = useRef<HTMLSpanElement | null>(null)
  const [, setIsTextOverflowing] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      setIsTextOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight,
      )
    }
  }, [])

  return (
    <span ref={textRef} className={[styles.Text, className].join(' ')}>
      {children}
    </span>
  )
}
