import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import usePageEvents from '@features/analytics/usePageEvents'
import { useToast } from '@hooks/useToast'
import { addPage } from '_firebase/contentAPI'
import { Folder } from 'app/models'
import { IAddPage } from 'actions/page'
import { AddPageData, UpdatePageData } from '../models'
import { nodeActions, pageActions } from '../../../../actions'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

interface CreatePageParams {
  boardId: string
  folderId: string
  key: string
  node: AddPageData
  sourceFolder: Folder
  isPageBoard: boolean
}
export interface AddPageApiResult {
  id: string
  properties: {
    shortcut: string
  }
}

const usePageAPI = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { t } = useTranslation()
  const toast = useToast()
  const { createPageEvent } = usePageEvents()

  const handleAddPage = useCallback(
    async (
      board: string,
      folder: string,
      key: string,
      properties: AddPageData,
      sourceFolder: Folder,
      isPageBoard: boolean,
    ) => {
      const result: AddPageApiResult = await addPage({
        board,
        folder,
        key,
        properties,
        location: isPageBoard ? 'start' : undefined,
      })

      if (result) {
        createPageEvent(
          { ...sourceFolder, shortcut: result.properties.shortcut },
          board,
          isPageBoard,
        )
      }
    },
    [createPageEvent],
  )

  const create = useCallback(
    ({
      boardId,
      folderId,
      key,
      node,
      sourceFolder,
      isPageBoard,
    }: CreatePageParams) => {
      if (!isApiAvailableOrNotify()) return

      if (!folderId || !key || !node) {
        toast(t('optimistic_ui_failed'))
        return
      }

      const payload = {
        board: boardId,
        folder: folderId,
        key,
        properties: node,
        location: isPageBoard ? 'start' : undefined,
      } as IAddPage

      dispatch(
        pageActions.addPageOptimistic(
          payload,
          () =>
            handleAddPage(
              boardId,
              folderId,
              key,
              node,
              sourceFolder,
              isPageBoard,
            ),
          () => navigate(`/${boardId}/${folderId}`),
        ),
      )
    },
    [t, toast, dispatch, isApiAvailableOrNotify, handleAddPage, navigate],
  )

  const update = useCallback(
    (
      boardId: string,
      folderId: string,
      contentId?: string,
      node?: UpdatePageData,
    ) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        nodeActions.setProperties(
          boardId,
          folderId,
          contentId,
          node as unknown as INodeProps,
        ),
      )
    },
    [dispatch, isApiAvailableOrNotify],
  )

  return {
    create,
    update,
  } as const
}

export default usePageAPI
