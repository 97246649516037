import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { PaywallDesktop, PaywallMobile } from 'boards-web-ui'

import PageHeader from '@features/payments/components/PageHeader'
import ConfetisBackground from '@features/payments/components/ConfetisBackground'
import SubscribeButtonText from '@features/payments/components/SubscribeButtonText'
import { usePublicPaywall } from '@features/payments/hooks/usePublicPaywall'
import { usePaywallPlansBulletsProps } from '@features/payments/hooks/usePaywallPlansProps'
import usePaywallProducts from '@features/payments/hooks/usePaywallProducts'
import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'
import { usePaywallPlanTermSelection } from '@features/payments/hooks/usePaywallPlanTermSelection'

import { isMobile } from '../../../utils/deviceParser'

const PublicPaywall = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get('campaign') || undefined
  const { freePlanBullets, businessPlanBullets } = usePaywallPlansBulletsProps()
  const { paddlePriceIds } = useSubscriptionPlans()
  const { products } = usePaywallProducts(paddlePriceIds)
  const {
    termOptions,
    selectedProductId,
    billedText,
    isMonthlyTermSelected,
    currentPlan,
    discountForPlan,
    handleTermChange,
  } = usePaywallPlanTermSelection({
    items: products?.items,
    currencySymbol: products?.currencySymbol,
  })

  const {
    paywallTitle,
    isLongText,
    isInCampaignMode,
    handlePlanSwipe,
    handleSubscribeFreePlanClick,
    handleSubscribePlanClick,
  } = usePublicPaywall({
    campaignId,
    currentPlan,
  })

  const Paywall = isMobile ? PaywallMobile : PaywallDesktop

  return (
    <ConfetisBackground
      isMobile={isMobile}
      hasConfetis={!!campaignId}
      header={<PageHeader isMobile={isMobile} hideBackButton />}
    >
      {currentPlan && (
        <Paywall
          priceTermValue={selectedProductId}
          onTermChange={handleTermChange}
          planPriceTermSwitcherOptions={termOptions}
          onChangeSubscriptionPlan={handlePlanSwipe}
          isLongTextTitle={isLongText}
          title={paywallTitle}
          billedText={billedText}
          defaultPlan={'business'}
          plans={{
            free: {
              chipLabel: t('free_plan_title'),
              planPriceTitle: t('free_plan_title'),
              planPriceValue: `${products?.currencySymbol}0`,
              bullets: freePlanBullets,
              confirmButtonText: t('action_get_started'),
              hideSubscribeButton: isInCampaignMode,
              onSubscribePlan: handleSubscribeFreePlanClick,
            },
            business: {
              chipLabel: currentPlan.productName,
              planPriceTitle: currentPlan.productName,
              planPriceValue: `${products?.currencySymbol}${currentPlan.price}`,
              planPriceSubValue: {
                linedThrough: !isMonthlyTermSelected
                  ? `${products?.currencySymbol}${products?.items[0]?.price}`
                  : undefined,
                commonText: t('business_price'),
              },
              planPriceDiscountBadge: !isMonthlyTermSelected
                ? t('label_save_number', {
                    percentage: discountForPlan,
                  })
                : undefined,
              bullets: businessPlanBullets,
              confirmButtonText: <SubscribeButtonText plan={currentPlan} />,
              onSubscribePlan: () =>
                handleSubscribePlanClick(currentPlan.priceId),
            },
          }}
        />
      )}
    </ConfetisBackground>
  )
}

export default PublicPaywall
