import { theme, useDialog } from 'boards-web-ui'
import { ComposeModal } from '@features/updates/components/ComposeModal'
import { ConfirmModal } from '@features/updates/components/ComposeModal/ConfirmModal'

export const useComposeModal = () => {
  const { open, close } = useDialog()

  const openConfirmModal = ({
    onPostMessage,
  }: {
    onPostMessage: () => void
  }) => {
    const onConfirm = () => {
      onPostMessage()
      close()
    }
    open(<ConfirmModal onConfirm={onConfirm} />, {
      closeOnEscapeButton: true,
    })
  }

  const openComposeModal = ({
    openContentPicker,
  }: {
    openContentPicker: boolean
  }) => {
    open(
      <ComposeModal
        onCloseDialog={close}
        onOpenConfirmModal={openConfirmModal}
        withOpenContentPicker={openContentPicker}
      />,
      {
        fullScreen: true,
        closeOnEscapeButton: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        withFullScreenDialogContainer: false,
      },
    )
  }

  return { openComposeModal }
}
