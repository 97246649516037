import { useTranslation } from 'react-i18next'
import { calculateAspectRatioFit, VideoPlayer } from 'boards-web-ui'
import useNodeEvents, { ScreenNames } from '@features/analytics/useNodeEvents'
import useCopyShortcuts from '@hooks/useCopyShortcuts'

import PreviewModal from './PreviewModal'
import { Folder, Node } from '../../../models'

import styles from './PreviewVideoStreaming.module.css'

const MAX_VIDEO_WIDTH = 900
const MAX_VIDEO_HEIGHT = 400

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  isDisabledPreviewNavigation?: boolean
  canCopyContentOfSharedFolder?: boolean
  isReadOnly?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const PreviewVideoStreaming = ({
  node,
  prev,
  next,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  isDisabledPreviewNavigation,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation()
  const { videoPlayEvent } = useNodeEvents()
  const { copyNodeShortcut } = useCopyShortcuts()

  const maxHeight = Math.max(window.innerHeight - 130, MAX_VIDEO_HEIGHT)

  const { width, height } = calculateAspectRatioFit(
    node.width || MAX_VIDEO_WIDTH,
    node.height || MAX_VIDEO_HEIGHT,
    MAX_VIDEO_WIDTH,
    maxHeight,
  )

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_copy_link')}
      onCopyClick={() => copyNodeShortcut(node)}
      prev={prev}
      next={next}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      isDisabledPreviewNavigation={isDisabledPreviewNavigation}
      isReadOnly={isReadOnly}
    >
      <div className={styles.Root}>
        <div className={styles.VideoWrapper}>
          <div style={{ maxWidth: width }} onClick={(e) => e.stopPropagation()}>
            <VideoPlayer
              className={styles.Player}
              key={node.text}
              src={node.text}
              thumbnail={node.thumbnail}
              width={width}
              height={height}
              isProcessing={node.isProcessing}
              processingLabel={t('label_processing_video')}
              onPlay={() => videoPlayEvent(node, ScreenNames.CONTENT_PREVIEW)}
            />
          </div>
        </div>
      </div>
    </PreviewModal>
  )
}

export default PreviewVideoStreaming
