import { ICreateOrUpdateNode, INodeProps } from './models/IUseNodeCreatorAPI'

import useAddPdf from './hooks/useAddPdf'
import useAddForm from './hooks/useAddForm'
import useAddLink from './hooks/useAddLink'
import useAddText from './hooks/useAddText'
import useAddVideo from './hooks/useAddVideo'
import useAddImages from './hooks/useAddImages'
import useAddFolder from './hooks/useAddFolder'
import useAddButton from './hooks/useAddButton'
import useAddProfile from './hooks/useAddProfile'
import useAddHeadline from './hooks/useAddHeadline'
import useAddParagraph from './hooks/useAddParagraph'
import useAddVideoStreaming from './hooks/useAddVideoStreaming'
import useAddCheckout from './hooks/useAddCheckout'

const useAddNode = (
  folderId: string,
  create: ICreateOrUpdateNode,
  update: (id: string, node: INodeProps) => void,
  isPageEditor?: boolean,
) => {
  const { addPdf } = useAddPdf(create, isPageEditor)
  const addText = useAddText(create)
  const addForm = useAddForm(create)
  const addLink = useAddLink(create)
  const addVideo = useAddVideo(create, isPageEditor)
  const addImages = useAddImages(create)
  const addFolder = useAddFolder()
  const addButton = useAddButton(create)
  const addProfile = useAddProfile(create)
  const addHeadline = useAddHeadline(create)
  const addParagraph = useAddParagraph(create)

  const addVideoStreaming = useAddVideoStreaming({
    save: create,
    update,
    isPageEditor,
    folderId,
  })

  const addCheckout = useAddCheckout(create)

  return {
    addForm,
    addFolder,
    addImages,
    addPdf,
    addText,
    addLink,
    addVideo,
    addButton,
    addProfile,
    addHeadline,
    addParagraph,
    addVideoStreaming,
    addCheckout,
  } as const
}

export default useAddNode
