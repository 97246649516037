import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatedDialog, theme } from 'boards-web-ui'

import usePageProfile from '@features/PageProfile/hooks/usePageProfile'

import { ModalEditWithConfirmationAndTitle } from '../../../../components/ModalEditWithConfirmation'
import PageProfileDialogHeader from './PageProfileDialogHeader'
import PageProfileDialogFooter from './PageProfileDialogFooter'
import PageProfileForm from './PageProfileForm'

import styles from './PageProfileDialog.module.css'

interface Props {
  isNew: boolean
}
const PageProfileDialog: FC<Props> = ({ isNew }) => {
  const { t } = useTranslation()
  const { pageProfileFormState } = usePageProfile()

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          responsiveHeight
          withBorders
          withFixedBody
          className={styles.Root}
          headerControls={
            <PageProfileDialogHeader
              title={t('label_profile')}
              onCancel={pageProfileFormState.onCancel}
            />
          }
          footerControls={
            <PageProfileDialogFooter
              loading={pageProfileFormState.loading}
              onSubmit={
                pageProfileFormState.isValid
                  ? pageProfileFormState.onSubmit
                  : undefined
              }
              name={t('action_done')}
            />
          }
        >
          <div className={styles.Body}>
            <PageProfileForm isNew={isNew} />
          </div>
        </ModalEditWithConfirmationAndTitle>
      }
    ></AnimatedDialog>
  )
}

export default PageProfileDialog
