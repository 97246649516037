import { isNumber } from 'lodash-es'

import useAddVideoStreamingDialogs from '@features/nodeEditor/hooks/useAddVideoStreamingDialogs'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import useUploadFileMetadata, {
  VideoMetaData,
} from '@hooks/useUploadFileMetadata'

const MAX_UPLOAD_FILE_SIZE = 512000 // 512 MB
const MAX_UPLOAD_FILE_DURATION = 600 // 600 seconds (10 minutes)

const useValidateVideoStreamingFile = () => {
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { getFileSize, getVideoMetadata } = useUploadFileMetadata()
  const { hideVideoHint } = useProfileInformation()
  const { maxFileSizeDialog, maxDurationDialog } = useAddVideoStreamingDialogs()

  const validateVideoStreamingFile = async (
    file: File,
  ): Promise<VideoMetaData | undefined> => {
    if (!isApiAvailableOrNotify()) return undefined

    if (getFileSize(file) >= MAX_UPLOAD_FILE_SIZE) {
      maxFileSizeDialog()
      return undefined
    }

    const metadata = await getVideoMetadata(file)

    if (
      isNumber(metadata.duration) &&
      metadata.duration >= MAX_UPLOAD_FILE_DURATION
    ) {
      maxDurationDialog()
      return undefined
    }

    hideVideoHint()
    return metadata
  }

  return validateVideoStreamingFile
}

export default useValidateVideoStreamingFile
