import { useLocation, useNavigate } from 'react-router-dom'
import { theme, useDialog } from 'boards-web-ui'

import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { useDispatch } from 'react-redux'
import { SourceType } from '@features/analytics/useFolderEvents'
import { IOpenNode } from '../models/nodes'

import NodePreview, { PreviewModeEnum } from '../../nodePreview/NodePreview'
import { Folder } from '../../../models'
import { toastActions } from '../../../../actions'

const useNodeActions = ({
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  mode,
}: {
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
  mode?: PreviewModeEnum
} = {}): IOpenNode => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { open, close } = useDialog()
  const { isFreePlanUser } = useReverseTrialContext()
  const dispatch = useDispatch()

  const previewNode = ({
    nodeId,
    hideParents,
    source,
    messageId,
  }: {
    nodeId: string
    hideParents?: boolean
    source?: SourceType
    messageId?: string
  }) => {
    if (isFreePlanUser) {
      dispatch(toastActions.closeToastMessage())
    }

    return open(
      <NodePreview
        nid={nodeId}
        close={close}
        folder={folder}
        isSharedFolderMode={isSharedFolderMode}
        canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
        sharedBoardId={sharedBoardId}
        sharedFolderId={sharedFolderId}
        mode={mode}
        source={source}
        messageId={messageId}
      />,
      {
        fullScreen: true,
        closeOnEscapeButton: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        withFullScreenDialogContainer: false,
        hideParents,
      },
    )
  }

  const openFolder: IOpenNode['openFolder'] = ({ boardId, folderId, source }) =>
    navigate(`/${boardId}/${folderId}/${search}`, { state: { source } })

  return {
    openFolder,
    previewNode,
  }
}

export default useNodeActions
