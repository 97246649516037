import { MouseEventHandler, RefObject } from 'react'

import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useCookie from '../../../../hooks/useCookie'
import { limitNumberWithinRange } from '../../../../helpers/numbers'

const MIN_SIDEBAR_WIDTH = 212
const MIN_SIDEBAR_WIDTH_FREE_PLAN = 300
const DEFAULT_SIDEBAR_WIDTH = 300
const MAX_SIDEBAR_WIDTH = window.screen.width / 4

export const useResizable = (
  elementRef: RefObject<HTMLElement>,
  dropHandler?: () => void,
) => {
  const { isFreePlanUser } = useReverseTrialContext()
  const [sidebarWidth, setSidebarWidth] = useCookie(
    'sidebar-width',
    DEFAULT_SIDEBAR_WIDTH,
  )

  const resizableWidth = limitNumberWithinRange(
    sidebarWidth,
    isFreePlanUser ? MIN_SIDEBAR_WIDTH_FREE_PLAN : MIN_SIDEBAR_WIDTH,
    MAX_SIDEBAR_WIDTH,
  )

  const resizeHandler: MouseEventHandler = (e) => {
    if (!elementRef.current) {
      return
    }

    window.addEventListener('mousemove', mousemove)
    window.addEventListener('mouseup', mouseup)

    const prevX = e.pageX
    const leftPanel = elementRef.current.getBoundingClientRect()

    function mousemove(event: MouseEvent) {
      const newWidth = leftPanel.width - (prevX - event.x)
      const newPanelWidth = limitNumberWithinRange(
        newWidth,
        MIN_SIDEBAR_WIDTH,
        MAX_SIDEBAR_WIDTH,
      )
      if (sidebarWidth !== newPanelWidth) {
        setSidebarWidth(newPanelWidth)
      }
    }

    function mouseup() {
      window.removeEventListener('mousemove', mousemove)
      window.removeEventListener('mouseup', mouseup)
      if (dropHandler) {
        dropHandler()
      }
    }
  }

  return {
    resizableWidth,
    resizeHandler,
  }
}
