import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckoutFormProduct,
  CheckoutFormProducts,
  FormFields,
  isNodeAudio,
  isNodeFolder,
  isNodeForm,
  isNodeImage,
  isNodeLink,
  isNodePage,
  isNodePDF,
  isNodeVideo,
  isNodeVideoStreaming,
  isPageNodeProfile,
  NODE_LINK_TYPES,
  NodeType as NodeTypeWebUI,
  PaymentStatuses,
  TNode,
  TNodeAudio,
  TNodeCheckoutForm,
  TNodeFolder,
  TNodeForm,
  TNodeImage,
  TNodeLink,
  TNodePage,
  TNodePDF,
  TNodeText,
  TNodeVideo,
  TNodeVideoStreaming,
  urlHelper,
  validateForms,
} from 'boards-web-ui'
import useFormFieldTransformer from '@features/nodes/hooks/useFormFieldTransformer'
import { getPdfThumbnailWithPlaceholder } from '@helpers/getPdfThumbnailWithPlaceholder'
import { PAGE_PLACEHOLDER } from 'constants/pagePlaceholder'
import { Node, NodeType } from '../../../models'

// TODO move to boards-web-ui
const isCheckoutProduct = (field: unknown): field is CheckoutFormProduct => {
  return (field as CheckoutFormProduct)?.type === 'product'
}

export type NodeWithSource<T = TNode> = {
  node: T
  source: Node
}
const usePageNodeTransformer = () => {
  const { t } = useTranslation()
  const { transformFormFields } = useFormFieldTransformer()
  const errorMessages = useMemo(
    () => ({
      requiredLabel: t('label_required'),
      invalidEmailLabel: t('error_invalid_email'),
      invalidPhoneNumberLabel: t('error_invalid_number'),
    }),
    [t],
  )

  const transformPageNode = useCallback(
    (source: Node) => {
      const nodeSource = source as unknown as TNode

      if (isCheckoutForm(nodeSource)) {
        const { shippingFee, minOrder, currency } = nodeSource.form
        const additionalFields: FormFields = []
        const products: CheckoutFormProducts = []

        nodeSource?.form?.fields?.forEach((field) => {
          if (isCheckoutProduct(field)) {
            return products.push(field as CheckoutFormProduct)
          }

          return additionalFields.push(field)
        })

        return {
          source,
          node: {
            type: NodeType.CHECKOUT,
            form: {
              type: NodeType.CHECKOUT,
              fields: transformFormFields(source.form?.fields),
              products,
              additionalFields,
              currency,
              minOrder,
              shippingFee,
              errorInPageText: t('checkout_error_in_this_page'),
              errorTexts: {
                minimumOrderError: '',
                errorInPage: t('checkout_error_in_this_page'),
              },
              paymentStatus: PaymentStatuses.NOT_CONNECTED,
              isReadyToAcceptPayments: true,
              merchantPaymentsReceivable: true,
            },
            translations: {
              checkoutSuccess: t('checkout_payment_successful'),
              checkoutSuccessRedirecting: t(
                'checkout_payment_successful_redirecting',
              ),
              buttonPaypal: t('checkout_pay_with'),
              subtotal: t('checkout_subtotal'),
              shipping: t('checkout_shipping_fees'),
              total: t('checkout_total'),
              buttonCreditCard: t('Pay with credit card'),
              choiceFieldHelper: t('forms_multi_select_choose_many'),
            },
            onValidate: validateForms({
              requiredLabel: t('label_required'),
              invalidEmailLabel: t('error_invalid_email'),
              invalidPhoneNumberLabel: t('error_invalid_number'),
            }),
          } as unknown as TNodeCheckoutForm,
        }
      }

      if (isNodeForm(nodeSource)) {
        return {
          source,
          node: {
            type: NodeTypeWebUI.FORM,
            title: source.title || null,
            onValidate: validateForms(errorMessages),
            form: {
              encryptedEmail: nodeSource?.form?.encryptedEmail,
              encryptedSpreadsheetURL:
                nodeSource?.form?.encryptedSpreadsheetURL,
              errorInPageText: t('checkout_error_in_this_page'),
              choiceFieldHelperText: t('forms_multi_select_choose_many'),
              title: source.title || '',
              action:
                nodeSource?.form?.action ||
                t('forms_submit_button_label_default'),
              response:
                nodeSource?.form?.response || t('forms_thanks_message_default'),
              fields: transformFormFields(nodeSource?.form?.fields),
            },
          } as unknown as TNodeForm,
        }
      }

      if (isNodeFolder(nodeSource)) {
        return {
          source,
          node: {
            type: NodeTypeWebUI.FOLDER,
            title: source.title || t('default_title_my_folder'),
            icon: source.icon || null,
          } as TNodeFolder,
        }
      }

      if (isNodeImage(nodeSource)) {
        return {
          source,
          node: {
            type: NodeTypeWebUI.IMAGE,
            title: source.title || null,
            thumbnail: source.text || null,
            height: source.height || null,
            width: source.width || null,
          } as TNodeImage,
        }
      }

      if (isNodeLink(nodeSource)) {
        return {
          source,
          node: {
            title: source.title || null,
            type: NodeTypeWebUI.LINK,
            src: source.text || null,
          } as TNodeLink,
        }
      }

      if ([NodeType.BUTTON].includes(source.type)) {
        let { title } = source
        if (!title?.trim()) {
          if (source.link_type === NODE_LINK_TYPES.REGULAR) {
            title = t('button_link_label_auto_populate')
          } else {
            title = t('page_my_button')
          }
        }

        return {
          source,
          node: {
            title,
            type: NodeTypeWebUI.LINK,
            src: source.text || null,
          } as TNodeLink,
        }
      }

      if (isNodePage(nodeSource)) {
        return {
          source,
          node: {
            type: NodeTypeWebUI.PAGE,
            title: source.title || null,
            thumbnail: source.thumbnail || PAGE_PLACEHOLDER,
          } as TNodePage,
        }
      }

      if (isNodePDF(nodeSource)) {
        return {
          source,
          node: {
            type: NodeTypeWebUI.PDF,
            thumbnail: getPdfThumbnailWithPlaceholder(source),
            title: source.title || null,
            text: source.text,
          } as TNodePDF,
        }
      }

      if (
        [NodeType.TEXT, NodeType.HEADLINE, NodeType.PARAGRAPH].includes(
          source.type,
        )
      ) {
        return {
          source,
          node: {
            type: NodeTypeWebUI.TEXT,
            title: source.title?.trim() || null,
            text: source.text?.trim() || null,
          } as TNodeText,
        }
      }

      if (isNodeVideo(nodeSource)) {
        const videoDetails = urlHelper.getVideoDetails(source.text)

        let fullbackThumbnail = null

        if (videoDetails?.type === 'youtube') {
          fullbackThumbnail = '/placeholders/youtube.png'
        }

        if (videoDetails?.type === 'vimeo') {
          fullbackThumbnail = '/placeholders/vimeo.png'
        }

        return {
          source,
          node: {
            src: videoDetails?.url,
            type: NodeTypeWebUI.VIDEO,
            thumbnail: source.thumbnail,
            fullbackThumbnail,
            title: source.title || null,
          } as TNodeVideo,
        }
      }

      if (isNodeVideoStreaming(nodeSource)) {
        return {
          source,
          node: {
            src: source.text,
            type: NodeTypeWebUI.VIDEO_STREAMING,
            thumbnail: source.thumbnail,
            title: source.title || null,
            width: source.width || 675,
            height: source.height || 380,
            isProcessing: source.isProcessing,
            processingLabel: t('label_processing_video'),
          } as TNodeVideoStreaming,
        }
      }

      if (isNodeAudio(nodeSource)) {
        return {
          source,
          node: {
            type: NodeType.AUDIO,
            src: source?.text,
            title: source?.title || t('label_my_recording'),
            duration: source?.duration,
            thumbnail: '',
          } as unknown as TNodeAudio,
        }
      }

      if (isPageNodeProfile(nodeSource)) {
        return {
          source,
          node: {
            ...nodeSource,
          },
        }
      }

      throw new Error(`Type ${source?.type} is not equal to TNode type`)
    },
    [errorMessages, t, transformFormFields],
  )

  return transformPageNode
}

export const isCheckoutForm = (node: unknown): node is TNodeCheckoutForm => {
  return (
    (node as Node)?.type === NodeType.CHECKOUT ||
    (node as TNodeCheckoutForm)?.form?.type === NodeType.CHECKOUT
  )
}

export default usePageNodeTransformer
