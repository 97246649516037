import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Grid, RetryIcon, SpinnerIcon } from 'boards-web-ui'

import ProgressBar from '@features/nodeEditor/components/ProgressBar'

import styles from './UploadProgressBar.module.css'

interface Props {
  isError: boolean
  progress: number
  retry: () => void
}

const UploadProgressBar: FC<Props> = ({ isError, progress, retry }) => (
  <div className={styles.ProgressBarContainer}>
    <Grid justifyContent="space-between">
      <span className={styles.UploadingLabel}>
        {isError ? (
          <Trans i18nKey="upload_failed_please_try_again" />
        ) : (
          <Trans i18nKey="label_uploading" />
        )}
      </span>

      {isError ? (
        <RetryIcon onClick={retry} className={styles.RetryIcon} />
      ) : (
        <SpinnerIcon className={styles.SpinnerIcon} />
      )}
    </Grid>
    <ProgressBar progress={progress} isRed={isError} />
  </div>
)

export default UploadProgressBar
