import { ReactNode } from 'react'
import { theme, useDialog } from 'boards-web-ui'

import ConfirmDialog from '../ui/components/ConfirmDialog'

interface ConfirmDialogProps {
  icon?: ReactNode
  title: string
  message?: ReactNode | string
  cancelText?: string
  confirmText: string
  className?: string
  confirmButtonType?: 'primary' | 'secondary' | 'warning'
  stacked?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  onEscape?: () => void
}

const useConfirmDialog = () => {
  const { open, close } = useDialog()

  const confirmDialog = ({
    icon,
    title,
    message,
    cancelText,
    confirmText,
    confirmButtonType,
    className,
    stacked,
    onConfirm,
    onCancel,
    onEscape,
  }: ConfirmDialogProps) => {
    open(
      <ConfirmDialog
        icon={icon}
        title={title}
        message={message}
        cancelText={cancelText}
        confirmButtonType={confirmButtonType}
        confirmText={confirmText}
        stacked={stacked}
        className={className}
        onCancel={() => {
          close()
          if (onCancel) {
            onCancel()
          }
        }}
        onConfirm={() => {
          close()

          if (onConfirm) onConfirm()
        }}
      />,
      {
        onEscape,
        hideParents: false,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark50,
      },
    )
  }

  return confirmDialog
}

export default useConfirmDialog
