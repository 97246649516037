import React, { ReactNode } from 'react'

import clsx from 'clsx'
import { ButtonNew } from 'boards-web-ui'
import { SimpleDialog } from '@ui/components/SimpleDialog'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import styles from './DeleteNodeConfirmation.module.css'

type Props = {
  icon?: ReactNode
  title: string
  body: string
  onCancel: () => void
  onConfirm: () => void
  confirmTitle?: string
  cancelTitle?: string
  showVerticalActionButtons?: boolean
}
const DeleteNodeConfirmation: React.FC<Props> = ({
  icon,
  title,
  body,
  onCancel,
  onConfirm,
  confirmTitle,
  cancelTitle,
  showVerticalActionButtons = false,
}) => {
  const { cancelDelete, confirmDelete } = useToolbarTranslations()
  return (
    <SimpleDialog
      icon={icon}
      className={clsx(
        styles.Root,
        showVerticalActionButtons && styles.WiderDialog,
      )}
      title={
        <p className={showVerticalActionButtons ? styles.WiderDialogTitle : ''}>
          {title}
        </p>
      }
      message={<p className={styles.Centred}>{body}</p>}
    >
      <div
        className={clsx(
          styles.Controls,
          showVerticalActionButtons && styles.VerticalActionButtons,
        )}
      >
        <ButtonNew
          color="secondary"
          size="large"
          onClick={onCancel}
          fullWidth={showVerticalActionButtons}
        >
          {cancelTitle || cancelDelete()}
        </ButtonNew>

        <ButtonNew
          color={showVerticalActionButtons ? 'secondary' : 'warning'}
          size="large"
          onClick={onConfirm}
          fullWidth={showVerticalActionButtons}
        >
          {confirmTitle || confirmDelete()}
        </ButtonNew>
      </div>
    </SimpleDialog>
  )
}

export default DeleteNodeConfirmation
