import { useContext } from 'react'
import { IListeners } from '../../app/models/listeners'
import FirebaseListenersContext from '../providers/FirebaseListenersContext'

const useFirebaseListeners = () => {
  const {
    startClientConfigListener,
    startBoardFoldersListener,
    startBoardPermissionsListener,
    startBoardUpdatesMessagesListener,
    stopBoardUpdatesMessagesListener,
    stopBoardFoldersListener,
  } = useContext(FirebaseListenersContext)

  return {
    startClientConfigListener,
    startBoardFoldersListener,
    startBoardPermissionsListener,
    startBoardUpdatesMessagesListener,
    stopBoardUpdatesMessagesListener,
    stopBoardFoldersListener,
  } as IListeners
}

export default useFirebaseListeners
