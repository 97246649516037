import { useSearchParams } from 'react-router-dom'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { useUpdatesMessage } from './useUpdatesMessage'
import useBoard from '../../../../hooks/useBoard'

import {
  updatesMessagesByBoardId,
  updatesUnReadMessagesByBoardId,
} from '../../../../selectors'

import { UiUpdatesMessage, UpdatesMessages } from '../../../models'
import { QUERY_PARAMS } from '../../../constants/routes'

interface BoardPermissions {
  viewOnly: boolean
}
interface UpdatesMessagesState extends BoardPermissions {
  messages: UpdatesMessages
  unReadMessages: UpdatesMessages
  selectedMessage: UiUpdatesMessage | null
}

export const useUpdatesMessages = (): UpdatesMessagesState => {
  const { boardId, isReadOnly } = useBoard()
  const [searchParams] = useSearchParams({})

  const messages = useSelectorFactory(updatesMessagesByBoardId, boardId)

  const unReadMessages = useSelectorFactory(
    updatesUnReadMessagesByBoardId,
    boardId,
  )

  const { message: selectedMessage } = useUpdatesMessage(
    searchParams.get(QUERY_PARAMS.message),
  )

  return {
    messages,
    unReadMessages,
    selectedMessage,
    viewOnly: isReadOnly,
  } as const
}
