import { callFirebaseFunction } from './utils'
import { contentFunctions as functions } from './functions'

export interface ICopyNodesParams {
  targetBoardId: string
  targetFolderId?: string
  nodes: string[]
  key: string
  fromParams: {
    fromBoardId: string
    fromFolderId: string
    fromShortcutId?: string
    fromTemplate?: string
  }
  location?: 'start'
}

export const copyNodesApiCall = ({
  targetBoardId,
  targetFolderId,
  nodes,
  key,
  fromParams: { fromBoardId, fromFolderId, fromShortcutId },
  location,
}: ICopyNodesParams) => {
  const args = {
    board: targetBoardId,
    folder: targetFolderId,
    nodes,
    key,
    ...(fromShortcutId
      ? { fromShortcut: fromShortcutId }
      : { fromFolder: fromFolderId, fromBoard: fromBoardId }),
    location,
  }

  return callFirebaseFunction(functions, 'copyNodes', args)
}

interface ICopyTemplateParams {
  targetBoardId?: string
  targetFolderId?: string
  template: string
  key: string
  location?: 'start'
}
export const copyTemplateApiCall = ({
  targetBoardId,
  targetFolderId,
  key,
  template,
  location,
}: ICopyTemplateParams) => {
  const args = {
    board: targetBoardId,
    folder: targetFolderId,
    key,
    template,
    location,
  }

  return callFirebaseFunction(functions, 'copyTemplate', args)
}
