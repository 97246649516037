import { useDialog } from 'boards-web-ui'

import { VideoMetaData } from '@hooks/useUploadFileMetadata'
import AddVideoStreamingFile from '@features/nodeEditor/components/VideoStreaming/AddVideoStreamingFile'
import AddVideoStreamingNode from '@features/nodeEditor/components/VideoStreaming/AddVideoStreamingNode'
import useOpenVideoStreamingPicker from '@features/nodeEditor/components/VideoStreaming/useOpenVideoStreamingPicker'
import useValidateVideoStreamingFile from '@features/nodeEditor/components/VideoStreaming/useValidateVideoStreamingFile'
import useSaveVideoStreamingNode from '@features/nodeEditor/components/VideoStreaming/useSaveVideoStreamingNode'

import { NodeType, Node } from 'app/models'

import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { IUseAddVideoStreaming } from '../models/IUseAddNode'

type VideoStreamingNode = Node & { type: NodeType.VIDEO_STREAMING }

const useAddVideoStreaming: IUseAddVideoStreaming = ({
  save,
  update,
  isPageEditor,
  isEditVideoMode,
}) => {
  const { open } = useDialog()
  const validateVideoStreamingFile = useValidateVideoStreamingFile()
  const { hideVideoHint } = useProfileInformation()
  const saveNodeOnDatabase = useSaveVideoStreamingNode({
    onSave: save,
  })

  const handleFilePicked = async (newFile: File) => {
    if (isEditVideoMode || !save || !update) {
      return
    }

    const videoMetadataFromFile = await validateVideoStreamingFile(newFile)

    if (!videoMetadataFromFile) return

    hideVideoHint()

    open(
      <AddVideoStreamingFile
        file={newFile}
        isPageEditor={isPageEditor}
        onSave={save}
        onUpdate={update}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  const handleNodePicked = (node: VideoStreamingNode) => {
    if (isEditVideoMode || !save || !update) {
      return
    }

    const metadata: VideoMetaData = {
      duration: node.duration || 0,
      width: node.width || 0,
      height: node.height || 0,
    }

    const savedNode = saveNodeOnDatabase(node.text, metadata, node.thumbnail)

    if (savedNode) {
      open(
        <AddVideoStreamingNode
          node={savedNode}
          isPageEditor={isPageEditor}
          onUpdate={update}
        />,
        {
          fullScreen: true,
          blankDialog: true,
        },
      )
    }
  }

  const addVideoStreaming = useOpenVideoStreamingPicker({
    isNew: true,
    onFilePicked: handleFilePicked,
    onNodePicked: handleNodePicked,
  })

  return addVideoStreaming
}

export default useAddVideoStreaming
