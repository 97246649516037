import {
  CheckoutEventNames,
  CheckoutEventsTimePeriodInterval,
  PaddleEventData,
} from '@paddle/paddle-js'
import { useAuth } from '@hooks/useAuth'
import usePaywallEvents from '@features/analytics/usePaywallEvents'
import { getIsPayingUser } from '@features/reverseTrial/heplers/getReverseAnalyticsGlobalParams'
import { updateUserProperties } from '../../../../api/AnalyticsAPI'

const PeriodIntervalTo = {
  [CheckoutEventsTimePeriodInterval.DAY]: 'daily',
  [CheckoutEventsTimePeriodInterval.WEEK]: 'weekly',
  [CheckoutEventsTimePeriodInterval.MONTH]: 'monthly',
  [CheckoutEventsTimePeriodInterval.YEAR]: 'yearly',
}
const useHandleEventCallback = (
  campaignManagerId: string | undefined,
  closeTabAfterSubscription: boolean,
) => {
  const { paywallSubscriptionStartEvent } = usePaywallEvents()
  const { user } = useAuth()

  return (event: PaddleEventData) => {
    if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
      const item = event.data?.items[0]

      if (item) {
        const frequency = item.billing_cycle?.frequency || 0
        const interval = item.billing_cycle?.interval

        let subscriptionCycle = ''
        if (interval) {
          subscriptionCycle =
            frequency > 1
              ? `${frequency}_${interval}s`
              : PeriodIntervalTo[interval]
        }

        const productId = item.product.id
        const plan = item.product.name.toLowerCase()
        const isTrial = !!item.trial_period

        paywallSubscriptionStartEvent({
          plan,
          subscriptionCycle,
          trial: isTrial,
          productId,
          campaignManagerId,
        })
        if (user) {
          const isPayingUser = getIsPayingUser({
            planName: plan,
            isReverseTrial: isTrial,
          })

          updateUserProperties(user, {
            plan,
            subscription_active: `${Boolean(isPayingUser)}`,
            subscription_plan: isPayingUser ? plan : null,
          })
        }
      }

      setTimeout(() => {
        if (closeTabAfterSubscription) {
          window.close()
          return
        }

        window.location.href = '/'
      }, 2000)
    }
  }
}

export default useHandleEventCallback
