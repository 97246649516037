export const enum CLIENT_CONFIG_FEATURES {
  EMAIL_ADDRESS = 'emailAddress',
  CLIENT_CONFIGURATION = 'clientConfiguration',
  ACADEMY = 'academy',
}

export type DefaultClientConfig = {
  reverseTrial: {
    isActive: boolean
  }
}

export type EmailAdressClientConfig = {
  format: RegExp
  frequentlyUsedDomains: string[]
  topLevelDomains: string[]
}

export type AcademyClientConfig = {
  isEnabled: boolean
  url: string
}

export type GeneralClientConfigType =
  | DefaultClientConfig
  | EmailAdressClientConfig
  | AcademyClientConfig

export interface ClientConfig {
  [CLIENT_CONFIG_FEATURES.EMAIL_ADDRESS]: EmailAdressClientConfig
  [CLIENT_CONFIG_FEATURES.CLIENT_CONFIGURATION]: DefaultClientConfig
  [CLIENT_CONFIG_FEATURES.ACADEMY]: AcademyClientConfig
}
