import React, { memo, useCallback } from 'react'

import clsx from 'clsx'
import { ButtonIcon, If, Tooltip, TooltipContent } from 'boards-web-ui'
import { MessageComposeTrigger } from '@features/updates/components/preview/MessageComposeTrigger'
import { ReactComponent as BackIcon } from '../../../../icons/dubbleArrow.svg'
import { useUpdatesTools } from '../hooks/useUpdatesTools'
import { useUpdatesMessages } from '../hooks/useUpdatesMessages'
import { useUpdatesApi } from '../hooks/useUpdatesApi'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import styles from './UpdatesSectionHeader.module.css'

type Props = {
  shouldDisplayComposeTrigger: boolean
}

const UpdatesSectionHeader: React.FC<Props> = ({
  shouldDisplayComposeTrigger,
}) => {
  const { close } = useUpdatesTools()
  const { messages } = useUpdatesMessages()
  const { marksAllAsRead } = useUpdatesApi()
  const { closeButtonTooltip, postUpdate } = useUpdatesTranslations()

  const readAll = useCallback(() => {
    marksAllAsRead(messages)
  }, [marksAllAsRead, messages])
  return (
    <div
      className={clsx(
        styles.Root,
        shouldDisplayComposeTrigger && styles.withComposeTrigger,
      )}
    >
      <div className={styles.HeaderWrapper}>
        <Tooltip
          className={styles.CloseButton}
          as="div"
          data={<TooltipContent>{closeButtonTooltip()}</TooltipContent>}
        >
          <ButtonIcon
            size={38}
            round
            variant="gray"
            onClick={() => {
              close()
              readAll()
            }}
          >
            <BackIcon fontSize={20} />
          </ButtonIcon>
        </Tooltip>
        <h3 className={styles.Header}>{postUpdate()}</h3>
      </div>
      <If state={shouldDisplayComposeTrigger}>
        <MessageComposeTrigger />
      </If>
    </div>
  )
}

export default memo(UpdatesSectionHeader)
