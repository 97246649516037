import React, { FC, memo, SyntheticEvent, useCallback, useState } from 'react'
import clsx from 'clsx'
import { If } from 'boards-web-ui'
import { MessageAttachments } from '@features/updates/components/MessageAttachments'
import useBoard from '@hooks/useBoard'
import { useOnMount } from '@ui/hooks/useOnMount'
import { useAuth } from '@hooks/useAuth'
import { useToast } from '@hooks/useToast'
import { useUpdatesTranslations } from '@features/updates/hooks/useUpdatesTranslations'
import { useUpdatesMessage } from '@features/updates/hooks/useUpdatesMessage'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'
import { useUpdateMessageActions } from '@features/updates/hooks/useUpdateMessageActions'
import Grid from '../../../../elements/Grid'

import { MessageAttachment, UiUpdatesMessage } from '../../../models'

import UpdatesMessageHeader from './UpdatesMessageHeader'
import UpdatesMessageBody from './UpdatesMessageBody'
import UpdatesMessageSeenBy from './UpdatesMessageSeenBy'
import styles from './UpdatesMessage.module.css'

export enum UpdatesSourceEnum {
  Feed = 'message_feed',
  Screen = 'message_screen',
}

type Props = {
  message: UiUpdatesMessage
  className?: string
  presentationMode?: boolean
  onClick?: (event: SyntheticEvent) => void
  source: UpdatesSourceEnum
}

const UpdatesMessage: FC<Props> = ({
  message,
  className,
  presentationMode = false,
  onClick,
  source,
}) => {
  const { isEditor, isOwnBoard } = useBoard()
  const { user } = useAuth()
  const toast = useToast()
  const { toastSent } = useUpdatesTranslations()

  const { read, count } = useUpdatesMessage(message?.id)
  const { messageView } = useUpdatesEvents()
  const [postedJustNow, setPostedJustNow] = useState(
    message?.unread && presentationMode,
  )

  const onMount = useCallback(() => {
    if (!presentationMode) {
      messageView(message?.node, message?.id, count || 0)
    }

    if (message?.unread && !presentationMode) {
      read()
    }

    if (postedJustNow && user?.uid === message.sender.id) {
      toast(toastSent(), true, 300)
      const timer = setTimeout(() => {
        setPostedJustNow(false)
        clearTimeout(timer)
      }, 1000)
    }
  }, [
    toast,
    toastSent,
    postedJustNow,
    message,
    presentationMode,
    read,
    count,
    user,
    messageView,
  ])

  useOnMount(onMount)

  const messageAttachment: MessageAttachment | null = message.processedContent

  const { handleOpenInBoardsClick, handlePreviewAttachmentClick } =
    useUpdateMessageActions({ source, messageAttachment, message })

  const contentId = message.content?.ids[0] ?? ''
  const nodeFolderId = message.content?.properties[contentId]?.folder

  return (
    <div
      className={clsx([
        styles.Root,
        presentationMode && styles.Hovered,
        message?.unread && presentationMode && styles.Unread,
        postedJustNow && styles.PostedJustNow,
        className,
      ])}
      onClick={onClick}
    >
      <UpdatesMessageHeader
        presentationMode={presentationMode}
        boardTitle={message?.title}
        author={message?.sender?.properties?.name}
        icon={message?.icon}
        message={message}
      />

      <UpdatesMessageBody
        title={message?.properties?.title}
        text={message?.properties?.text}
        presentationMode={presentationMode}
      />

      {messageAttachment && (
        <MessageAttachments
          messageAttachment={messageAttachment}
          onAttachmentClick={handlePreviewAttachmentClick}
          onShowInBoardClick={handleOpenInBoardsClick}
          isDeletedNode={!nodeFolderId}
        />
      )}

      <If state={isEditor || isOwnBoard}>
        <Grid justifyContent="space-between" alignSelf="flex-end">
          <UpdatesMessageSeenBy count={message ? message.viewCount : 0} />
        </Grid>
      </If>
    </div>
  )
}

export default memo(UpdatesMessage)
