import { useContext } from 'react'
import { UpdatesContext } from '../UpdatesProviders'
import useBoard from '../../../../hooks/useBoard'

interface IUseUpdatesTools {
  isOpen: boolean
  disabled: boolean
  isUnAvailable: boolean
  shouldAutoOpenMessagesSection: boolean
  setShouldAutoOpenMessagesSection: (value: boolean) => void
  close: () => void
  toggle: () => void
}

export const useUpdatesTools = (): IUseUpdatesTools => {
  const state = useContext(UpdatesContext)

  const { board } = useBoard()

  const isUnAvailable = !state.isAvailable || !board || board.isPageBoard

  return {
    isOpen: state.isOpen,
    disabled: state.disabled,
    isUnAvailable,
    close: state.close,
    toggle: state.toggle,
    shouldAutoOpenMessagesSection: state.shouldAutoOpenMessagesSection,
    setShouldAutoOpenMessagesSection: state.setShouldAutoOpenMessagesSection,
  }
}
