import { FC, useEffect } from 'react'
import FolderSelection from '@features/pickers/components/FolderSelection'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import useRouterParams from '@hooks/useRouterParams'
import { useDispatch } from 'react-redux'
import useFirebaseListeners from '../../../../../_firebase/hooks/useFirebaseListeners'
import { Node as NodeModel } from '../../../../models'
import { boardActions } from '../../../../../actions'

interface Props {
  nodes: NodeModel[]
  toggleNode: (node: NodeModel) => void
  showClearMsgIfHasSelectedNodes: (callback: () => void) => void
}

export const ComposeContentDialogBody: FC<Props> = ({
  nodes,
  toggleNode,
  showClearMsgIfHasSelectedNodes,
}) => {
  const {
    pickerBoardId: boardId,
    pickerFolderId: folderId,
    isPage,
    selectFolder,
  } = useContentNavigation(ContentPickersEnum.Compose)

  const { startBoardFoldersListener, stopBoardFoldersListener } =
    useFirebaseListeners()
  const dispatch = useDispatch()

  const { boardId: currentBoardId, folderId: currentFolderId } =
    useRouterParams()

  useEffect(() => {
    if (!boardId) {
      return undefined
    }

    const [uid, bid] = boardId.split('-')
    const hash = 'compose-picker'
    startBoardFoldersListener(uid, bid, hash)

    return () => stopBoardFoldersListener(uid, bid, hash)
  }, [boardId, startBoardFoldersListener, stopBoardFoldersListener])

  const shouldUpdateComposePath = currentBoardId !== boardId

  if (shouldUpdateComposePath) {
    dispatch(
      boardActions.setFolderPathAction(
        ContentPickersEnum.Compose,
        currentBoardId,
        currentFolderId,
      ),
    )
  }

  return boardId && folderId && !isPage ? (
    <FolderSelection
      nodes={nodes}
      boardId={boardId}
      folderId={folderId}
      selectFolder={(fid) =>
        showClearMsgIfHasSelectedNodes(() => selectFolder(fid))
      }
      toggleNode={toggleNode}
      picker={ContentPickersEnum.Compose}
    />
  ) : null
}
