export const createFileFromUrl = async (
  url: string,
  fileName: string,
): Promise<File> => {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`Failed to fetch file from URL: ${response.statusText}`)
  }

  const data: Blob = await response.blob()

  const file: File = new File([data], fileName, {
    type: data.type,
  })

  return file
}
