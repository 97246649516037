import { useDispatch } from 'react-redux'
import { UploadTask } from 'firebase/storage'
import { useCallback, useRef, useState } from 'react'

import { ENV_KEYS } from 'utils/envKeys'
import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { uploadPdf, uploadVideo } from '../_firebase'
import { randomContentId } from '../helpers'
import { useAuth } from './useAuth'
import { uploadActions } from '../actions'

interface UploadTaskSnapshot {
  bytesTransferred: number
  totalBytes: number
}
export enum FirebaseUploadFileType {
  VIDEO = 'mp4',
  PDF = 'pdf',
}

const calcSnapshotProgressPercent = (snapshot: UploadTaskSnapshot) =>
  Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

const preventExitWhileLoading = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = false
}
const { videoUrl, pdfUrl } = configurationObjectFromEnv({
  videoUrl: ENV_KEYS.VIDEO_URL,
  pdfUrl: ENV_KEYS.PDF_URL,
})

const addPreventExitDialog = () =>
  window.addEventListener('beforeunload', preventExitWhileLoading)
const removePreventExitDialog = () =>
  window.removeEventListener('beforeunload', preventExitWhileLoading)

const useUploadFirebase = () => {
  const { user } = useAuth()
  const uploadTaskRef = useRef<UploadTask>()
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [progress, setProgress] = useState(0)

  const uploadFile = useCallback(
    (
      file: File,
      fileType: FirebaseUploadFileType,
    ): Promise<{ url: string }> => {
      return new Promise((resolve, reject) => {
        if (!user?.uid) {
          reject(new Error('User not authenticated.'))
          return
        }

        setError(false)
        addPreventExitDialog()

        const assetId = randomContentId()
        const fileName = `${assetId}.${fileType}`
        const fileUrl =
          fileType === FirebaseUploadFileType.PDF
            ? encodeURI(`${pdfUrl}/${fileName}`)
            : encodeURI(`${videoUrl}/${assetId}.m3u8`)

        dispatch(uploadActions.addUpload(fileUrl))

        const uploadFunction =
          fileType === FirebaseUploadFileType.PDF ? uploadPdf : uploadVideo

        uploadTaskRef.current = uploadFunction(file, fileName, user.uid)

        uploadTaskRef.current.on(
          'state_changed',
          (snapshot: UploadTaskSnapshot) => {
            setProgress(calcSnapshotProgressPercent(snapshot))
          },
          (err) => {
            setError(true)
            removePreventExitDialog()

            dispatch(uploadActions.removeUpload(fileUrl))
            reject(err)
          },
          () => {
            dispatch(uploadActions.removeUpload(fileUrl))

            removePreventExitDialog()

            resolve({
              url: fileUrl,
            })
          },
        )
      })
    },
    [user?.uid, dispatch],
  )

  const createUploadHandler = (action: 'cancel' | 'pause' | 'resume') => () => {
    if (uploadTaskRef.current) {
      uploadTaskRef.current[action]()
      removePreventExitDialog()
    }
  }

  return {
    error,
    progress,
    pauseUpload: createUploadHandler('pause'),
    resumeUpload: createUploadHandler('resume'),
    cancelUpload: createUploadHandler('cancel'),
    uploadFile,
  }
}

export default useUploadFirebase
