import React, { FC, memo, useRef } from 'react'
import {
  AnimatedDialog,
  InputGlobalEvents,
  theme,
  useCustomEvent,
} from 'boards-web-ui'

import useOnScrollHandler from '@hooks/useOnScrollHandler'

import { ModalEditWithConfirmationAndTitle } from '../../../../components/ModalEditWithConfirmation'

import { useCheckout } from '../hooks'
import CheckoutHeaderControls from './CheckoutHeaderControls'
import CheckoutBody from './CheckoutBody'
import FooterControls from './FooterControls'
import styles from './Checkout.module.css'

const CheckoutDialog: FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { onCheckoutCancel } = useCheckout()
  const { dispatch: dispatchCloseDropDownsEvent } = useCustomEvent(
    InputGlobalEvents.CLOSE,
  )
  useOnScrollHandler(ref?.current, () => dispatchCloseDropDownsEvent())

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        onOverlayClick: onCheckoutCancel,
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          responsiveHeight
          withBorders
          withFixedBody
          ref={ref}
          className={styles.CheckoutRoot}
          headerControls={<CheckoutHeaderControls onClose={onCheckoutCancel} />}
          footerControls={<FooterControls />}
          onClick={() => dispatchCloseDropDownsEvent()}
        >
          <CheckoutBody wrapperRef={ref} />
        </ModalEditWithConfirmationAndTitle>
      }
    ></AnimatedDialog>
  )
}

export default memo(CheckoutDialog)
