import { useDialog } from 'boards-web-ui'

import { IUseAddNode } from '@features/nodeEditor/models/IUseAddNode'
import EditPageProfile from '@features/nodeEditor/components/EditPageProfile'

import useFirebaseImageUploader from '../../../../_firebase/useFirebaseImageUploader'

const useAddProfile: IUseAddNode = (save) => {
  const { open, close } = useDialog()
  const firebaseImageUploadProvider = useFirebaseImageUploader()

  const addProfile = () => {
    open(
      <EditPageProfile
        imageUploadProvider={firebaseImageUploadProvider}
        onSave={(data) => {
          save(data)
          close()
        }}
        onCancel={close}
        isNew
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return addProfile
}

export default useAddProfile
