import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import useRemoteConfig from '@hooks/useRemoteConfig'
import {
  calculateDaysLeftUntilDueDate,
  calculateHoursLeftUntilDueDate,
} from '@hooks/useCountdownTimer'
import { useAppClientConfig } from '@hooks/useAppClientConfig'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import {
  Activation,
  PLAN_NAMES,
  ReverseTrialContextProps,
  ReverseTrialUserFlags,
  USER_PREFERENCE_FIELDS,
} from '@features/reverseTrial/models/reverseTrialModels'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useReverseTrialLocalStorageHelper from '@features/reverseTrial/hooks/useReverseTrialLocalStorageHelper'
import { UserProfileUIState } from '@models/UserProfile'
import { REMOTE_CONFIG_KEYS, ReverseTrialConfig } from '../../models'
import { startReverseTrial } from '../../../_firebase'
import * as ROUTES from '../../constants/routes'
import {
  getFirstActivation,
  selectProfileInformation,
} from '../../../selectors'

import { CLIENT_CONFIG_FEATURES } from '../../models/clientConfig'

const ReverseTrialContext = createContext<ReverseTrialContextProps | undefined>(
  undefined,
)

const ReverseTrialProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation()
  const { hideEndOfReverseTrialScreen } = useProfileInformation()
  const profile = useSelectorFactory(selectProfileInformation)
  const activation: Activation | undefined =
    useSelectorFactory(getFirstActivation)

  const reverseTrial: ReverseTrialUserFlags = { ...profile.reverseTrial }
  const uiState: UserProfileUIState = { ...profile.uiState }

  const [reverseTrialConfigs, isRemoteConfigLoading] = useRemoteConfig(
    REMOTE_CONFIG_KEYS.REVERSE_TRIAL_CONFIGS,
  ) as [ReverseTrialConfig, boolean]

  const { reverseTrialStartDaysThreshold } = reverseTrialConfigs

  const { currentUserPreference, setPreference } =
    useReverseTrialLocalStorageHelper()
  const isReverseTrialFeatureActive =
    useAppClientConfig()?.[CLIENT_CONFIG_FEATURES.CLIENT_CONFIGURATION]
      ?.reverseTrial?.isActive

  const userCurrentPlan = activation?.plan

  const [isUserInTrialUnprotectedPage, setIsUserInTrialUnprotectedPage] =
    useState(false)

  useEffect(() => {
    setIsUserInTrialUnprotectedPage(
      location.pathname.startsWith(ROUTES.PLANS) ||
        location.pathname.startsWith(ROUTES.SIGN_OUT) ||
        location.pathname.startsWith(ROUTES.PROFILING) ||
        location.pathname.startsWith(ROUTES.PHONE_VERIFICATION) ||
        location.pathname.startsWith(ROUTES.WELCOME),
    )
  }, [location])

  const [shouldShowCountdownBanner, setShouldShowCountdownBanner] =
    useState(false)

  const isApplyReverseTrialFeatureEnabled = useCallback(
    (isTrue: boolean | null | undefined) => {
      if (!isTrue) return false
      return isReverseTrialFeatureActive && isTrue
    },
    [isReverseTrialFeatureActive],
  )

  // -------------------------------------------------------------
  // Processed Data ----------------------------------------------
  // -------------------------------------------------------------
  const isUserInTrialMode = useMemo(
    () => isApplyReverseTrialFeatureEnabled(activation?.isReverseTrial),
    [isApplyReverseTrialFeatureEnabled, activation?.isReverseTrial],
  )

  const isFreePlanUser = useMemo(
    () =>
      isApplyReverseTrialFeatureEnabled(
        userCurrentPlan === PLAN_NAMES.free ||
          userCurrentPlan === PLAN_NAMES.reverseTrial,
      ),
    [isApplyReverseTrialFeatureEnabled, userCurrentPlan],
  )

  // Compute shouldShowCountdownBanner every minute
  useEffect(() => {
    const expirationDate = activation?.expires
    if (!(expirationDate instanceof Date)) {
      return undefined
    }
    const checkIndicator = () => {
      const hoursLeft = calculateHoursLeftUntilDueDate(expirationDate)
      setShouldShowCountdownBanner(
        isApplyReverseTrialFeatureEnabled(
          isUserInTrialMode &&
            !currentUserPreference?.hasUserClosedCountdownTimer &&
            hoursLeft <=
              reverseTrialConfigs.banners.reverseTrialHoursLeftIndicator,
        ),
      )
    }
    checkIndicator()
    const intervalId = setInterval(checkIndicator, 1000 * 60) // every minute
    return () => clearInterval(intervalId)
  }, [
    isApplyReverseTrialFeatureEnabled,
    isUserInTrialMode,
    currentUserPreference?.hasUserClosedCountdownTimer,
    activation?.expires,
    reverseTrialConfigs?.banners?.reverseTrialHoursLeftIndicator,
  ])

  const shouldShowStartTrialScreen = useMemo(
    () =>
      reverseTrial?.daysAvailable
        ? isApplyReverseTrialFeatureEnabled(
            reverseTrial.daysAvailable > 0 &&
              !isUserInTrialMode &&
              !isUserInTrialUnprotectedPage &&
              isFreePlanUser,
          )
        : false,
    [
      isApplyReverseTrialFeatureEnabled,
      reverseTrial?.daysAvailable,
      isUserInTrialMode,
      isUserInTrialUnprotectedPage,
      isFreePlanUser,
    ],
  )

  const shouldShowEndOfTrialScreen = useMemo(
    () =>
      isApplyReverseTrialFeatureEnabled(
        uiState.showEndOfReverseTrialScreen && !isUserInTrialUnprotectedPage,
      ),
    [
      isApplyReverseTrialFeatureEnabled,
      uiState.showEndOfReverseTrialScreen,
      isUserInTrialUnprotectedPage,
    ],
  )

  const shouldShowSubscribeInPlansButton = useMemo(
    () => isApplyReverseTrialFeatureEnabled(isUserInTrialMode),
    [isApplyReverseTrialFeatureEnabled, isUserInTrialMode],
  )

  const shouldShowFreeTrialIndicator = useMemo(
    () => isApplyReverseTrialFeatureEnabled(isUserInTrialMode),
    [isApplyReverseTrialFeatureEnabled, isUserInTrialMode],
  )

  const shouldShowFreePlanIndicator = useMemo(() => {
    return isApplyReverseTrialFeatureEnabled(
      isFreePlanUser && !isUserInTrialMode,
    )
  }, [isApplyReverseTrialFeatureEnabled, isFreePlanUser, isUserInTrialMode])

  const getDaysLeftIndicatorData = useCallback(() => {
    const expirationDate = activation?.expires
    if (!(expirationDate instanceof Date)) {
      return { daysLeft: 0, shouldShowDays: false }
    }

    const daysLeft = calculateDaysLeftUntilDueDate(expirationDate)
    const shouldShowDays = isApplyReverseTrialFeatureEnabled(
      daysLeft <= reverseTrialConfigs.banners.reverseTrialDaysLeftIndicator &&
        daysLeft > 0,
    )
    return { daysLeft, shouldShowDays }
  }, [
    activation?.expires,
    isApplyReverseTrialFeatureEnabled,
    reverseTrialConfigs.banners.reverseTrialDaysLeftIndicator,
  ])

  const handleUserCloseCountdownBanner = useCallback(() => {
    setPreference(USER_PREFERENCE_FIELDS.countdown, true)
  }, [setPreference])

  // -------------------------------------------------------------
  // API functions -----------------------------------------------
  // -------------------------------------------------------------
  const handleStartReverseTrial = useCallback(async (callback?: () => void) => {
    await startReverseTrial()

    if (callback) {
      callback()
    }
  }, [])

  const disableEndOfReverseTrialScreen = useCallback(() => {
    hideEndOfReverseTrialScreen()
  }, [hideEndOfReverseTrialScreen])

  // -------------------------------------------------------------
  // Context-API internals ---------------------------------------
  // -------------------------------------------------------------
  const contextValue = useMemo(
    () => ({
      trialExpirationDate: activation?.expires,
      trialDaysAvailable: reverseTrial?.daysAvailable,
      trialDaysReceived: reverseTrial?.daysReceived,
      handleUserCloseCountdownBanner,
      shouldShowStartTrialScreen,
      shouldShowEndOfTrialScreen,
      shouldShowCountdownBanner,
      shouldShowSubscribeInPlansButton,
      shouldShowFreeTrialIndicator,
      shouldShowFreePlanIndicator,
      getDaysLeftIndicatorData,
      handleStartReverseTrial,
      disableEndOfReverseTrialScreen,
      isFreePlanUser,
      isUserInTrialMode,
      reverseTrialStartDaysThreshold,
      isRemoteConfigLoading,
    }),
    [
      activation?.expires,
      reverseTrial?.daysAvailable,
      reverseTrial?.daysReceived,
      handleUserCloseCountdownBanner,
      shouldShowStartTrialScreen,
      shouldShowEndOfTrialScreen,
      shouldShowCountdownBanner,
      shouldShowSubscribeInPlansButton,
      shouldShowFreeTrialIndicator,
      shouldShowFreePlanIndicator,
      getDaysLeftIndicatorData,
      handleStartReverseTrial,
      disableEndOfReverseTrialScreen,
      isFreePlanUser,
      isUserInTrialMode,
      reverseTrialStartDaysThreshold,
      isRemoteConfigLoading,
    ],
  )

  return (
    <ReverseTrialContext.Provider value={contextValue}>
      {children}
    </ReverseTrialContext.Provider>
  )
}

export const useReverseTrialContext = () => {
  const context = useContext(ReverseTrialContext)
  if (context === undefined) {
    throw new Error(
      'useReverseTrialContext must be used within a ReverseTrialProvider',
    )
  }
  return context
}

export { ReverseTrialProvider }
