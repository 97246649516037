import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AdditionalField,
  AdditionalFields,
  DeleteBigIcon,
  FieldTypes,
  FormField,
  FormFields,
  Icon,
  useDialog,
} from 'boards-web-ui'

import styles from '@features/checkout/components/CheckoutProducts.module.css'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'
import { newKey } from '@helpers/NodeIdGenerator'
import useConfirmDialog from '@hooks/useConfirmDialog'

import {
  ADDITIONAL_FIELDS_TYPE,
  DEFAULT_ADDITIONAL_FIELD,
  DEFAULT_ADDITIONAL_FIELDS,
  getDefaultFieldValuesByFieldType,
} from '../constants'
import SelectAdditionalFieldType from '../components/SelectAdditionalFieldType'

const useAdditionalFields = (
  defaultAdditionalFields: AdditionalFields = DEFAULT_ADDITIONAL_FIELDS,
  allFields: FormFields | undefined,
) => {
  const { t } = useTranslation()
  const { open, close } = useDialog()
  const confirmDialog = useConfirmDialog()
  const { appCheckoutQuestionAddedEvent, appCheckoutQuestionDeletedEvent } =
    useCheckoutAnalytics()

  const [additionalFields, setAdditionalFields] = useState<AdditionalFields>(
    defaultAdditionalFields.map((field) => {
      if (field.type !== FieldTypes.CHOICE) {
        return {
          ...field,
          value:
            field.value ||
            t(ADDITIONAL_FIELDS_TYPE[field.type]?.defaultValue || ''),
        }
      }

      const matchingChoiceField = allFields?.find(
        (regularField) => regularField.id === field.id,
      ) as FormField | undefined

      return {
        ...field,
        choices: matchingChoiceField?.choices,
        multiple: matchingChoiceField?.multiple,
        value:
          field.value ||
          t(ADDITIONAL_FIELDS_TYPE[field.type]?.defaultValue || ''),
      }
    }),
  )

  const isAdditionalFieldsValid = useMemo(() => {
    return !!additionalFields.length
  }, [additionalFields])

  const onAdditionalFieldsReOrder = (
    additionalFieldsInNewOrder: AdditionalFields,
  ) => setAdditionalFields(additionalFieldsInNewOrder)

  const onAdditionalFieldsAdd = (
    additionalField: AdditionalField = {
      ...DEFAULT_ADDITIONAL_FIELD,
      id: newKey(14),
    },
  ): Promise<AdditionalField | null> =>
    new Promise((resolve) => {
      open(
        <SelectAdditionalFieldType
          onSelect={({ type, title, defaultValue }) => {
            const newAdditionalField = {
              ...additionalField,
              title: t(title || ''),
              type,
              ...getDefaultFieldValuesByFieldType(type, t(defaultValue || '')),
            } as AdditionalField

            setAdditionalFields((_additionalFields) => [
              ..._additionalFields,
              newAdditionalField,
            ])

            appCheckoutQuestionAddedEvent(type)
            close()

            resolve(newAdditionalField)
          }}
        />,
        {
          onCloseClick: () => resolve(null),
        },
      )
    })

  const onAdditionalFieldsDelete = (additionalField: AdditionalField) => {
    confirmDialog({
      icon: (
        <Icon className={styles.DeleteFieldIcon}>
          <DeleteBigIcon />
        </Icon>
      ),
      title: t('forms_delete_field_dialog_title'),
      message: t('forms_delete_field_dialog_subtitle'),
      cancelText: t('action_cancel'),
      confirmText: t('action_delete'),
      confirmButtonType: 'warning',
      onConfirm: () => {
        setAdditionalFields((_additionalFields) =>
          _additionalFields.filter((_additionalField) => {
            if (_additionalField.id === additionalField.id) {
              appCheckoutQuestionDeletedEvent(_additionalField.type)
              return false
            }
            return true
          }),
        )
      },
    })
  }

  const onAdditionalFieldsChange = (additionalField: AdditionalField) => {
    if (!additionalField) {
      return
    }

    setAdditionalFields((_additionalFields) =>
      _additionalFields.map((_additionalField) =>
        _additionalField.id === additionalField.id
          ? {
              ..._additionalField,
              ...additionalField,
            }
          : _additionalField,
      ),
    )
  }

  return {
    additionalFields,
    isAdditionalFieldsValid,
    onAdditionalFieldsReOrder,
    onAdditionalFieldsAdd,
    onAdditionalFieldsDelete,
    onAdditionalFieldsChange,
  } as const
}

export default useAdditionalFields
