import { Board, UiUpdatesMessage, UpdatesMessage } from '../app/models'

export const mergeMessageWithBoard = ({
  message,
  board: { title, icon },
  unread,
}: {
  message: UpdatesMessage
  board: Board
  unread: boolean
}): UiUpdatesMessage => ({
  ...message,
  title,
  icon,
  unread,
})
