import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonIcon,
  ButtonNew,
  CloseIcon,
  Icon,
  ReverseTrialCheckIcon,
  theme,
  Tooltip,
  TooltipContent,
  useDialog,
} from 'boards-web-ui'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import useReverseTrialEvents from '@features/reverseTrial/hooks/useReverseTrialEvents'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import styles from './ReverseTrialEndDialog.module.css'

type ReverseTrialEndDialogRef = {
  setLoadingState: () => void
}

export const ReverseTrialEnd = () => {
  const { open } = useDialog()
  const dialogRef = useRef<ReverseTrialEndDialogRef | null>(null)

  useEffect(() => {
    open(<ReverseTrialEndDialog ref={dialogRef} />, {
      fullScreen: true,
      overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark70,
      onOverlayClick: () => {
        if (dialogRef.current) {
          dialogRef.current.setLoadingState()
        }
      },
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

const ReverseTrialEndDialog = forwardRef<ReverseTrialEndDialogRef>(
  (props, ref) => {
    const { openPaywall } = usePaywall()
    const { trialDaysReceived, disableEndOfReverseTrialScreen } =
      useReverseTrialContext()
    const {
      endTrialViewEvent,
      endTrialDismissClickEvent,
      endTrialUpgradeClickEvent,
    } = useReverseTrialEvents()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
      setLoadingState: handleDismiss,
    }))

    const bulletPoints = [
      {
        title: t('end_trial_point1'),
      },
      {
        title: t('end_trial_point2'),
      },
      {
        title: t('end_trial_point3'),
      },
      {
        title: t('end_trial_point4'),
      },
    ]

    useEffect(() => {
      endTrialViewEvent(trialDaysReceived || 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpgrade = () => {
      endTrialUpgradeClickEvent(trialDaysReceived || 0)
      openPaywall({ source: PaywallSourceEnum.TRIAL_ENDED_SCREEN })
    }

    const handleDismiss = () => {
      setLoading(true)
      endTrialDismissClickEvent(trialDaysReceived || 0)
      disableEndOfReverseTrialScreen()
    }

    return (
      <div className={styles.Root}>
        <div>
          <Tooltip
            className={styles.CloseButton}
            data={
              <TooltipContent>
                <Trans i18nKey={'action_close'} />
              </TooltipContent>
            }
          >
            <ButtonIcon
              round
              size={20}
              variant="default"
              onClick={handleDismiss}
            >
              <CloseIcon />
            </ButtonIcon>
          </Tooltip>
        </div>
        <div className={styles.HeaderCnt}>
          <h3 className={styles.Header}>
            {t('end_trial_title', { day: trialDaysReceived })}
          </h3>
          <h4 className={styles.HeaderDescription}>
            {t('end_trial_subtitle')}
          </h4>
        </div>

        <div className={styles.DetailsCnt}>
          <div className={styles.Bullets}>
            {bulletPoints.map((bullet) => {
              return (
                <div key={bullet.title} className={styles.Bullet}>
                  <Icon size="20px">
                    <ReverseTrialCheckIcon />
                  </Icon>
                  <h5 className={styles.BulletTitle}>{bullet.title}</h5>
                </div>
              )
            })}
          </div>

          <div className={styles.ActionCnt}>
            <div className={styles.ActionButton}>
              <ButtonNew
                fullWidth
                size={'medium'}
                onClick={handleUpgrade}
                isLoading={loading}
              >
                {t('end_trial_button')}
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default ReverseTrialEnd
