import { ContentPickersEnum } from '@features/pickers'
import { removeUndefinedKeysFromObject } from '@helpers/object'
import { getFolderId } from '@helpers/NodeIdGenerator'
import { AppState } from '../../app/models/appState'
import { Folder, Node, NodeType } from '../../app/models'
import { setPropertiesReducer } from '../general/genralRedusers'
import * as FolderManagement from '../FolderManagement'

// TODO https://heybliss.atlassian.net/browse/BLISS-10770
export const setFolderPathReducer = (
  state: AppState,
  {
    picker,
    boardId,
    folderId,
  }: { picker: ContentPickersEnum; boardId?: string; folderId?: string },
): AppState => {
  return {
    ...state,
    app: {
      ...state.app,
      pickersContentNavigation: {
        ...state.app.pickersContentNavigation,
        [picker]: {
          boardId,
          folderId,
        },
      },
    },
  }
}

export function setFoldersReducer(
  state: AppState,
  boardId: string,
  folders: { [id: string]: Folder },
): AppState {
  /**
   * This is a workaround to prevent flush metadata from button keys
   *
   * Problem: after update a button key, the first snapshot will come with
   * undefined metadata, this will keep previous metadata values
   */
  const foldersWithPreviousMetadataValues = Object.keys(folders).reduce(
    (acc, folderId) => {
      const prevFolder = state?.folders[boardId]
      const prevContent = prevFolder ? prevFolder[folderId]?.content : undefined
      const content = folders[folderId].content.map((node) => {
        if (node.type === NodeType.BUTTON) {
          const prevNode = prevContent?.find(({ id }) => id === node.id)

          if (prevNode) {
            return {
              ...prevNode,
              ...removeUndefinedKeysFromObject(node),
            }
          }
        }

        if (node.type === NodeType.FORM || node.type === NodeType.CHECKOUT) {
          const prevNode = prevContent?.find(({ id }) => id === node.id)

          if (prevNode) {
            const updatedNode = {
              ...prevNode,
              form: {
                ...prevNode.form,
                ...node.form,
              },
              integrationState: node.integrationState,
            }
            return updatedNode
          }
        }

        return node
      })

      return {
        ...acc,
        [folderId]: {
          ...folders[folderId],
          content,
        },
      }
    },
    {},
  )

  return {
    ...state,
    folders: {
      ...state.folders,
      [boardId]: {
        ...state.folders[boardId],
        ...foldersWithPreviousMetadataValues,
      },
    },
  }
}

export function addFolderReducer(
  state: AppState,
  boardId: string,
  fid: string,
  key: string,
  properties: { [key: string]: unknown },
): AppState {
  const nodeId = getFolderId(0, key)
  return setPropertiesReducer(state, boardId, fid, nodeId, properties)
}

export function addFakeFolderReducer(
  state: AppState,
  boardId: string,
  fid?: string,
  key?: string,
  properties?: { [key: string]: unknown },
  isPage?: boolean,
  picker?: ContentPickersEnum,
): AppState {
  if (!fid || !key || !properties) {
    return { ...state }
  }
  const nodeId = getFolderId(0, key)

  const node = {
    id: nodeId,
    type: isPage ? 'page' : 'folder',
    ...properties,
  }

  const folder = {
    id: nodeId,
    key,
    icon: properties.icon,
    isPage,
    title: '',
    content: [],
    ...properties,
  }

  let folders = FolderManagement.addNodes({
    folders: state.folders,
    boardId,
    fid,
    nodes: [
      // TODO fix this type casting
      node as unknown as Node,
    ],
  })

  folders = FolderManagement.addFolder(
    folders,
    boardId,
    // TODO fix this type casting
    folder as unknown as Folder,
  )

  // When creating a folder from the destination picker
  // it should be opened in the destination picker right after creation
  const pickerStatePath = picker
    ? setFolderPathReducer(state, { picker, boardId, folderId: folder.id })
    : {}

  return {
    ...state,
    ...pickerStatePath,
    folders,
  }
}

export function deleteFolderReducer(
  state: AppState,
  action: { board: string; fid: string },
): AppState {
  const { board, fid } = action

  const boardFolders = state.folders[board]
  delete boardFolders[fid]

  return {
    ...state,
    folders: {
      ...state.folders,
      [board]: boardFolders,
    },
  }
}

export function setFolderShortcutReducer(
  state: AppState,
  shortcut: string,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      sharedFolder: {
        ...state.app.sharedFolder,
        folderPath: [...state.app.sharedFolder.folderPath, shortcut],
      },
    },
  }
}

export function deleteFolderShortcutReducer(
  state: AppState,
  shortcut?: string | Array<string>,
): AppState {
  let updatedFolderPath: undefined | string[]

  if (shortcut) {
    if (Array.isArray(shortcut)) {
      updatedFolderPath = []
    } else {
      updatedFolderPath = state.app.sharedFolder.folderPath.filter(
        (item) => item !== shortcut,
      )
    }
  } else {
    updatedFolderPath = state.app.sharedFolder.folderPath.slice(0, -1)
  }

  return {
    ...state,
    app: {
      ...state.app,
      sharedFolder: {
        ...state.app.sharedFolder,
        folderPath: updatedFolderPath,
      },
    },
  }
}

export function setSharedFolderReducer(
  state: AppState,
  folder?: Folder,
): AppState {
  if (!folder) {
    return {
      ...state,
      app: {
        ...state.app,
        sharedFolder: {
          ...state.app.sharedFolder,
          folders: [],
        },
      },
    }
  }

  const isDuplicate = state.app.sharedFolder.folders.some(
    (item) => item.id === folder.id,
  )

  if (!isDuplicate) {
    const newArray = [...state.app.sharedFolder.folders, folder]
    return {
      ...state,
      app: {
        ...state.app,
        sharedFolder: {
          ...state.app.sharedFolder,
          folders: newArray,
        },
      },
    }
  }

  return state
}

export function updateSharedFolderDataReducer(
  state: AppState,
  shortcutId: string,
  boardId: string,
  folderId?: string,
  copyBoardAllowed?: boolean,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      sharedFolder: {
        ...state.app.sharedFolder,
        shortcutId,
        boardId,
        folderId,
        copyBoardAllowed,
      },
    },
  }
}
