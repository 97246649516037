import { DocumentSnapshot } from 'firebase/firestore'
import { UserAcademyType } from '@models/UserAcademy'
import { AppDispatch } from '../../../app/store'
import { contentActions } from '../../../actions'

export const transformAcademy = (doc: DocumentSnapshot): UserAcademyType => {
  const data = doc.data() || {}
  return { progress: data }
}

export const UserAcademySnapshot =
  (dispatch: AppDispatch) => (doc: DocumentSnapshot) => {
    const academy = transformAcademy(doc)
    dispatch(contentActions.setUserAcademy(academy))
  }
