import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AnimatedDialog, theme, useDialog } from 'boards-web-ui'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import DoneButton from '@ui/components/DoneButton'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '@hooks/useRouterParams'
import useVideoStreamingFileUpload from '@features/nodeEditor/components/VideoStreaming/useVideoStreamingFileUpload'
import useSaveVideoStreamingNode from '@features/nodeEditor/components/VideoStreaming/useSaveVideoStreamingNode'
import UploadProgressBar from '@features/nodeEditor/components/UploadProgressBar'

import { getCurrentFolderId } from '../../../../../selectors'
import { Node, NodeType } from '../../../../models'
import { videoActions } from '../../../../../actions'
import ModalEditWithConfirmation from '../../../../../components/ModalEditWithConfirmation'
import VideoStreaming from '../VideoStreaming'
import InputModalTitle from '../../../../../elements/InputModalTitle'

import useNodeAPI from '../../hooks/useNodeAPI'
import useNodeDiscardChanges from '../../hooks/useNodeDiscardChanges'
import useAddVideoStreamingDialogs from '../../hooks/useAddVideoStreamingDialogs'

import {
  ICreateOrUpdateNode,
  INodeProps,
} from '../../models/IUseNodeCreatorAPI'

import styles from './AddVideoStreaming.module.css'

interface SharedProps {
  isPageEditor?: boolean
  onSave: ICreateOrUpdateNode
  onUpdate: (id: string, node: INodeProps) => void
}
type FileOrNodeProps =
  | { file: File; initialNode?: never }
  | { initialNode: Node; file?: never }

type Props = FileOrNodeProps & SharedProps

const AddVideoStreamingFile = ({
  file,
  initialNode,
  isPageEditor,
  onSave,
  onUpdate,
}: Props) => {
  const { close } = useDialog()
  const dispatch = useDispatch()
  const discardChanges = useNodeDiscardChanges()
  const { cancelUploadDialog } = useAddVideoStreamingDialogs()
  const { t } = useTranslation()
  const { boardId } = useRouterParams()
  const [node, setNode] = useState<Node | undefined>(initialNode)
  const { title, setTitle } = useNodeAPI('', '')
  const currentFolderId = useSelectorFactory(getCurrentFolderId)

  const saveNodeOnDatabase = useSaveVideoStreamingNode({
    title,
    setNode,
    onSave,
  })
  const {
    error,
    progress,
    isUploading,
    handleFilePicked,
    retryUpload,
    cancelUpload,
  } = useVideoStreamingFileUpload({
    onUploaded: saveNodeOnDatabase,
  })

  useEffect(() => {
    if (!file) return

    handleFilePicked(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const handleSave = () => {
    if (!node) return

    onUpdate(node?.id, {
      title,
      text: node?.text,
      type: NodeType.VIDEO_STREAMING,
    })
    dispatch(videoActions.setVideoStreamingId(''))
    dispatch(videoActions.setVideoStreamingUrl(''))
    close()
  }

  const handleClose = () => {
    if (isUploading) {
      cancelUploadDialog(() => {
        cancelUpload()
        close()
      })
      return
    }

    discardChanges()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        onEscape: handleClose,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave} disabled={isUploading}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <div className={clsx(styles.Root, !isPageEditor && styles.WithTitle)}>
            {!isPageEditor && (
              <InputModalTitle
                value={title}
                placeholder={t('add_an_update_title_optional')}
                onChange={setTitle}
              />
            )}
            {isUploading ? (
              <div className={styles.UploadProgressBarWrapper}>
                <UploadProgressBar
                  progress={progress}
                  isError={error}
                  retry={retryUpload}
                />
              </div>
            ) : (
              node && (
                <VideoStreaming
                  boardId={boardId}
                  folderId={currentFolderId}
                  nodeId={node.id}
                  showDescriptionLabel
                />
              )
            )}
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default AddVideoStreamingFile
