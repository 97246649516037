import React, { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import clsx from 'clsx'
import {
  ButtonIcon,
  DragIcon,
  Tooltip,
  TooltipContent,
  TrashIcon,
} from 'boards-web-ui'
import type { Identifier, XYCoord } from 'dnd-core'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'

import { IconWrapper } from '@ui/components/IconWrapper'

import styles from './ListItem.module.css'

const style = {
  cursor: 'move',
}

const ItemTypes = {
  Node: 'node',
}

interface Props {
  id: string
  index: number
  disabled: boolean
  withIndicator?: boolean
  title: string
  move: (dragIndex: number, hoverIndex: number) => void
  end: (id: string, dragIndex: number) => void
  onClick: () => void
  onDelete: () => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

const ListItem: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  index,
  withIndicator,
  disabled,
  title,
  move,
  end,
  onClick,
  onDelete,
  children,
}) => {
  const [borderRadiusFix, setBorderRadiusFix] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.Node,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      move(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex
    },
  })

  const [{ opacity, isDragging }, preview] = useDrag({
    type: ItemTypes.Node,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
      isDragging: !!monitor.getItem(),
    }),
    end: (item) => {
      if (!item) {
        return
      }
      end(item.id, item.index)
    },
    canDrag: borderRadiusFix,
  })

  preview(drop(ref))

  if (disabled) {
    return <div>{children}</div>
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.Root,
        isDragging && styles.IsDragging,
        withIndicator && styles.WithIndicator,
      )}
      style={{
        opacity,
        transform: borderRadiusFix ? 'translate(0, 0)' : '',
        overflow: borderRadiusFix ? 'hidden' : '',
      }}
      data-handler-id={handlerId}
    >
      <button
        className={styles.DragIcon}
        style={{ ...style }}
        aria-label="Drag"
        onMouseEnter={() => setBorderRadiusFix(true)}
        onMouseLeave={() => setBorderRadiusFix(false)}
      >
        <DragIcon />
      </button>

      <button
        className={styles.Item}
        onClick={onClick}
        onMouseEnter={() => setBorderRadiusFix(true)}
        onMouseLeave={() => setBorderRadiusFix(false)}
      >
        <div className={styles.Title}>{title}</div>
        <div className={styles.Body}>{children}</div>
      </button>

      <div className={styles.Delete}>
        <Tooltip
          data={
            <TooltipContent>
              <Trans i18nKey="action_delete" />
            </TooltipContent>
          }
        >
          <ButtonIcon
            variant="default"
            size={38}
            round
            onClick={onDelete}
            className={styles.DeleteButtonIcon}
          >
            <IconWrapper color="primary" className={styles.DeleteIcon}>
              <TrashIcon />
            </IconWrapper>
          </ButtonIcon>
        </Tooltip>
      </div>
    </div>
  )
}

export default ListItem
