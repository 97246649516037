import { ReactNode } from 'react'
import styles from './BoardInfo.module.css'

interface Props {
  icon?: ReactNode
  title?: string
  name: string
}

export const BoardInfo = ({ icon, title, name }: Props) => (
  <div className={styles.Root}>
    <div className={styles.Icon}>{icon}</div>

    <div className={styles.Meta}>
      <span className={styles.Title}>{title}</span>
      <span className={styles.Name}>{name}</span>
    </div>
  </div>
)
