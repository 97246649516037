/* eslint no-console: ["error", { allow: ["warn"] }] */
import { QuerySnapshot } from 'firebase/firestore'

import {
  transformAcademyDoc,
  transformClientConfigurationDoc,
  transformEmailAddressDoc,
} from '_firebase/listeners/transformers/ClientConfigTransformer'
import { CLIENT_CONFIG_FEATURES } from 'app/models/clientConfig'
import { AppDispatch } from '../../../app/store'
import { clientConfigActions } from '../../../actions'

export const ClientConfigSnapshot =
  (dispatch: AppDispatch) => (snapshot: QuerySnapshot) => {
    snapshot.docChanges().forEach(({ doc, type }) => {
      if (type === 'added' || type === 'modified') {
        switch (doc.id) {
          case 'email-address':
            dispatch(
              clientConfigActions.setClientConfig(
                transformEmailAddressDoc(doc),
                CLIENT_CONFIG_FEATURES.EMAIL_ADDRESS,
              ),
            )
            break

          case 'client-configuration':
            dispatch(
              clientConfigActions.setClientConfig(
                transformClientConfigurationDoc(doc),
                CLIENT_CONFIG_FEATURES.CLIENT_CONFIGURATION,
              ),
            )
            break

          case 'academy':
            dispatch(
              clientConfigActions.setClientConfig(
                transformAcademyDoc(doc),
                CLIENT_CONFIG_FEATURES.ACADEMY,
              ),
            )
            break
          default:
            console.warn(`Unhandled feature client config: ${doc.id}`)
        }
      }
    })
  }
