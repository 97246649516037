import { useCallback, useEffect } from 'react'

import { useIsAcademyEnabled } from '@features/academy'
import useLocalStorage from '@hooks/useLocalStorage'
import { useAcademyBannerEvents } from '@features/sidebar/hooks/useAcademyBannerEvents'
import { useAuth } from '@hooks/useAuth'

const ACADEMY_BANNER_LOCAL_STORAGE_KEY = 'isAcademyBannerDismissed'

export const useAcademyBanner = () => {
  const { user } = useAuth()
  const userId = user?.uid

  const { isAcademyEnabled } = useIsAcademyEnabled()
  const { dismissBannerEvent, clickBannerEvent, showBannerEvent } =
    useAcademyBannerEvents()
  const [isAcademyBannerDismissed, setIsAcademyBannerDismissed] =
    useLocalStorage<boolean>(
      `${userId}-${ACADEMY_BANNER_LOCAL_STORAGE_KEY}`,
      false,
    )

  const shouldShowAcademyBanner = isAcademyEnabled && !isAcademyBannerDismissed

  useEffect(() => {
    if (!shouldShowAcademyBanner) {
      return
    }

    showBannerEvent()
  }, [shouldShowAcademyBanner, showBannerEvent])

  const handleCloseAcademyBanner = useCallback(() => {
    dismissBannerEvent()
    setIsAcademyBannerDismissed(true)
  }, [setIsAcademyBannerDismissed, dismissBannerEvent])

  const handleOpenAcademyPage = useCallback(() => {
    clickBannerEvent()
  }, [clickBannerEvent])

  return {
    shouldShowAcademyBanner,
    onCloseBanner: handleCloseAcademyBanner,
    onOpenAcademyPage: handleOpenAcademyPage,
  }
}
