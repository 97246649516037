import { PropsWithChildren } from 'react'
import ExplanationMessage from './ExplanationMessage'
import InputFieldWithEmojiPicker from './InputFieldWithEmojiPicker'

import styles from './FormHeaderWithTitleAndMessage.module.css'

type Props = PropsWithChildren<{
  title: string
  inputLabel: string
  inputPlaceholder: string
  explanationText: string
  autoFocus?: boolean
  onTitleChange: (t: string) => void
}>
const FormHeaderWithTitleAndMessage = ({
  title,
  autoFocus,
  inputLabel,
  inputPlaceholder,
  explanationText,
  children,
  onTitleChange,
}: Props) => (
  <div className={styles.Root}>
    <InputFieldWithEmojiPicker
      label={inputLabel}
      placeholder={inputPlaceholder}
      value={title}
      onChange={onTitleChange}
      autoFocus={autoFocus}
      withResetButton
    />
    {children}
    <ExplanationMessage message={explanationText} />
  </div>
)

export default FormHeaderWithTitleAndMessage
