import {
  CLIENT_CONFIG_FEATURES,
  GeneralClientConfigType,
} from 'app/models/clientConfig'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  SET_CLIENT_CONFIG = 'SET_CLIENT_CONFIG',
}

const setClientConfig = (
  data: GeneralClientConfigType,
  clientFeature: CLIENT_CONFIG_FEATURES,
) => ({
  type: ACTION_TYPE.SET_CLIENT_CONFIG,
  data,
  clientFeature,
})

const actions = {
  setClientConfig,
} as const

export type TClientConfigActions = ActionCreator<
  typeof setClientConfig,
  ACTION_TYPE.SET_CLIENT_CONFIG
>

export default actions
