import React, { useEffect } from 'react'
import useFirebaseListeners from '../../../../../_firebase/hooks/useFirebaseListeners'
import SignInForm from '../../components/SignInForm'

const PageSignIn: React.FC = () => {
  const { startClientConfigListener } = useFirebaseListeners()

  useEffect(() => {
    startClientConfigListener()
  }, [startClientConfigListener])

  return <SignInForm />
}

export default PageSignIn
