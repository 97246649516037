import React, { useEffect, useRef, useState } from 'react'
import { Icon, Overlay, SpinnerIcon } from 'boards-web-ui'
import SharedBoardModalHeader from '@features/editor/components/SharedBoardModalHeader'
import Board from '@features/board/Board'
import useShortcutLoader from '@features/editor/hooks/useShortcutLoader'
import useFolderEvents from '@features/analytics/useFolderEvents'
import { useSharedFolder } from '@hooks/useSharedFolder'
import useBulk from '@hooks/bulk/useBulk'
import { SharedBoardModalFooterSelection } from '@features/editor/components/SharedBoardModalFooterSelection'
import { useTranslation } from 'react-i18next'
import { DestinationPickerSourceEventEnum } from '@features/analytics/useDestinationPickerEvents'
import clsx from 'clsx'
import { useCopyReceivedContent } from '@hooks/useCopyReceivedContent'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import SharedBoardModalFooter from '@features/editor/components/SharedBoardModalFooter'
import { SharedBoardModalFooterSave } from '@features/editor/components/SharedBoardModalFooterSave'
import { useDispatch } from 'react-redux'
import Modal from '../../../../components/Modal'
import styles from './SharedBoardModal.module.css'
import { folderActions } from '../../../../actions'

const SharedBoardModal = ({
  shortcutId,
  boardId,
  folderId,
}: {
  shortcutId: string
  boardId: string
  folderId?: string
}) => {
  const dispatch = useDispatch()

  const [webFolderEventCount, setWebFolderEventCount] = useState(0)

  const { viewWebFolderEvent } = useFolderEvents()

  const { loading, data, folder } = useShortcutLoader()
  const { bulk, resetBulkActions } = useBulk()
  const { t } = useTranslation()
  const {
    sharedFolderState: { boardId: joinedBoardId, folders },
  } = useSharedFolder()

  const titleTextRef = useRef(null)

  const doRenderComponent = boardId && shortcutId && folderId

  useEffect(() => {
    if (data?.board && webFolderEventCount < 1) {
      setWebFolderEventCount(webFolderEventCount + 1)
      viewWebFolderEvent(
        !!data.canJoinBoardWithKey,
        data.board.options ? data.board.options?.copyAllowed : false,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { canCopy, onCopyClick } = useCopyReceivedContent({
    isSharedFolderMode: true,
  })

  if (!doRenderComponent) {
    return null
  }

  if ((loading || !folder) && folders.length === 0) {
    return (
      <Overlay
        index={1}
        onClick={() => null}
        color="#00000080"
        position="fixed"
      >
        <div className={styles.FolderLoadingSpinner}>
          <Icon size="38px">
            <SpinnerIcon />
          </Icon>
        </div>
      </Overlay>
    )
  }

  const canBeShared = Boolean(data?.canJoinBoardWithKey)
  const canBeCopied = Boolean(data?.copyBoardAllowed) && !canBeShared && data

  const handleCloseModal = () => {
    dispatch(folderActions.deleteFolderShortcut([]))

    dispatch(
      folderActions.updateSharedFolderData({
        shortcutId: '',
        boardId: '',
        folderId: '',
      }),
    )

    dispatch(folderActions.setSharedFolder())

    resetBulkActions()
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.FolderLoadingSpinner}>
          <Icon size="38px">
            <SpinnerIcon />
          </Icon>
        </div>
      )
    }

    if (folder) {
      return (
        <div
          className={clsx(
            folder?.content.length !== 0
              ? styles.BoardContainer
              : styles.EmptyBoardContainer,

            folder?.content.length === 0 &&
              data?.canJoinBoardWithKey &&
              styles.EmptyBoardContainerJoin,

            folder?.content.length !== 0 &&
              data?.canJoinBoardWithKey &&
              styles.BoardContainerWithFooter,
          )}
        >
          <Board
            boardId={joinedBoardId}
            sharedBoardData={data?.board}
            folder={folder}
            isFolderEmpty={folder?.content.length === 0}
            isRoot={false}
            isSharedFolderMode
            canBeShared={canBeShared}
          />
        </div>
      )
    }
    return null
  }

  return (
    <Modal
      Header={
        <SharedBoardModalHeader
          loading={loading}
          titleTextRef={titleTextRef}
          onClose={handleCloseModal}
        />
      }
      onClickOutside={undefined}
      open
      variant={'veryLarge'}
      className={styles.Modal}
    >
      <div
        className={`
            ${folder?.content.length === 0 && styles.LayoutContainerSmall}
            `}
      >
        <h1 ref={titleTextRef} className={[styles.FolderTitle].join(' ')}>
          {!loading && (
            <>
              <span>{folder?.icon}</span>
              &nbsp;
              <span>{folder?.title}</span>
            </>
          )}
        </h1>
        {renderContent()}
      </div>

      {bulk.nodes.length ? (
        <SharedBoardModalFooterSelection
          selectedNodesCount={bulk.nodes.length}
          onCancel={resetBulkActions}
          isRestricted={!canCopy}
          onConfirm={() => {
            onCopyClick({
              source: PaywallSourceEnum.COPY_SHARED_FOLDER,
              pickerProps: {
                contentType: 'selection',
                performActionCopy: t('action_save_here'),
                headerCopy: t('label_save_to'),
                performActionSuccessMessage: t('toast_folder_saved'),
                eventsOverrides: {
                  source: DestinationPickerSourceEventEnum.WebFolder,
                },
              },
            })
          }}
        />
      ) : (
        <>
          {data?.canJoinBoardWithKey && data.board && data.rootFolderProps ? (
            <SharedBoardModalFooter
              joinKey={data.canJoinBoardWithKey}
              boardId={joinedBoardId}
              icon={data.rootFolderProps.icon}
              title={data.rootFolderProps.title}
              notCountedTowardsLimit={data.board.options.notCountedTowardsLimit}
              joinFolderId={data.folders[0].folder.id}
            />
          ) : null}

          {canBeCopied ? (
            <SharedBoardModalFooterSave
              joinedBoardId={joinedBoardId}
              ownerId={data?.ownerId}
              onCopyDone={handleCloseModal}
            />
          ) : null}
        </>
      )}
    </Modal>
  )
}

export default SharedBoardModal
