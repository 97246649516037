/* eslint-disable no-alert */
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { OnPasswordCallback, PasswordResponse } from 'react-pdf/dist/cjs/shared/types'
import { Folder, Node } from '../../../models'
import { Document, Page, PasswordResponses } from '../helpers/reactPdf'
import { getPdfBucketUrl } from '../helpers/pdf'
import { ReactComponent as SpinnerSmallIcon } from '../../../icons/Spinner-36px.svg'
import ZoomButtons from './ZoomButtons'
import useZoom from '../hooks/useZoom'
import PreviewModal from './PreviewModal'
import useNodeClipboard from '../../nodeEditor/hooks/useNodeClipboard'

import styles from './PreviewPdf.module.css'

interface Props {
  node: Node
  close: () => void
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  isDisabledPreviewNavigation?: boolean
  isReadOnly?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

type DocType = { numPages: number }
const RETRY_LIMIT = 5

const PreviewPdf = ({
  node,
  close,
  next,
  prev,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  isDisabledPreviewNavigation,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation()
  const { zoom, increase, decrease, reset } = useZoom()
  const { copyToClipboard } = useNodeClipboard()

  const [numPages, setNumPages] = useState<number | null>(null)
  const file = getPdfBucketUrl(node.text)
  const [docKey, setDocKey] = useState(0)

  const isPolling = docKey < RETRY_LIMIT

  const onDocumentLoadSuccess = (doc: DocType) => {
    setNumPages(doc.numPages)
    setDocKey(0)
  }

  const onHandleCallback = (
    callback: OnPasswordCallback,
    password: string | null,
  ) => {
    if (password === null) {
      return close()
    }

    return callback(password)
  }

  const onPassword = (
    callback: OnPasswordCallback,
    reason: PasswordResponse,
  ) => {
    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt(t('preview_pdf_promp_password'))
        onHandleCallback(callback, password)
        break
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt(t('preview_pdf_promp_invalid_password'))
        onHandleCallback(callback, password)
        break
      }

      // no default
    }
  }

  const onLoadError = () => {
    if (isPolling) {
      setTimeout(() => {
        setDocKey((prevState) => prevState + 1)
      }, 1000)
      return
    }

    if (file) {
      window.open(file, '_blank', 'noopener,noreferrer')
      close()
    }
  }

  return (
    <PreviewModal
      centered={
        <ZoomButtons increase={increase} decrease={decrease} reset={reset} />
      }
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyToClipboard(node)}
      next={next}
      prev={prev}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      isDisabledPreviewNavigation={isDisabledPreviewNavigation}
      isReadOnly={isReadOnly}
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <Document
          key={docKey}
          className={styles.Doc}
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          externalLinkTarget="_blank"
          onPassword={onPassword}
          onLoadError={onLoadError}
          error={
            isPolling ? (
              <SpinnerSmallIcon />
            ) : (
              <div className={styles.Message}>
                <Trans i18nKey={'preview_pdf_error'} />
              </div>
            )
          }
          loading={<SpinnerSmallIcon />}
          noData={
            <div className={styles.Message}>
              <Trans i18nKey={'preview_pdf_no_data'} />
            </div>
          }
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page
              scale={zoom}
              width={800}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>
    </PreviewModal>
  )
}

export default PreviewPdf
