import React, { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { ReverseTrialProvider } from '@features/reverseTrial/ReverseTrialContext'
import ReverseTrialBlocker from '@features/reverseTrial/components/reverseTrialStart/ReverseTrialBlocker'
import { useAuth } from '@hooks/useAuth'

import useGeoLocation from '@hooks/useGeoLocation'
import useCioLinks from '@hooks/useCioLinks'
import PrivateRoutes from './private/Routes'
import PublicRoutes from './public/Routes'
import { EDITOR, PLANS, QUERY_PARAMS } from '../constants/routes'

type Props = {
  identifiedUser?: boolean
}
const AppRouting: React.FC<Props> = ({ identifiedUser }) => {
  useCioLinks()
  const { pathname } = useLocation()
  const { isLoggedIn, loaded } = useAuth()
  const { geoData, loadGeoData } = useGeoLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const campaignId = searchParams.get(QUERY_PARAMS.campaign)

  useEffect(() => {
    if (campaignId && pathname === EDITOR) {
      navigate(`${PLANS}?${QUERY_PARAMS.campaign}=${campaignId}`)
    }
  }, [campaignId, pathname, navigate])

  useEffect(() => {
    if (!geoData) {
      loadGeoData()
    }
  }, [geoData, loadGeoData])

  if (!loaded) {
    return null
  }

  if (!isLoggedIn) {
    return <PublicRoutes />
  }

  return identifiedUser ? (
    <ReverseTrialProvider>
      <ReverseTrialBlocker />
      <PrivateRoutes />
    </ReverseTrialProvider>
  ) : null
}

export default AppRouting
