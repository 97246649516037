import { theme, useDialog } from 'boards-web-ui'
import { ReactNode, useCallback } from 'react'
import { ContentPickersEnum } from '@features/pickers'
import { DestinationPickerModal } from '@features/pickers/destinationPicker/DestinationPickerModal'
import { DestinationPickerSourceEventEnum } from '@features/analytics/useDestinationPickerEvents'

export interface IOpenDestinationPickerParams {
  picker: ContentPickersEnum
  contentType: string
  headerCopy: ReactNode
  performActionCopy: ReactNode
  performActionSuccessMessage: string
  eventsOverrides?: {
    source: DestinationPickerSourceEventEnum
  }
}

interface IUseDestinationPickerCallbacks {
  openDestinationPicker: (params: IOpenDestinationPickerParams) => void
}

export interface IUseDestinationPickerParams {
  onClose?: (picker: ContentPickersEnum) => void
}

type TUseDestinationPicker = (
  params?: IUseDestinationPickerParams,
) => IUseDestinationPickerCallbacks

export const useDestinationPicker: TUseDestinationPicker = (params) => {
  const { open, close } = useDialog()

  const handleClose = useCallback(
    (picker: ContentPickersEnum) => {
      close()

      if (params?.onClose) {
        params.onClose(picker)
      }
    },
    [close, params],
  )

  const openDestinationPicker: IUseDestinationPickerCallbacks['openDestinationPicker'] =
    ({
      picker,
      contentType,
      performActionCopy,
      headerCopy,
      performActionSuccessMessage,
      eventsOverrides,
    }) => {
      open(
        <DestinationPickerModal
          picker={picker}
          contentType={contentType}
          onClose={handleClose}
          performActionCopy={performActionCopy}
          headerCopy={headerCopy}
          performActionSuccessMessage={performActionSuccessMessage}
          eventsOverrides={eventsOverrides}
        />,
        {
          fullScreen: true,
          closeOnOverlayClick: true,
          overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
          withFullScreenDialogContainer: false,
        },
      )
    }

  return {
    openDestinationPicker,
  }
}
