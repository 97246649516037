import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'boards-web-ui'
import { LoadFoldersWithShortcutsResponse } from '../../../_firebase/models/shortcutDataResponse'
import { getFoldersWithShortcuts } from '../../../_firebase'
import { QUERY_PARAMS } from '../../constants/routes'

import styles from './UniversalLinksBoardsController.module.css'

export const UniversalLinksBoardsController = () => {
  const { shortcutId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  useEffect(() => {
    if (shortcutId) {
      getFoldersWithShortcuts(shortcutId)
        .then((res: LoadFoldersWithShortcutsResponse | undefined) => {
          if (res) {
            const boardId = res.board?.id
            const folderId = res.folders[0]?.folder?.id

            const copyAllowedParam =
              res.copyBoardAllowed && !res.canJoinBoardWithKey
                ? `&${QUERY_PARAMS.copyBoardAllowed}=true`
                : ''

            const redirectTo =
              `/?${QUERY_PARAMS.shortcutId}=${shortcutId}&` +
              `${QUERY_PARAMS.boardId}=${res.ownerId}-${boardId}&` +
              `${QUERY_PARAMS.folderId}=${folderId}` +
              `${copyAllowedParam}`

            navigate(redirectTo)
          }
        })
        .catch((e) => {
          setError(e.toString())
        })
    }
  }, [shortcutId, navigate])

  return (
    <Spinner size="large" spinning={!error}>
      <div className={styles.ErrorMessage}>
        {error && <span>{t('optimistic_ui_failed')}</span>}
      </div>
    </Spinner>
  )
}
