import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteBigIcon, FormField, Icon, useDialog } from 'boards-web-ui'

import useConfirmDialog from '@hooks/useConfirmDialog'
import { newKey } from '@helpers/NodeIdGenerator'
import useFormsEvents from '@features/analytics/useFormsEvents'
import SelectAdditionalFieldType from '@features/checkout/components/SelectAdditionalFieldType'
import styles from '@features/checkout/components/CheckoutProducts.module.css'

import {
  ADDITIONAL_FIELDS_TYPE,
  DEFAULT_NEW_FIELD,
  DEFAULT_ADDITIONAL_FIELDS,
  getDefaultFieldValuesByFieldType,
} from '../constants'

type Props = {
  defaultFields?: FormField[]
}
const useAdditionalFields = ({
  defaultFields = DEFAULT_ADDITIONAL_FIELDS,
}: Props) => {
  const { t } = useTranslation()
  const { open, close } = useDialog()
  const confirmDialog = useConfirmDialog()

  const { questionAddedEvent, questionDeletedEvent } = useFormsEvents()

  const [formsFields, setFormsFields] = useState<FormField[]>(
    defaultFields?.map((field) => ({
      ...field,
      label: field.label
        ? t(field.label)
        : t(ADDITIONAL_FIELDS_TYPE[field.type]?.defaultValue),
    })),
  )

  const isFormFieldsValid = useMemo(() => {
    return !!formsFields.length
  }, [formsFields])

  const onAddFormsField = useCallback(
    (
      newField = {
        ...DEFAULT_NEW_FIELD,
        id: newKey(14),
      },
    ): Promise<FormField | null> => {
      return new Promise((resolve) => {
        open(
          <SelectAdditionalFieldType
            onSelect={({ type, defaultValue }) => {
              const newFormField = {
                ...newField,
                type,
                ...getDefaultFieldValuesByFieldType(
                  type,
                  t(defaultValue || ''),
                ),
              } as FormField

              setFormsFields((prevFields) => [...prevFields, newFormField])
              close()
              questionAddedEvent(type)
              resolve(newFormField)
            }}
          />,
          {
            onCloseClick: () => resolve(null),
          },
        )
      })
    },
    [setFormsFields, questionAddedEvent, close, open, t],
  )

  const onDeleteFormsField = useCallback(
    (deleteField: FormField) => {
      confirmDialog({
        icon: (
          <Icon className={styles.DeleteFieldIcon}>
            <DeleteBigIcon />
          </Icon>
        ),
        title: t('forms_delete_field_dialog_title'),
        message: t('forms_delete_field_dialog_subtitle'),
        cancelText: t('action_cancel'),
        confirmText: t('action_delete'),
        confirmButtonType: 'warning',
        onConfirm: () => {
          setFormsFields((prevFields) =>
            prevFields.filter((f) => f.id !== deleteField.id),
          )
          questionDeletedEvent(deleteField.type)
        },
      })
    },
    [setFormsFields, questionDeletedEvent, confirmDialog, t],
  )

  const onUpdateFormsField = useCallback(
    (updatedField: Partial<FormField>) => {
      setFormsFields((prevFields) =>
        prevFields.map((f) =>
          f.id === updatedField.id
            ? ({ ...f, ...updatedField } as FormField)
            : f,
        ),
      )
    },
    [setFormsFields],
  )

  const onFormFieldsReOrder = useCallback(
    (orderedFormFields: Array<FormField>) => {
      setFormsFields(orderedFormFields)
    },
    [setFormsFields],
  )

  return {
    formsFields,
    isFormFieldsValid,
    onAddFormsField,
    onDeleteFormsField,
    onUpdateFormsField,
    onFormFieldsReOrder,
  } as const
}

export default useAdditionalFields
