import { AppState } from '../../app/models/appState'
import {
  CLIENT_CONFIG_FEATURES,
  GeneralClientConfigType,
} from '../../app/models/clientConfig'

export function setClientConfigReducer(
  state: AppState,
  data: GeneralClientConfigType,
  feature: CLIENT_CONFIG_FEATURES,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      clientConfig: {
        ...state.app.clientConfig,
        [feature]: data,
      },
    },
  }
}
