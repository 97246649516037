import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { filterFirstError } from '@helpers/formErrors'
import {
  ButtonNew,
  emailHelper,
  InputCheckbox,
  InputLabel,
} from 'boards-web-ui'
import { useAppClientConfig } from '@hooks/useAppClientConfig'
import GuestInputWrapper from './GuestInputWrapper'
import GuestTitle from './GuestTitle'
import Link from '../../../../elements/Link'
import InputField from '../../../../elements/InputField'
import * as ROUTES from '../../../../constants/routes'
import styles from './SignInForm.module.css'
import { useSignIn } from '../hooks/useSignIn'
import GuestFooter from './GuestFooter'
import Layout from '../../layout/Layout'

const SignInForm = () => {
  const { t } = useTranslation()
  const clientConfig = useAppClientConfig()

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    trigger,
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      remember: true,
      email: '',
      password: '',
    },
  })

  const rememberFieldValue = useWatch({
    control,
    name: 'remember',
  })

  const { submitting, onSignIn } = useSignIn(setError)
  const [searchParams, setSearchParams] = useSearchParams()
  const referralCode = searchParams.get('ref')
  const redirect = searchParams.get('redirect')
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')
  const linkShortcut = searchParams.get('link-shortcut')

  useEffect(() => {
    if (redirect) {
      const emailPattern = /[\w.-]+@[\w.-]+/
      const emailMatch = redirect.match(emailPattern)
      const isEmail = emailHelper.validateTLD(
        emailMatch[0],
        clientConfig.emailAddress.topLevelDomains,
      )

      if (emailMatch && isEmail) {
        const matchedEmail = emailMatch[0]
        setValue('email', matchedEmail)
        const newUrl = redirect.split('?')
        searchParams.set('redirect', newUrl[0])
        searchParams.delete('link-shortcut')
        searchParams.delete('source')
        setSearchParams(searchParams)
      }
    }
    // eslint-disable-next-line
  }, [redirect])

  const onSubmit = async (data) => {
    await onSignIn(data)
  }

  const firstError = filterFirstError(errors, ['email', 'password'])

  return (
    <Layout>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <GuestInputWrapper>
          <GuestTitle>
            <Trans i18nKey="action_sign_in" />
          </GuestTitle>
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            placeholder={t('authentication_screen_email_placholder')}
            {...register('email', {
              required: t('singup_error_no_email_filled'),
              pattern: {
                value: clientConfig.emailAddress.format,
                message: t('errors.invalid_email_address'),
              },
              validate: {
                tld: (value) =>
                  emailHelper.validateTLD(
                    value,
                    clientConfig.emailAddress.topLevelDomains,
                  ) || t('errors.invalid_email_address'),
              },
            })}
            onBlur={(e) => {
              setValue('email', e.target.value.toLowerCase().trim())
              // noinspection JSIgnoredPromiseFromCall
              trigger('email')
            }}
            errors={firstError}
          />
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            type="password"
            placeholder={t('authentication_screen_password')}
            {...register('password', {
              required: t('singup_error_no_password_filled'),
              minLength: {
                value: 6,
                message: t('singup_error_min_characters', {
                  field: 'Password',
                  min: 6,
                }),
              },
            })}
            errors={firstError}
          />
        </GuestInputWrapper>

        <GuestInputWrapper>
          <div className={styles.RememberMe}>
            <InputLabel>
              <InputCheckbox
                id={'remember'}
                checked={rememberFieldValue}
                onChange={() => {
                  setValue('remember', !rememberFieldValue)
                }}
              />

              <Trans i18nKey="authentication_screen_remember_me" />
            </InputLabel>

            <Link
              to={ROUTES.RESET_PASSWORD}
              linkClassName={styles.ForgotPasswordLink}
            >
              <Trans i18nKey="authentication_screen_forgot_password" />
            </Link>
          </div>
        </GuestInputWrapper>

        <ButtonNew isLoading={submitting} size={'xl'} type={'submit'}>
          <Trans i18nKey="action_sign_in" />
        </ButtonNew>

        <GuestFooter
          link={`${ROUTES.SIGN_UP}${
            referralCode ? `?ref=${referralCode}` : ''
          }${
            shortcutId
              ? `?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}&link-shortcut=${linkShortcut}`
              : ''
          }`}
          linkKey="action_sign_up"
          msgKey="authentication_screen_dont_have_an_account"
        />
      </form>
    </Layout>
  )
}
export default SignInForm
