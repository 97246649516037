import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToast } from '@hooks/useToast'
import useBoard from '@hooks/useBoard'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useReverseTrialLocalStorageHelper from '@features/reverseTrial/hooks/useReverseTrialLocalStorageHelper'
import { USER_PREFERENCE_FIELDS } from '@features/reverseTrial/models/reverseTrialModels'
import { Folder } from '../../../models'
import { toastActions } from '../../../../actions'

export const useReverseTrialToastMessage = ({
  isActive,
  isSharedFolderMode,
  folder,
}: {
  folder: Folder
  isSharedFolderMode?: boolean
  isActive?: boolean
}) => {
  const { isFreePlanUser } = useReverseTrialContext()
  const { t } = useTranslation()

  const toast = useToast()
  const { isReadOnly, isOwnBoard, isBoardOwnerInFreePlan } = useBoard()
  const { currentUserPreference, setPreference } =
    useReverseTrialLocalStorageHelper()
  const dispatch = useDispatch()

  useEffect(() => {
    const showCoEditorToastMessage = Boolean(
      (isFreePlanUser &&
        isActive &&
        !isOwnBoard &&
        !isSharedFolderMode &&
        !isReadOnly) ||
        isBoardOwnerInFreePlan,
    )

    if (
      showCoEditorToastMessage &&
      !currentUserPreference?.shouldShowReverseTrialToastMessage
    ) {
      dispatch(toastActions.closeToastMessage())
      toast(
        t(
          isBoardOwnerInFreePlan
            ? 'co_editor_limit_editor'
            : 'co_editors_shared_active_page',
        ),
        false,
        0,
        false,
        false,
        () => {
          setPreference(USER_PREFERENCE_FIELDS.toastMessage, true)
        },
      )
    } else {
      dispatch(toastActions.closeToastMessage())
    }

    return () => {
      dispatch(toastActions.closeToastMessage())
    }
  }, [
    isOwnBoard,
    isActive,
    isFreePlanUser,
    t,
    toast,
    folder.content,
    isSharedFolderMode,
    isReadOnly,
    currentUserPreference?.shouldShowReverseTrialToastMessage,
    setPreference,
    dispatch,
    isBoardOwnerInFreePlan,
  ])
}
