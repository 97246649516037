import { useTranslation } from 'react-i18next'
import { MenuItem, useDialog, UploadIcon, GridIcon } from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import ModalMenu from '@ui/components/ModalMenu'

interface Props {
  title: string
  onGalleryClick: () => void
  onFromBoardsClick: () => void
}
const useMediaPickerDialog = ({
  title,
  onGalleryClick,
  onFromBoardsClick,
}: Props) => {
  const { open, close } = useDialog()
  const { t } = useTranslation()

  const handleFromBoardsClick = () => {
    close()

    onFromBoardsClick()
  }

  const handleFromGalleryClick = () => {
    close()

    onGalleryClick()
  }

  const MENU_ITEMS = [
    {
      id: 'grid',
      title: t('image_selector_from_boards'),
      icon: <GridIcon />,
      onClick: handleFromBoardsClick,
    },
    {
      id: 'upload',
      title: t('image_selector_from_pc'),
      icon: <UploadIcon />,
      onClick: handleFromGalleryClick,
    },
  ]

  const openMediaPickerDialog = () => {
    open(
      <ModalMenu
        items={MENU_ITEMS}
        title={title}
        renderItem={(field) => (
          <MenuItem
            style={{
              cursor: 'pointer',
              columnGap: '10px',
              width: '100%',
            }}
            key={field.id}
            onClick={field.onClick}
          >
            <IconWrapper>{field.icon}</IconWrapper>
            {field.title}
          </MenuItem>
        )}
      />,
    )
  }

  return openMediaPickerDialog
}

export default useMediaPickerDialog
