import React, { FC, memo } from 'react'
import { ButtonNew, DeleteBigIcon, Icon } from 'boards-web-ui'
import { SimpleDialog } from '../../../../ui/components/SimpleDialog'

import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useOnMount } from '../../../../ui/hooks/useOnMount'
import styles from './DeleteMessageConfirmation.module.css'

type Props = {
  onCancel: () => void
  onDelete: () => void
}

export const DeleteMessageConfirmation: FC<Props> = ({
  onCancel,
  onDelete,
}) => {
  const {
    cancelValue,
    deleteValue,
    deleteConfirmationMessage,
    deleteConfirmationTitle,
  } = useUpdatesTranslations()

  const { deletePopUpUpdateShown } = useUpdatesEvents()
  useOnMount(deletePopUpUpdateShown)

  return (
    <SimpleDialog
      icon={
        <Icon className={styles.DeleteIcon}>
          <DeleteBigIcon />
        </Icon>
      }
      className={styles.NonDefault}
      title={deleteConfirmationTitle()}
      message={<p className={styles.Centred}>{deleteConfirmationMessage()}</p>}
    >
      <ButtonNew onClick={onCancel} color="secondary" size="large">
        {cancelValue()}
      </ButtonNew>

      <ButtonNew onClick={onDelete} color="warning" size="large">
        {deleteValue()}
      </ButtonNew>
    </SimpleDialog>
  )
}

export default memo(DeleteMessageConfirmation)
