import { useDialog } from 'boards-web-ui'
import { AcademyPageErrorModal } from './AcademyPageErrorModal'

export const useAcademyPageErrorModal = ({
  onReloadClick,
}: {
  onReloadClick: () => void
}) => {
  const { open, close } = useDialog()

  const openAcademyPageErrorModal = () => {
    open(
      <AcademyPageErrorModal onReloadClick={onReloadClick} onClose={close} />,
      {
        closeOnEscapeButton: true,
      },
    )
  }

  return { openAcademyPageErrorModal }
}
