import React, { FC, memo } from 'react'

import { normalizeLinksInText } from 'boards-web-ui'

import { ReadMoreText } from '../../../../ui/components/ReadMoreText'

import styles from './UpdatesMessageBody.module.css'

type Props = {
  title: string
  text: string
  presentationMode: boolean
}

export const UpdatesMessageBody: FC<Props> = ({
  title,
  text,
  presentationMode,
}) => {
  return (
    <div>
      {title ? (
        <>
          <p
            className={styles.Title}
            dangerouslySetInnerHTML={{
              __html: normalizeLinksInText(title),
            }}
          />
          {text && (
            <p className={styles.Text}>
              {presentationMode ? (
                <ReadMoreText>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: normalizeLinksInText(text),
                    }}
                  />
                </ReadMoreText>
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: normalizeLinksInText(text),
                  }}
                />
              )}
            </p>
          )}
        </>
      ) : (
        <p className={styles.Text}>
          {presentationMode ? (
            <ReadMoreText>
              <span
                dangerouslySetInnerHTML={{ __html: normalizeLinksInText(text) }}
              />
            </ReadMoreText>
          ) : (
            <span
              dangerouslySetInnerHTML={{ __html: normalizeLinksInText(text) }}
            />
          )}
        </p>
      )}
    </div>
  )
}

export default memo(UpdatesMessageBody)
