import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  CopyIcon,
  DeleteBigIcon,
  FolderIcon,
  Icon,
  Menu,
  MenuItem,
  PremiumIndicationIcon,
  TrashIcon,
} from 'boards-web-ui'
import { useDestinationPicker } from 'app/features/pickers/destinationPicker'
import useNode from '@features/board/hooks/useNode'
import useBoard from '@hooks/useBoard'
import useBoardMode from '@features/board/hooks/useBoardMode'
import { ContentPickersEnum } from '@features/pickers'
import { AnalyticsNodeType } from '@features/analytics/useNodeEvents'
import { useCopyReceivedContent } from '@hooks/useCopyReceivedContent'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywall from '@hooks/usePaywall'
import { IconWrapper } from '@ui/components/IconWrapper'

import useNodeDeletorAPI from '../../nodeEditor/hooks/useNodeDeletorAPI'
import { Node, NodeType } from '../../../models'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'

import styles from './NodeActionsMenu.module.css'

type MenuProps = {
  node: Node
  onItemClick: () => void
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  isReadOnly: boolean
  isLimitedForEditNode: boolean
}

export const NodeActionsMenu: React.FC<MenuProps> = ({
  node,
  onItemClick,
  setMenuOpen,
  isReadOnly,
  isLimitedForEditNode,
}) => {
  const { boardId, folder, isPageBoard, isCopyAllowedByRole } = useBoard()
  const mode = useBoardMode()

  const { t } = useTranslation()
  const { openDestinationPicker } = useDestinationPicker({
    onClose: (picker: ContentPickersEnum) => {
      if (picker === ContentPickersEnum.MovePicker) {
        setMenuOpen(false)
        onItemClick()
      }
    },
  })
  const isPage = node.type === NodeType.PAGE

  const { onSelect } = useNode({
    boardId,
    folderId: folder?.id,
    mode,
    folder,
    isSharedFolderMode: false,
  })

  const confirmDialog = useConfirmDialog()
  const { deleteNode } = useNodeDeletorAPI(boardId, folder?.id)

  const handleDelete = () => {
    setMenuOpen(false)

    confirmDialog({
      icon: (
        <Icon className={styles.Icon}>
          <DeleteBigIcon />
        </Icon>
      ),
      title: isPage
        ? t('dialog_delete_page_title')
        : t('confirmation_message_delete_selected_key'),
      message: isPage
        ? t('dialog_delete_page_body')
        : t('dialog_delete_this_key'),
      confirmText: t('action_delete'),
      confirmButtonType: 'warning',
      className: styles.DeleteContentPopUp,
      cancelText: t('action_cancel'),
      onConfirm: () => {
        if (onItemClick) {
          onItemClick()
        }

        deleteNode(node)
      },
    })
  }

  const { canCopy, onCopyClick } = useCopyReceivedContent()

  const { openPaywall } = usePaywall()

  const handleCopyClick = useCallback(() => {
    if (isLimitedForEditNode) {
      openPaywall({ source: PaywallSourceEnum.COPY_CONTENT })
      return
    }

    setMenuOpen(false)

    onCopyClick({
      source: PaywallSourceEnum.COPY_CONTENT,
      pickerProps: {
        headerCopy: t('destination_picker_copy_to'),
        performActionCopy: t('destination_picker_paste_here'),
        performActionSuccessMessage: t('toast_content_copied'),
        contentType: AnalyticsNodeType[node.type],
      },
      onBeforeOpenPicker: () => onSelect(node.id, node.type),
      isPreviewMode: true,
    })
  }, [
    isLimitedForEditNode,
    node.id,
    node.type,
    onCopyClick,
    onSelect,
    openPaywall,
    setMenuOpen,
    t,
  ])

  const startCopyIcon = () => {
    if (canCopy) {
      return <CopyIcon />
    }
    return isLimitedForEditNode ? null : <PremiumIndicationIcon />
  }

  return (
    <Menu as="div">
      {isCopyAllowedByRole ? (
        <MenuItem as="button" name="copy" onClick={handleCopyClick}>
          <IconWrapper size={20} className={styles.ItemIcon}>
            {startCopyIcon()}
          </IconWrapper>

          {t('action_copy')}

          {isLimitedForEditNode ? (
            <>
              <span style={{ marginLeft: '6px' }} />

              <PremiumIndicationIcon fontSize={'20px'} />
            </>
          ) : null}
        </MenuItem>
      ) : null}

      {!isReadOnly && !isPageBoard && isCopyAllowedByRole && (
        <MenuItem
          as="button"
          name="move"
          onClick={() => {
            if (isLimitedForEditNode) {
              openPaywall({ source: PaywallSourceEnum.MOVE_CONTENT })
              return
            }

            onSelect(node.id, node.type)
            openDestinationPicker({
              picker: ContentPickersEnum.MovePicker,
              contentType: AnalyticsNodeType[node.type],
              headerCopy: <Trans i18nKey={'destination_picker_move_to'} />,
              performActionCopy: (
                <Trans i18nKey={'destination_picker_move_here'} />
              ),
              performActionSuccessMessage: t('toast_content_moved'),
            })
          }}
        >
          <IconWrapper className={styles.ItemIcon}>
            <FolderIcon />
          </IconWrapper>
          {t('action_move')}

          {isLimitedForEditNode ? (
            <>
              <span style={{ marginLeft: '6px' }} />

              <PremiumIndicationIcon fontSize={'20px'} />
            </>
          ) : null}
        </MenuItem>
      )}

      {!isReadOnly && isCopyAllowedByRole && (
        <MenuItem as="button" name="delete" onClick={handleDelete}>
          <IconWrapper className={styles.ItemIcon}>
            <TrashIcon />
          </IconWrapper>

          {t(isPageBoard || isPage ? 'action_delete_page' : 'action_delete')}
        </MenuItem>
      )}
    </Menu>
  )
}
