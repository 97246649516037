import { DEFAULT_PROFILE } from '../_firebase/listeners/transformers/ProfileTransformer'
import { AppState } from '../app/models/appState'

export const initialState: AppState = {
  boards: {},
  folders: {},
  invites: {},
  permissions: {},
  persistUser: false,
  permissionsLoaded: {}, // temp solution as long as the number of permissions is not part of the board document
  profileInfo: { ...DEFAULT_PROFILE },
  undoActions: {},
  updates: {},
  updatesReadUnRead: {},
  updatesUnPostMessages: {},
  plans: {
    data: {
      current: null,
      plans: [],
    },
    status: null,
    discountId: undefined,
  },
  app: {
    currentFolderId: undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    permissions: {},
    content: {
      order: [],
    },
    forms: {
      responseEmail: undefined,
    },
    undoStack: {
      undo: [],
      redo: [],
    },
    uploading: [],
    loading: true,
    bulk: {
      action: undefined,
      contentId: undefined,
      folderId: undefined,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      type: undefined,
      nodes: [],
    },
    actionInProgress: false,
    toast: {
      msg: undefined,
      global: false,
      customIcon: false,
    },
    pickersContentNavigation: {
      source: {},
      copy: {},
      move: {},
      compose: {},
    },
    pageEditor: {
      formErrors: {},
      buttonError: undefined,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      buttonType: undefined,
      buttonGeoData: undefined,
      isGoogleSheetsUrlValid: false,
    },
    geoData: undefined,
    nodeEditor: {
      errorMessage: null,
      pageStep: 1,
      loading: false,
      nodeTitle: '',
      editLink: {
        errorMessage: null,
      },
      nodeVideo: {
        hasError: false,
        videoUrl: '',
        videoId: '',
      },
    },
    sharedFolder: {
      folders: [],
      folderPath: [],
      shortcutId: '',
      boardId: '',
      folderId: '',
    },
    clientConfig: {
      emailAddress: {
        format:
          /^[a-z0-9._%+-]+@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?.)+[a-z0-9][a-z0-9-]/,
        frequentlyUsedDomains: [],
        topLevelDomains: [],
      },
      clientConfiguration: {
        reverseTrial: {
          isActive: false,
        },
      },
      academy: {
        isEnabled: false,
        url: '',
      },
    },
  },
  templates: {
    data: null,
    loading: true,
    error: null,
  },
}
