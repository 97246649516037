import { Trans, useTranslation } from 'react-i18next'
import { normalizeLinksInText } from 'boards-web-ui'
import { Folder, Node } from '../../../models'
import useNodeClipboard from '../../nodeEditor/hooks/useNodeClipboard'
import PageEmptyState from './PageEmptyState'
import PreviewModal from './PreviewModal'

import styles from './PreviewText.module.css'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  isDisabledPreviewNavigation?: boolean
  isReadOnly?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const PreviewText = ({
  node,
  prev,
  next,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  isDisabledPreviewNavigation,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation()
  const { copyToClipboard } = useNodeClipboard()

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyToClipboard(node)}
      prev={prev}
      next={next}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      isDisabledPreviewNavigation={isDisabledPreviewNavigation}
      isReadOnly={isReadOnly}
    >
      {node.text && (
        <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
          {node.text && (
            <div
              className={styles.Text}
              dangerouslySetInnerHTML={{
                __html: normalizeLinksInText(node.text),
              }}
            />
          )}
        </div>
      )}
      {!node.text && (
        <PageEmptyState>
          <Trans i18nKey={'preview_text_no_text'} />
        </PageEmptyState>
      )}
    </PreviewModal>
  )
}

export default PreviewText
