import { PropsWithChildren } from 'react'
import { InputField, NODE_LINK_TYPES } from 'boards-web-ui'

import { isPhoneInput } from './util'
import useButtonTranslations from './hooks/useButtonTranslations'
import FormHeaderWithTitleAndMessage from './components/FormHeaderWithTitleAndMessage'

type Props = PropsWithChildren<{
  title: string
  text: string
  type: NODE_LINK_TYPES
  error?: string
  autoFocusText?: boolean
  autoFocusTitle?: boolean
  onTitleChange: (t: string) => void
  onTextChange: (props: string) => void
  onTextFocus: () => void
}>
const EditTextButtonForm = ({
  title,
  text,
  type,
  error,
  autoFocusText,
  autoFocusTitle,
  onTitleChange,
  onTextChange,
  onTextFocus,
  children,
}: Props) => {
  const { getButtonTranslations } = useButtonTranslations()
  const buttonTranslations = getButtonTranslations(type)

  if (isPhoneInput(type)) {
    return null
  }

  return (
    <FormHeaderWithTitleAndMessage
      title={title}
      inputLabel={buttonTranslations.titleInputLabel}
      inputPlaceholder={buttonTranslations.titleInputPlaceholder}
      explanationText={buttonTranslations.explanationText}
      onTitleChange={onTitleChange}
      autoFocus={autoFocusTitle}
    >
      <div>
        <InputField
          value={text}
          label={buttonTranslations.textInputlabel}
          placeholder={buttonTranslations.textInputPlaceholder}
          errorMessage={error}
          onChange={(e) => onTextChange(e.target.value)}
          onFocus={onTextFocus}
          autoFocus={autoFocusText}
          blueVariant
          withResetButton
        />
        {children}
      </div>
    </FormHeaderWithTitleAndMessage>
  )
}

export default EditTextButtonForm
