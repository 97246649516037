import {
  getFolderId,
  getNodeId,
  getRootFolderId,
} from '@helpers/NodeIdGenerator'
import { insertBefore } from '@helpers/array'
import { convertFolderNodeToPageNode } from '@helpers/nodeType'
import { Board, Folder, Node, NodeType } from '../app/models'
import { AppState } from '../app/models/appState'
import { FormIntegrationStatusData } from '../_firebase'

interface IBoards {
  [id: string]: Board
}

interface IFolders {
  [id: string]: {
    [id: string]: Folder
  }
}

export interface IProperties {
  id: string
  icon: string
  text: string
  title: string
}

interface INodesWithNewKeys {
  nodes: Node[]
  folders: {
    [id: string]: Folder
  }
}

interface IAddNodesProps {
  folders: IFolders
  boardId: string
  fid: string
  nodes: Node[]
  beforeNodeId?: string
  beforeNodeIndex?: number
}

export const getNodes = (
  folders: IFolders | Folder[],
  boardId: string,
  fid: string,
  nodeIds: string[],
  shortcutId?: string,
): Node[] => {
  let folder
  if (Array.isArray(folders)) {
    if (shortcutId) {
      folder = folders.find(({ id }) => id === fid)
    }
  } else {
    folder = folders[boardId][fid]
  }

  if (!folder) {
    return []
  }

  return folder.content.filter(({ id }) => nodeIds.includes(id))
}

export const addNodes = ({
  folders,
  boardId,
  fid,
  nodes,
  beforeNodeId,
  beforeNodeIndex,
}: IAddNodesProps): IFolders => {
  const folderNodes = folders[boardId][fid].content

  const resolvedIndex =
    beforeNodeIndex !== undefined
      ? beforeNodeIndex
      : folderNodes.findIndex((n) => n.id === beforeNodeId)

  const content = insertBefore(folderNodes, resolvedIndex, nodes)

  return {
    ...folders,
    [boardId]: {
      ...folders[boardId],
      [fid]: {
        ...folders[boardId][fid],
        content,
      },
    },
  }
}

export const deleteNodes = (
  folders: IFolders,
  boardId: string,
  fid?: string,
  nodeIds?: string[],
): IFolders => {
  if (!fid || !nodeIds) {
    return folders
  }

  const content = folders[boardId][fid].content.filter(
    (n) => !nodeIds.includes(n.id),
  )
  return {
    ...folders,
    [boardId]: {
      ...folders[boardId],
      [fid]: {
        ...folders[boardId][fid],
        content,
      },
    },
  }
}

export const addFolder = (
  folders: IFolders,
  boardId: string,
  folder: Folder,
): IFolders => {
  return {
    ...folders,
    [boardId]: {
      ...folders[boardId],
      [folder.id]: folder,
    },
  }
}

export const updateFoldersFormIntegrationState = (
  state: AppState,
  boardId: string,
  fId: string,
  nId: string,
  integrationState?: FormIntegrationStatusData,
): AppState => {
  const node = getNode(state.folders, boardId, fId, nId)

  if (!node) return { ...state }

  return updateNodeIntegrationStatus(state, boardId, fId, nId, integrationState)
}

export const updateFolders = (
  state: AppState,
  boardId: string,
  fid?: string,
  nId?: string,
  properties?: IProperties,
): AppState => {
  if (!fid || !nId || !properties) {
    return { ...state }
  }
  const isRootFolder = getRootFolderId(boardId) === nId
  const node = getNode(state.folders, boardId, fid, nId)

  if (isRootFolder) {
    return updateRootFolder(state, boardId, fid, nId, properties)
  }

  if (!node) return state

  if ([NodeType.FOLDER, NodeType.PAGE].includes(node.type)) {
    return updateFolder(state, boardId, fid, nId, properties)
  }

  return updateNode(state, boardId, fid, nId, properties)
}

export const nodesWithNewKeys = (
  folders: { [id: string]: Folder },
  nodes: Node[],
  key: string,
  isPage: boolean,
): INodesWithNewKeys => {
  const folderWithNewIds = folders

  function folderWithNewKey(fid: string) {
    const folder = folders[fid]
    if (!folder) return

    const newFolderId = getFolderId(fid, key)
    folderWithNewIds[newFolderId] = {
      ...folder,
      id: newFolderId,
      content: folder.content.map(nodeWithNewKey),
    } as Folder
  }

  function nodeWithNewKey(n: Node) {
    if (n.type === NodeType.FOLDER || n.type === NodeType.PAGE) {
      folderWithNewKey(n.id)
      return {
        ...n,
        id: getFolderId(n.id, key),
      }
    }

    let node = {
      ...n,
      id: getNodeId(n.id, key),
    }

    if (node.type === NodeType.CHECKOUT) {
      node = {
        ...node,
        form: {
          ...node.form,
          paymentProviders: [],
        },
      }
    }

    if (node.type === NodeType.CHECKOUT || node.type === NodeType.FORM) {
      node = {
        ...node,
        form: {
          ...node.form,
          spreadsheetURL: '',
          encryptedSpreadsheetURL: '',
        },
      }
    }

    return isPage ? convertFolderNodeToPageNode(node) : node
  }

  const nodesWithNewIds = nodes.map(nodeWithNewKey)
  return {
    nodes: nodesWithNewIds,
    folders: folderWithNewIds,
  }
}

export const getNode = (
  folders: IFolders,
  boardId: string,
  fid: string,
  nid: string,
): Node | null => {
  try {
    const folder = folders[boardId][fid]
    return folder.content.find(({ id }) => id === nid) || null
  } catch (error) {
    return null
  }
}

const updateFolder = (
  state: AppState,
  boardId: string,
  fid: string,
  nid: string,
  properties: IProperties,
): AppState => {
  const { boards, folders } = state
  const folder = folders[boardId][nid]

  return {
    ...state,
    boards: resetBoardAction(boards, boardId),
    folders: {
      ...folders,
      [boardId]: {
        ...folders[boardId],
        [fid]: {
          ...folders[boardId][fid],
          content: folders[boardId][fid].content.map((n) => {
            if (n.id === nid) {
              return { ...n, ...properties }
            }
            return n
          }),
        },
        [nid]: {
          ...{
            id: folder?.id,
            icon: folder?.icon,
            title: folder?.title,
            isPage: !!folder?.isPage,
            shortcut: folder?.shortcut,
          },
          content: folder?.content,
          ...properties,
        } as Folder,
      },
    },
  }
}

const updateRootFolder = (
  state: AppState,
  boardId: string,
  fid: string,
  nid: string,
  properties: IProperties,
): AppState => {
  const { boards, folders } = state

  return {
    ...state,
    boards: resetBoardAction(boards, boardId),
    folders: {
      ...folders,
      [boardId]: {
        ...folders[boardId],
        [fid]: {
          ...folders[boardId][nid],
          ...properties,
        },
      },
    },
  }
}

const updateNodeIntegrationStatus = (
  state: AppState,
  boardId: string,
  fid: string,
  nid: string,
  integrationState?: FormIntegrationStatusData,
): AppState => {
  const { folders, boards } = state

  return {
    ...state,
    boards: resetBoardAction(boards, boardId),
    folders: {
      ...folders,
      [boardId]: {
        ...folders[boardId],
        [fid]: {
          ...folders[boardId][fid],
          content: folders[boardId][fid].content.map((n) => {
            if (n.id === nid) {
              return {
                ...n,
                integrationState: {
                  ...n?.integrationState,
                  ...integrationState,
                } as FormIntegrationStatusData,
              }
            }
            return n
          }),
        },
      },
    },
  }
}

const updateNode = (
  state: AppState,
  boardId: string,
  fid: string,
  nid: string,
  properties: IProperties,
): AppState => {
  const { folders, boards } = state

  return {
    ...state,
    boards: resetBoardAction(boards, boardId),
    folders: {
      ...folders,
      [boardId]: {
        ...folders[boardId],
        [fid]: {
          ...folders[boardId][fid],
          content: folders[boardId][fid].content.map((n) => {
            if (n.id === nid) {
              return { ...n, ...properties }
            }
            return n
          }),
        },
      },
    },
  }
}

const resetBoardAction = (boards: IBoards, boardId: string): IBoards => {
  return {
    ...boards,
    [boardId]: {
      ...boards[boardId],
      action: {
        ...boards[boardId].action,
        undoHead: undefined,
        redoHead: undefined,
      },
    },
  }
}
