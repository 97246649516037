import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  PostMessagePopupIcon,
} from 'boards-web-ui'
import React from 'react'
import { Trans } from 'react-i18next'
import { useCreateMessage } from '@features/updates/hooks/useCreateMessage'
import styles from './ConfirmModal.module.css'

interface Props {
  onConfirm: () => void
}

export const ConfirmModal = ({ onConfirm }: Props) => {
  const { count, board } = useCreateMessage()

  return (
    <div className={styles.Root}>
      <CardBody className={styles.Container}>
        <CardBodyContent className={styles.Content}>
          <div className={styles.IconContainer}>
            <PostMessagePopupIcon width={118} height={127} />
          </div>

          <div className={styles.Title}>
            <Trans i18nKey={'dialog_post_confirmation_title'} />
          </div>

          <div className={styles.Message}>
            <Trans
              i18nKey={'dialog_confirmation_message_before_posting_with_name'}
              components={{
                strong: <span style={{ fontWeight: '500' }} />,
              }}
              values={{
                members: count,
                Board_emoji: board?.icon || '',
                Board_name: board?.title || '',
              }}
            />
          </div>
        </CardBodyContent>

        <CardActions className={styles.Actions}>
          <ButtonNew fullWidth size="large" onClick={onConfirm}>
            <Trans i18nKey={'action_post'} />
          </ButtonNew>
        </CardActions>
      </CardBody>
    </div>
  )
}
