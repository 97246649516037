import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import FolderListItem from '@features/board/components/FolderListItem'
import {
  eEnvironment,
  Node as NodeComponent,
  Warning2Icon,
} from 'boards-web-ui'
import usePageNodeTransformer from '@features/nodes/hooks/usePageNodeTransformer'
import { buildLayout } from '@features/board/helpers/layout'
import { IconWrapper } from '@ui/components/IconWrapper'
import clsx from 'clsx'
import {
  MessageAttachment,
  MessageAttachmentNodeSize,
  MessageAttachmentNodeSizes,
  Node,
  NodeType,
} from '../../../models'
import styles from './MessageAttachments.module.css'

const DEFAULT_ATTACHMENT_PREVIEW_SIZE: MessageAttachmentNodeSize = {
  width: 200,
  height: 200,
}

const MESSAGE_ATTACHMENT_PREVIEW_SIZES: MessageAttachmentNodeSizes = {
  [NodeType.IMAGE]: {
    width: 334,
    height: 323,
  },
  [NodeType.LINK]: {
    width: 334,
    height: 94,
  },
  [NodeType.PDF]: {
    width: 334,
    height: 323,
  },
  [NodeType.PAGE]: {
    width: 334,
    height: 323,
  },
  [NodeType.TEXT]: {
    width: 334,
    height: 94,
  },
  [NodeType.VIDEO_STREAMING]: {
    width: 334,
    height: 216,
  },
  [NodeType.VIDEO]: {
    width: 334,
    height: 216,
  },
  [NodeType.FOLDER]: {
    width: 200,
    height: 94,
  },
  [NodeType.AUDIO]: {
    width: 334,
    height: 94,
  },
}

const MessageAttachmentNotFound = () => {
  return (
    <div className={styles.NotFound}>
      <IconWrapper size={20}>
        <Warning2Icon />
      </IconWrapper>
      <span className={styles.NotFoundText}>
        <Trans i18nKey="message_content_deleted" />
      </span>
    </div>
  )
}

type Props = {
  messageAttachment: MessageAttachment
  onAttachmentClick: (params: { folderId: string; node: Node }) => void
  onShowInBoardClick: (params: { folderId: string; node: Node }) => void
  nodeSize?: MessageAttachmentNodeSize
  isDeletedNode: boolean
}

export const MessageAttachments: FC<Props> = ({
  messageAttachment,
  onAttachmentClick,
  onShowInBoardClick,
  isDeletedNode,
  nodeSize = MESSAGE_ATTACHMENT_PREVIEW_SIZES[
    messageAttachment.node?.type as keyof MessageAttachmentNodeSizes
  ] ?? DEFAULT_ATTACHMENT_PREVIEW_SIZE,
}) => {
  const transformPageNode = usePageNodeTransformer()
  const { node, folderId, isDeletedOrMoved } = messageAttachment

  if (!node || isDeletedNode || isDeletedOrMoved) {
    return <MessageAttachmentNotFound />
  }

  const layoutNode = buildLayout([node]).tiles[0]

  layoutNode.rect = { ...layoutNode.rect, ...nodeSize }

  return (
    <div className={styles.Root}>
      <div
        className={styles.Attachment}
        style={{ height: layoutNode.rect.height }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          onAttachmentClick({
            folderId,
            node,
          })
        }}
      >
        <FolderListItem
          id={`${layoutNode.node.id}-message-attachment`}
          rect={layoutNode.rect}
          index={0}
          className={clsx([
            styles.AttachmentBorder,
            node.type === NodeType.VIDEO_STREAMING && styles.AttachmentVideo,
            node.type === NodeType.PDF && styles.AttachmentPdf,
          ])}
        >
          <NodeComponent
            node={transformPageNode(layoutNode.node).node}
            titleBreakLineThreshold={100}
            environment={eEnvironment.Editor}
          />
        </FolderListItem>
      </div>
      <div className={styles.ActionButtonWrapper}>
        <span
          className={styles.ActionButton}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            onShowInBoardClick({
              folderId,
              node,
            })
          }}
        >
          <Trans i18nKey={'message_show_in_board'} />
        </span>
      </div>
    </div>
  )
}
