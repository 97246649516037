import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DialogProvider } from 'boards-web-ui'
import SharedBoardModal from '@features/editor/components/SharedBoardModal'
import ReverseTrialBar from '@features/editor/components/reverseTrial/ReverseTrialBar'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'

import { BannerProvider } from '@ui/components/Banner/BannerProvider'
import useBulk from '@hooks/bulk/useBulk'
import { useSharedFolder } from '@hooks/useSharedFolder'
import useBoard from '@hooks/useBoard'
import useBoards from '@hooks/useBoards'

import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'
import UpdatesProvider from '../../updates/UpdatesProviders'
import useRotesHelper from '../../../routes/hooks/useRotesHelper'
import useAutoSelectFirstBoardOrRedirect from '../../../routes/hooks/useAutoSelectFirstBoardOrRedirect'
import useLoadCurrentBoardFolders from '../hooks/useLoadCurrentBoardFolders'
import useDialogFromSearchParams from '../hooks/useDialogFromSearchParams'
import ScrollAreasProvider from '../../../../ui/components/Scroll/ScrollAreasProvider'

import SideBar from '../../sidebar/SideBar'
import AppUpdatesSection from '../../updates/AppUpdatesSection'

import EditorNav from '../components/EditorNav'

import BoardToolBar from '../../toolbar/BoardToolBar'
import ToastMessageContainer from '../../../../containers/ToastMessageContainer'

import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import EmptyContent, {
  EmptyBoardVariants,
} from '../../../../components/EmptyContent'
import BulkActionsToolbar from '../../bulkActionsToolbar/BulkActionsToolbar'

import { ReactComponent as Spinner } from '../../../../icons/Spinner.svg'
import { isMobile } from '../../../../utils/deviceParser'

import Modal from '../../../../components/Modal'
import MobilePopup from '../../sidebar/components/MobilePopup'
import MobilePopupHeader from '../../sidebar/components/MobilePopupHeader'

import { folderActions } from '../../../../actions'

import styles from './EditorRoot.module.css'
import { EDITOR, QUERY_PARAMS } from '../../../constants/routes'

const ID_TOP_BANNER = 'editor-root-top-banner-id'
const ID_EDITOR_HEADER = 'editor-root-header-id'
const HEADER_IDS = [ID_TOP_BANNER, ID_EDITOR_HEADER]

const RenderElement: React.FC = () => {
  useLoadCurrentBoardFolders()
  useDialogFromSearchParams()

  const { boards } = useBoards()

  const { resetBulkActions, isSelectionModeEnabled } = useBulk()

  const { board } = useBoard()

  // TODO refactor with BLISS-11790
  const heights = HEADER_IDS.map((id) => {
    const element = document.getElementById(id)
    if (element) {
      return element.offsetHeight
    }
    return 0
  })

  const availableHeight = `calc(100vh - ${heights[0] + heights[1]}px)`

  if (!board || Object.keys(boards).length === 0) {
    return (
      <>
        <EmptyContent variant={EmptyBoardVariants.noBoards} />
        <Outlet />
      </>
    )
  }

  return (
    <>
      <div id={ID_EDITOR_HEADER}>
        {isSelectionModeEnabled && (
          <BulkActionsToolbar
            className={styles.BulkContainer}
            isPageBoard={board.isPageBoard}
          />
        )}

        <BoardToolBar className={isSelectionModeEnabled ? styles.Hidden : ''} />

        <EditorNav onLinkClicked={resetBulkActions} />
      </div>
      <div className={styles.EditorBody}>
        <div className={styles.EditorBodyContent}>
          <Outlet />
        </div>
        <div
          style={{
            height: availableHeight,
          }}
        >
          <AppUpdatesSection />
        </div>
      </div>
    </>
  )
}

type Props = PropsWithChildren<Record<string, unknown>>
const EditorRoot: React.FC<Props> = () => {
  const currentBoardExists = useAutoSelectFirstBoardOrRedirect()
  useFetchAvailableSubscriptions()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { pending } = useRotesHelper()
  const { loaded } = useProfileInformation()
  const [open, setOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const shortcutId = searchParams.get(QUERY_PARAMS.shortcutId)
  const boardId = searchParams.get(QUERY_PARAMS.boardId)
  const folderId = searchParams.get(QUERY_PARAMS.folderId)
  const copyBoardAllowed = searchParams.get(QUERY_PARAMS.copyBoardAllowed)

  const { shouldShowFreeTrialIndicator, shouldShowFreePlanIndicator } =
    useReverseTrialContext()

  const {
    sharedFolderState,
    isSharedFolderExistsInFolders,
    showSharedFolder,
    foundCompositeBoardId,
    isUserOwnerOfSharedBoard,
  } = useSharedFolder()

  useEffect(() => {
    if (shortcutId && boardId && folderId) {
      dispatch(
        folderActions.updateSharedFolderData({
          shortcutId,
          boardId,
          folderId,
          copyBoardAllowed: Boolean(copyBoardAllowed),
        }),
      )
      searchParams.delete(QUERY_PARAMS.shortcutId)
      searchParams.delete(QUERY_PARAMS.boardId)
      searchParams.delete(QUERY_PARAMS.folderId)
      searchParams.delete(QUERY_PARAMS.copyBoardAllowed)
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      isSharedFolderExistsInFolders &&
      sharedFolderState.boardId &&
      sharedFolderState.folderId &&
      (!sharedFolderState.copyBoardAllowed || isUserOwnerOfSharedBoard)
    ) {
      navigate(`/${foundCompositeBoardId}/${sharedFolderState.folderId}`)
      dispatch(
        folderActions.updateSharedFolderData({
          shortcutId: '',
          boardId: '',
          folderId: '',
        }),
      )
    }
  }, [
    isSharedFolderExistsInFolders,
    sharedFolderState,
    navigate,
    foundCompositeBoardId,
    dispatch,
    isUserOwnerOfSharedBoard,
  ])

  const canRenderSharedFolder = showSharedFolder && !(pending || !loaded)

  const { routeHasRedirect, redirectToOverwriteRoute } =
    useOverwriteRouteRedirect()

  useEffect(() => {
    if (isMobile) {
      navigate('/welcome')
    }

    if (routeHasRedirect(EDITOR)) {
      redirectToOverwriteRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Fix blank screen when user click back button
   *
   * TODO: avoid reset state in FirebaseListenersProvider
   */
  if (!currentBoardExists && !loaded) return null

  return (
    <ScrollAreasProvider>
      <UpdatesProvider>
        <BannerProvider>
          <DialogProvider>
            <main className={styles.Body}>
              <Modal
                variant="smallPopup"
                Header={<MobilePopupHeader setOpen={setOpen} />}
                onClickOutside={undefined}
                open={open}
                className={styles.MobileModal}
              >
                <MobilePopup setOpen={setOpen} />
              </Modal>

              <SideBar setOpen={setOpen} />

              <section className={styles.EditorBodyMainSection}>
                <div id={ID_TOP_BANNER}>
                  {(shouldShowFreeTrialIndicator ||
                    shouldShowFreePlanIndicator) && <ReverseTrialBar />}
                </div>
                {pending || !loaded ? (
                  <Spinner className={styles.Spinner} />
                ) : (
                  <RenderElement />
                )}

                {canRenderSharedFolder && (
                  <SharedBoardModal
                    shortcutId={sharedFolderState.shortcutId}
                    boardId={sharedFolderState.boardId}
                    folderId={sharedFolderState?.folderId}
                  />
                )}
                <ToastMessageContainer />
              </section>
            </main>

            {/* <Monetization /> Monetization Banner was commented by BLISS-4342 */}
          </DialogProvider>
        </BannerProvider>
      </UpdatesProvider>
    </ScrollAreasProvider>
  )
}

export default EditorRoot
