import { DialogProvider } from 'boards-web-ui'
import { FC, ReactNode } from 'react'

interface PaywallProvidersProps {
  children: ReactNode
}
const PaywallProviders: FC<PaywallProvidersProps> = ({ children }) => {
  return <DialogProvider>{children}</DialogProvider>
}

export default PaywallProviders
