import { useTranslation } from 'react-i18next'
import { urlHelper } from 'boards-web-ui'

import { Folder, Node } from '../../../models'
import useNodeClipboard from '../../nodeEditor/hooks/useNodeClipboard'
import PreviewModal from './PreviewModal'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  isDisabledPreviewNavigation?: boolean
  isReadOnly?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const VideoPreview = ({
  node,
  prev,
  next,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  isDisabledPreviewNavigation,
  isReadOnly,
}: Props) => {
  const video = urlHelper.getVideoDetails(node.text)
  const { t } = useTranslation()
  const { copyToClipboard } = useNodeClipboard()

  if (!video) return null

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyToClipboard(node)}
      prev={prev}
      next={next}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      isDisabledPreviewNavigation={isDisabledPreviewNavigation}
      isReadOnly={isReadOnly}
    >
      <iframe
        style={{ margin: '0 auto', borderRadius: '10px' }}
        title={node.title}
        src={video.url}
        width="650"
        height="355"
        frameBorder="0"
        onClick={(e) => e.stopPropagation()}
      />
    </PreviewModal>
  )
}

export default VideoPreview
