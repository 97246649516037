import { useEffect } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import usePaywallEvents from '@features/analytics/usePaywallEvents'
import { usePaywallTitle } from '@features/payments/hooks/usePaywallTitle'
import { ProductData } from '@features/payments/utils/productTransformer'
import useGeoLocation from '@hooks/useGeoLocation'
import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'
import useFetchAvailableSubscriptions from '@hooks/useFetchAvailableSubscriptions'

import {
  PLANS,
  PROFILING,
  QUERY_PARAMS,
  SIGN_IN,
  SIGN_UP,
} from 'app/constants/routes'
import subscriptionActions from 'actions/subscription'

interface Props {
  campaignId?: string
  currentPlan?: ProductData
}
export const usePublicPaywall = ({ campaignId, currentPlan }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loadGeoData } = useGeoLocation()
  const { overwriteRouteRedirect } = useOverwriteRouteRedirect()
  const { paywallView, paywallSubscribeClickEvent, paywallTabClickEvent } =
    usePaywallEvents()
  const { title: paywallTitle, isLongText } = usePaywallTitle({
    currentPlanTitle: currentPlan?.subscription?.title,
  })

  const isInCampaignMode = !!campaignId

  useFetchAvailableSubscriptions(campaignId || undefined)

  useEffect(() => {
    paywallView(campaignId)
  }, [campaignId, paywallView])

  useEffect(() => {
    loadGeoData()
  }, [loadGeoData])

  const handleSubscribeFreePlanClick = async () => {
    paywallSubscribeClickEvent('free', undefined, campaignId)

    navigate(SIGN_UP)
  }

  const handleSubscribePlanClick = async (priceId: string) => {
    const queryParams: { [key: string]: string } = {
      [QUERY_PARAMS.priceId]: priceId,
    }
    if (campaignId) queryParams.campaign = campaignId

    overwriteRouteRedirect(
      [SIGN_IN, PROFILING],
      `${PLANS}?${createSearchParams(queryParams)}`,
    )

    if (currentPlan) {
      paywallSubscribeClickEvent(currentPlan.productName, priceId, campaignId)
    }

    dispatch(subscriptionActions.resetAvailableSubscriptions())

    navigate(SIGN_UP)
  }

  return {
    paywallTitle,
    isLongText,
    isInCampaignMode,
    handlePlanSwipe: paywallTabClickEvent,
    handleSubscribeFreePlanClick,
    handleSubscribePlanClick,
  }
}
