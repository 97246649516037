import React, { FC, memo } from 'react'

import { DateDiff } from '@ui/components/DateDiff'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'
import { UpdatesMessage } from '../../../models'
import styles from './UpdatesMessageHeader.module.css'

type Props = {
  presentationMode?: boolean
  boardTitle: string
  icon: string
  author?: string
  className?: string
  message: UpdatesMessage
}

export const UpdatesMessageHeader: FC<Props> = ({
  presentationMode,
  icon,
  boardTitle,
  author,
  className,
  message,
}) => {
  const { postedBy } = useUpdatesTranslations()

  return (
    <div
      className={[
        styles.Root,
        className,
        presentationMode ? '' : styles.Margin,
      ].join(' ')}
    >
      {icon && <span className={styles.Icon}>{icon}</span>}
      {(boardTitle || author) && (
        <div className={styles.Wrap}>
          <span className={styles.BoardTitle}>{boardTitle}</span>
          <div className={styles.DateAuthor}>
            {author && (
              <span className={styles.Author}>{postedBy(author)}</span>
            )}
            <DateDiff date={(message?.time?.seconds ?? 0) * 1000} />
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(UpdatesMessageHeader)
