import { useDispatch } from 'react-redux'
import { nextTick, useDialog } from 'boards-web-ui'

import { putImage } from '_firebase'

import { randomContentId } from '@helpers/index'
import { generateNodeId } from '@helpers/NodeIdGenerator'
import useImagePicker from '@features/pickers/hooks/useImagePicker'

import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { ENV_KEYS } from 'utils/envKeys'
import { uploadActions } from 'actions'

import { NodeType, Node } from 'app/models'
import { IUseAddNode } from '../models/IUseAddNode'

const preventExitWhileLoading = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = false
}

const useAddImages: IUseAddNode = (save) => {
  const dispatch = useDispatch()
  const { close } = useDialog()
  const { imageBucket } = configurationObjectFromEnv({
    imageBucket: ENV_KEYS.IMAGE_BUCKET,
  })

  const createImages = async (files: File[]) => {
    await Promise.all(
      Array.from(files).map(async (file) => {
        const imageName = `${randomContentId()}.${file.name.split('.').pop()}`
        const nodeText = `${imageBucket}/${imageName}`

        const { nodeId, key } = generateNodeId()

        dispatch(uploadActions.addUpload(nodeId))

        const node = {
          key,
          id: nodeId,
          title: '',
          text: nodeText,
          image: imageName,
          type: NodeType.IMAGE,
        }

        // TODO get back to using await when the uploading progress bar will be ready
        // https://heybliss.atlassian.net/browse/BLISS-7549
        save({ ...node, isProcessing: true })
        await putImage(file, imageName)
        await nextTick()
        dispatch(uploadActions.removeUpload(nodeId))
      }),
    )
  }

  const uploadImage = async (fileList: FileList) => {
    window.addEventListener('beforeunload', preventExitWhileLoading)

    await createImages(Array.from(fileList))

    window.removeEventListener('beforeunload', preventExitWhileLoading)
  }

  const addImageNode = (node: Node & { type: NodeType.IMAGE }) => {
    save({
      title: '',
      text: node.text,
      type: NodeType.IMAGE,
    })
    close()
  }

  return useImagePicker<true>({
    isNew: true,
    multiple: true,
    nodeType: NodeType.IMAGE,
    onFilePicked: uploadImage,
    onNodePicked: addImageNode,
  })
}

export default useAddImages
