import React, { FC, useCallback, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonNew,
  InputField,
  TooltipPositions,
  useDialog,
} from 'boards-web-ui'
import { useUpdatesTranslations } from '@features/updates/hooks/useUpdatesTranslations'
import { useForm, useWatch } from 'react-hook-form'
import { useToast } from '@hooks/useToast'
import ContentIcon from '@features/toolbar/components/ContentIcon'
import useToolbarTranslations from '@features/toolbar/hooks/useToolbarTranslations'

import { ContentPickersEnum, EntityDialogEnum } from '@features/pickers'
import { useAddNewEntityActions } from '@features/pickers/destinationPicker/AddNewEntity/useAddNewEntityActions'
import styles from './AddNewEntity.module.css'

interface IProps {
  entity: EntityDialogEnum
  picker: ContentPickersEnum
}

export const AddNewEntity: FC<IProps> = ({ entity, picker }) => {
  const { t } = useTranslation()

  const toast = useToast()
  const { close } = useDialog()
  const { setNewIcon, handleCreateBoard, handleCreateFolder, newIcon } =
    useAddNewEntityActions({ picker })

  const { minNameLength, maxNameLength } = useUpdatesTranslations()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: '',
    },
  })

  const nameFieldValue = useWatch({
    control,
    name: 'name',
  })

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleSubmitClick = useCallback(() => {
    setValue(
      'name',
      inputRef.current?.value.trim() ||
        t(
          entity === EntityDialogEnum.Board
            ? 'default_title_my_board'
            : 'default_title_my_folder',
        ),
    )

    inputRef.current?.blur()
  }, [setValue, t, entity])

  const onSubmit = async ({ name }: { name: string }) => {
    const isBoardEntity = entity === EntityDialogEnum.Board
    const createEntity = isBoardEntity ? handleCreateBoard : handleCreateFolder

    createEntity(name)

    toast(
      t(isBoardEntity ? 'toast_board_created' : 'toast_folder_created'),
      true,
      0,
      false,
    )

    close()
  }

  const { changeEmojiTooltip } = useToolbarTranslations()

  return (
    <div className={styles.Root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={styles.Title}>
          <Trans
            i18nKey={
              entity === EntityDialogEnum.Board
                ? 'destination_picker_new_board'
                : 'destination_picker_new_folder'
            }
          />
        </p>

        <div className={styles.Body}>
          <ContentIcon
            className={styles.Icon}
            icon={newIcon}
            onChange={setNewIcon}
            tooltip={changeEmojiTooltip()}
            tooltipPosition={TooltipPositions.BOTTOM}
            closeOnOutsideClick
          />

          <div className={styles.Input}>
            <InputField
              autoFocus
              maxLength={30}
              placeholder={t(
                entity === EntityDialogEnum.Board
                  ? 'destination_picker_enter_board_name'
                  : 'destination_picker_enter_folder_name',
              )}
              errorMessage={errors?.name?.message}
              {...register('name', {
                required: minNameLength(1),
                minLength: {
                  value: 1,
                  message: minNameLength(1),
                },
                maxLength: {
                  value: 30,
                  message: maxNameLength(30),
                },
              })}
              value={nameFieldValue}
              onChange={(e) => {
                setValue('name', e.target.value)
              }}
              ref={inputRef}
            />
          </div>
        </div>

        <ButtonNew
          type={'submit'}
          fullWidth
          size={'large'}
          onClick={handleSubmitClick}
        >
          <Trans
            i18nKey={
              entity === EntityDialogEnum.Board
                ? 'create_board'
                : 'create_folder'
            }
          />
        </ButtonNew>
      </form>
    </div>
  )
}
