import { useState } from 'react'
import { useDialog } from 'boards-web-ui'

import { usePdfPicker } from '@features/pickers/hooks/usePdfPicker'
import AddPdf from '@features/nodeEditor/components/AddPdf'
import { Node, NodeType } from 'app/models'
import { getFileName } from 'utils/fileHelper'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'
import useAddContentTranslations from '../../toolbar/hooks/useAddContentTranslations'

import EditLinkPdf from '../components/EditLinkPdf'
import { IUseAddPDF } from '../models/IUseAddNode'

const MAX_UPLOAD_FILE_SIZE = 40000

const useAddPdf: IUseAddPDF = (save, isPageEditor, meta) => {
  const { open, close } = useDialog()
  const { bigFile } = useAddContentTranslations()
  const [updatedPdf, setUpdatedPdf] = useState({ text: '', title: '' })
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const handlePdfSave = async ({
    title,
    text,
  }: {
    title: string
    text: string
  }) => {
    if (meta?.isEditMode) {
      setUpdatedPdf({ title, text })
      save({ title, text, type: NodeType.PDF, thumbnail: undefined })

      if (meta.onContentReplaceEvent) {
        meta.onContentReplaceEvent()
      }

      return
    }

    if (!isPageEditor) {
      editPdf({ title, text })
      return
    }

    save(
      {
        title,
        text,
        type: NodeType.PDF,
      },
      {},
    )
  }

  const editPdf = ({ title, text }: { title: string; text: string }) => {
    open(
      <EditLinkPdf initialText={text} initialTitle={title} onSave={save} />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  const addPdfFile = async (file: File) => {
    if (!isApiAvailableOrNotify()) return

    const size = Math.round(file.size / 1024)
    if (size >= MAX_UPLOAD_FILE_SIZE) {
      // eslint-disable-next-line
      alert(bigFile())
      return
    }

    open(
      <AddPdf
        file={file}
        onUpload={(url: string) => {
          close()
          handlePdfSave({
            title: getFileName(file.name),
            text: url,
          })
        }}
      />,
      {
        fullScreen: true,
      },
    )
  }

  const addPdfNode = async (node: Node & { type: NodeType.PDF }) => {
    await handlePdfSave({ title: node.title, text: node.text })
  }

  const addPdf = usePdfPicker({
    isNew: !meta?.isEditMode,
    onFilePicked: addPdfFile,
    onNodePicked: addPdfNode,
  })

  return { addPdf, updatedPdf }
}

export default useAddPdf
