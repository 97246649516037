import { Trans, useTranslation } from 'react-i18next'
import { AnimatedDialog, AudioPlayer, theme, useDialog } from 'boards-web-ui'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'
import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'

import InputModalTitle from 'elements/InputModalTitle'
import { Node } from '../../../models'
import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

import { INodeProps } from '../models/IUseNodeCreatorAPI'

import styles from './EditAudio.module.css'

interface Props {
  node: Node
  onSave: (node: INodeProps) => void
}

const EditAudio = ({ node, onSave }: Props) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const { title, hasChanges, setTitle } = useNodeAPI(
    node.title || t('label_my_recording'),
    '',
  )

  const handleSave = () => {
    onSave({ ...node, title })
    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        onEscape: handleClose,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <InputModalTitle
            className={styles.InputMargin}
            value={title}
            placeholder={t('add_an_update_title_optional')}
            onChange={setTitle}
          />
          <div className={styles.AudioPlayerWrapper}>
            <AudioPlayer duration={node.duration} src={node.text} />
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditAudio
