import { FC, PropsWithChildren, ReactNode } from 'react'
import { DialogProvider } from 'boards-web-ui'

import { useIsAcademyEnabled } from '@features/academy/hooks'

type Props = PropsWithChildren<{
  fallback: ReactNode
}>

export const AcademyIsEnabledGuard: FC<Props> = ({
  children = null,
  fallback = null,
}) => {
  const { isAcademyEnabled } = useIsAcademyEnabled()

  return (
    <DialogProvider>{isAcademyEnabled ? children : fallback}</DialogProvider>
  )
}
