import { ButtonIcon, InputTextarea, SmileIcon } from 'boards-web-ui'
import React, {
  ChangeEvent,
  SyntheticEvent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import EmojiPicker from '../../../../components/EmojiPicker'

import styles from './TextAreaFieldWithEmojiPicker.module.css'

interface Props {
  label: string
  placeholder: string
  value: string
  onChange: (t: string) => void
  index?: number
}

export const TextAreaFieldWithEmojiPicker = ({
  label,
  placeholder,
  value,
  onChange,
  index,
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const pickerButtonRef = useRef<HTMLDivElement>(null)
  const [cursorPosition, setCursorPosition] = useState(0)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const onEmojiClick = (emoji: string) => {
    const title = value || ''

    const position = cursorPosition || inputRef?.current?.selectionStart || 0
    const newVal = title.slice(0, position) + emoji + title.slice(position)
    setCursorPosition(position + emoji.length)
    onChange(newVal)
  }

  function adjustHeight() {
    if (!inputRef.current) {
      return
    }

    inputRef.current.style.height = 'inherit'
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
  }

  useLayoutEffect(adjustHeight, [])

  const handleFocus = (e: SyntheticEvent) => {
    let range
    if (document.createRange) {
      // Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange() // Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(e.currentTarget) // Select the entire contents of the element with the range
      range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
    }
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }

    adjustHeight()
  }

  const onClickOutsideEmojiPicker = () => {
    setCursorPosition(0)
    setShowEmojiPicker(false)
  }

  return (
    <div className={styles.Wrapper}>
      <InputTextarea
        className={styles.TextArea}
        ref={inputRef}
        type={'text'}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        tabIndex={index}
      />
      <div
        ref={pickerButtonRef}
        className={clsx(
          styles.EmojiPickerWrapper,
          showEmojiPicker && styles.ShowPicker,
        )}
      >
        <ButtonIcon
          round
          size={38}
          className={styles.PickerButton}
          onClick={() => setShowEmojiPicker(true)}
        >
          <SmileIcon />
        </ButtonIcon>

        {showEmojiPicker && (
          <EmojiPicker
            standalone
            onClickEmoji={onEmojiClick}
            onClickOutside={onClickOutsideEmojiPicker}
            side="right"
            position="top"
          />
        )}
      </div>
    </div>
  )
}
