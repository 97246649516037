import useRemoteConfig from '@hooks/useRemoteConfig'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import useSidebarEvents from '../../analytics/useSidebarEvents'
import { REMOTE_CONFIG_KEYS } from '../../../models'

const MONETIZED_COUNTRIES = ['CHL', 'CAN', 'COL', 'AUS', 'BRA', 'PER', 'GBR']

const useSidebarBannerAPI = () => {
  const { dismissBannerEvent } = useSidebarEvents()
  const { monetizationBannerEnabled, geoMonetizationBannerFeatureFlag } =
    useFeatureFlags()
  const {
    profile: { uiState, plan, store },
    loaded,
    hideMonetizationBanner,
  } = useProfileInformation()

  const config = useRemoteConfig(
    REMOTE_CONFIG_KEYS.TEST_COUNTRIES_WITH_SINGLE_TIER,
  )

  const singleTierCountries = config[0].split(',')

  const isCountrySingleTier =
    singleTierCountries.indexOf(store?.countryCode) !== -1

  const closeBanner = () => {
    hideMonetizationBanner()
    dismissBannerEvent()
  }

  const isCountryMonetized = MONETIZED_COUNTRIES.includes(
    store?.countryCode || '',
  )

  const monetizationRule =
    monetizationBannerEnabled &&
    !plan.monetized &&
    !uiState.monetizationBannerShown

  const geoMonetizationRule =
    geoMonetizationBannerFeatureFlag &&
    isCountryMonetized &&
    !plan.monetized &&
    !uiState.monetizationBannerShown

  return {
    showBanner: loaded && (monetizationRule || geoMonetizationRule),
    closeBanner,
    isCountrySingleTier,
  } as const
}

export default useSidebarBannerAPI
