import { useCallback, useEffect } from 'react'
import { AnimatedDialog, theme, useDialog } from 'boards-web-ui'

import useUploadFirebase, {
  FirebaseUploadFileType,
} from '@hooks/useUploadFirebase'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'

import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'
import UploadProgressBar from '@features/nodeEditor/components/UploadProgressBar'
import useConfirmDialog from '@hooks/useConfirmDialog'
import { useTranslation } from 'react-i18next'

import styles from './AddPdf.module.css'

interface Props {
  file: File
  onUpload: (url: string) => void
}

const AddPdf = ({ file, onUpload }: Props) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const confirmDialog = useConfirmDialog()
  const {
    error,
    progress,
    uploadFile,
    cancelUpload,
    pauseUpload,
    resumeUpload,
  } = useUploadFirebase()

  const startUpload = useCallback(async () => {
    const { url } = await uploadFile(file, FirebaseUploadFileType.PDF)
    onUpload(url)
  }, [file, onUpload, uploadFile])

  useEffect(() => {
    if (!file) return

    startUpload()
  }, [file, startUpload])

  const handleClose = () => {
    pauseUpload()

    confirmDialog({
      title: t('cancel_upload_dialog_title'),
      cancelText: t('action_no'),
      confirmText: t('action_yes'),
      onCancel: resumeUpload,
      onConfirm: () => {
        cancelUpload()
        close()
      },
    })
  }
  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        onEscape: handleClose,
        onOverlayClick: handleClose,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
        >
          <div className={styles.Root}>
            <UploadProgressBar
              progress={progress}
              isError={error}
              retry={startUpload}
            />
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default AddPdf
