import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'boards-web-ui'
import { getBoardDataByMessageId } from '../../../_firebase'
import { EDITOR, QUERY_PARAMS } from '../../constants/routes'

export const MessagesController = () => {
  const navigate = useNavigate()
  const { messageId } = useParams()

  useEffect(() => {
    if (!messageId) {
      return
    }

    const redirectByMessageData = async () => {
      let data
      try {
        data = await getBoardDataByMessageId(messageId)
      } catch {
        navigate(EDITOR)
        return
      }
      if (!data?.ownerId || !data?.boardId) {
        navigate(EDITOR)
        return
      }
      const redirectPath = `/${data.ownerId}-${data.boardId}`
      const redirectQuery = `?${QUERY_PARAMS.showMessage}=true&${QUERY_PARAMS.message}=${messageId}`
      navigate(`${redirectPath}${redirectQuery}`)
    }
    // noinspection JSIgnoredPromiseFromCall
    redirectByMessageData()
  }, [messageId, navigate])

  return (
    <Spinner size="large" spinning>
      <span />
    </Spinner>
  )
}
