import { PropsWithChildren, ReactElement } from 'react'
import { Trans } from 'react-i18next'
import useInsightsEvents, {
  RelateTooltip,
} from '@features/analytics/useInsightsEvents'

import { Tooltip, TooltipContent } from 'boards-web-ui'
import { formatNumber } from '../helper/numbers'

import styles from './ContentItem.module.css'

type Props = PropsWithChildren<{
  text: string | ReactElement
  count: number
  highlightText?: boolean
}>

const ContentItem = ({
  text,
  count,
  highlightText = true,
  children,
}: Props) => {
  const { tooltipHovered } = useInsightsEvents()
  return (
    <div className={styles.Root}>
      <div className={styles.CoverWrapper}>{children}</div>
      <div className={styles.TextWrapper}>
        <div className={highlightText ? styles.HighlightText : styles.Text}>
          {text}
        </div>
      </div>
      <div className={styles.NumberWrapper}>
        <Tooltip
          onHover={() => tooltipHovered(RelateTooltip.TOP_CONTENT_INTERACTIONS)}
          data={
            <TooltipContent>
              <div className={styles.InteractionsText}>
                <Trans i18nKey="insights_tab_interactions" values={{ count }} />
              </div>
            </TooltipContent>
          }
        >
          <div className={styles.Number}>{formatNumber(count)}</div>
        </Tooltip>
      </div>
    </div>
  )
}

export default ContentItem
