import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  alignContent?: string
  columnGap?: number
  rowGap?: number
  width?: string
  height?: string
  minWidth?: string
  alignSelf?: string
}>

const Grid = ({
  flexDirection = 'row',
  justifyContent = 'center',
  alignItems = 'center',
  alignContent,
  columnGap = 8,
  rowGap = 0,
  width = 'auto',
  minWidth = '0',
  height,
  children,
  alignSelf,
}: Props) => {
  const style = {
    display: 'flex',
    flexDirection,
    justifyContent,
    alignContent,
    alignItems,
    rowGap,
    columnGap,
    width,
    height,
    minWidth,
    alignSelf,
  }

  return <div style={style as React.CSSProperties}>{children}</div>
}

export default Grid
