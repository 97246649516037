import { getFolderId, getNodeId } from '@helpers/NodeIdGenerator'
import { AddPageData } from '@features/toolbar/models'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { UserContentSettings } from '@models/UserContentSettings'
import { UserAcademyType } from '@models/UserAcademy'
import * as FolderManagement from '../FolderManagement'
import { AppState } from '../../app/models/appState'
import { Folder, Node } from '../../app/models'
import { undoStackWithContentAdded } from '../general/undoStackWithContentAdded'
import { FormIntegrationStatusData } from '../../_firebase'
import { initialState } from '../initialState'

// TODO https://heybliss.atlassian.net/browse/BLISS-10770
// refactor those reducers

export function addPageReducer(
  state: AppState,
  boardId: string,
  fid: string,
  key: string,
  properties: AddPageData,
  location?: 'start',
): AppState {
  const nodeId = getFolderId(0, key)
  const node = {
    id: nodeId,
    type: 'page',
    ...properties,
  } as unknown as Node

  const folder = {
    id: nodeId,
    icon: properties.icon,
    isPage: true,
    title: '',
    content: [],
    ...properties,
  } as unknown as Folder

  let folders = FolderManagement.addNodes({
    folders: state.folders,
    boardId,
    fid,
    nodes: [node],
    ...(location === 'start' && { beforeNodeIndex: 0 }),
  })
  folders = FolderManagement.addFolder(folders, boardId, folder)

  return {
    ...state,
    folders,
    app: {
      ...state.app,
      currentFolderId: nodeId,
    },
  }
}

export function addContentReducer(
  state: AppState,
  boardId: string,
  folder?: string,
  properties?: INodeProps,
  key?: string,
): AppState {
  if (!folder || !properties || !key) {
    return { ...state }
  }
  const nodeId = getNodeId(0, key)

  const node = {
    ...properties,
    id: nodeId,
  } as unknown as Node

  return {
    ...state,
    folders: FolderManagement.addNodes({
      folders: state.folders,
      boardId,
      fid: folder,
      nodes: [node],
    }),
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, boardId),
      currentFolderId: folder,
    },
  }
}

export function deleteNodesReducer(
  state: AppState,
  boardId: string,
  folderId?: string,
  nodes?: string[],
): AppState {
  return {
    ...state,
    folders: FolderManagement.deleteNodes(
      state.folders,
      boardId,
      folderId,
      nodes,
    ),
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, boardId),
    },
  }
}

export function setFormIntegrationReducer(
  state: AppState,
  boardId: string,
  folderId: string,
  nodeId: string,
  integrationState?: FormIntegrationStatusData,
): AppState {
  return {
    ...state,
    ...FolderManagement.updateFoldersFormIntegrationState(
      state,
      boardId,
      folderId,
      nodeId,
      integrationState,
    ),
  }
}

export function moveNodesReducer(
  state: AppState,
  board: string,
  folder?: string,
  nodeIds?: string[],
  fromFolder?: string,
  beforeNodeId?: string,
): AppState {
  if (!folder || !fromFolder || !nodeIds) {
    return { ...state }
  }

  const nodes = FolderManagement.getNodes(
    state.folders,
    board,
    fromFolder,
    nodeIds,
  )
  let folders = FolderManagement.deleteNodes(
    state.folders,
    board,
    fromFolder,
    nodeIds,
  )
  folders = FolderManagement.addNodes({
    folders,
    boardId: board,
    fid: folder,
    nodes,
    beforeNodeId,
  })

  return {
    ...state,
    folders,
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, board),
      bulk: initialState.app.bulk,
    },
  }
}

export function setUserContentSettingsReducer(
  state: AppState,
  settings: UserContentSettings,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      content: {
        ...state.app.content,
        ...settings,
      },
    },
  }
}

export function setUserAcademyReducer(
  state: AppState,
  academy: UserAcademyType,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      academy,
    },
  }
}
