import React from 'react'

import { AcademyIcon, HelpIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { Trans } from 'react-i18next'
import { useIsAcademyEnabled } from '@features/academy'
import { useProfileEvents } from '../../analytics/useProfileEvents'
import { useProfileTranslations } from '../../profile/hooks/useProfileTranslations'

import links from '../../../constants/links'

import styles from './SideBarFooter.module.css'
import * as ROUTES from '../../../constants/routes'

type Props = {
  className?: string
}
const SideBarFooter: React.FC<Props> = ({ className }) => {
  const { onHowToUseClick, appAcademyTabClickEvent } = useProfileEvents()
  const { howToUse } = useProfileTranslations()

  const { isAcademyEnabled } = useIsAcademyEnabled()

  const { icon, text, href, handleClick } = {
    icon: isAcademyEnabled ? <AcademyIcon /> : <HelpIcon />,
    text: isAcademyEnabled ? <Trans i18nKey="academy_title" /> : howToUse(),
    href: isAcademyEnabled
      ? `${window.origin}/${ROUTES.ACADEMY}`
      : links.helpUrl,
    handleClick: isAcademyEnabled ? appAcademyTabClickEvent : onHowToUseClick,
  }

  return (
    <div className={className}>
      <a
        className={styles.Item}
        href={href}
        target="_blank"
        onClick={handleClick}
        rel="noreferrer"
      >
        <IconWrapper className={styles.Icon} color="gray">
          {icon}
        </IconWrapper>

        {text}
      </a>
    </div>
  )
}

export default React.memo(SideBarFooter)
