import React from 'react'
import { Trans } from 'react-i18next'
import { AttachIcon, ButtonIcon, Tooltip, TooltipContent } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useProfileName } from '@features/profile/hooks/useProfileName'
import { useComposeModal } from '@features/updates/hooks/useComposeModal'
import { Avatar } from '@ui/components/Avatar'
import useRouterParams from '@hooks/useRouterParams'
import { useAttachContentToMessagesEvents } from '@features/analytics/useAttachContentToMessagesEvents'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'

import styles from './MessageComposeTrigger.module.css'

export const MessageComposeTrigger = () => {
  const { name } = useProfileName()
  const { boardId } = useRouterParams()
  const { openComposeModal } = useComposeModal()
  const { messageAttachClickEvent } = useAttachContentToMessagesEvents()
  const { appStartWritingClick } = useUpdatesEvents()

  return (
    <div className={styles.Root}>
      <Avatar size={46} className={styles.Avatar}>
        {name.trim().charAt(0)}
      </Avatar>
      <div
        className={styles.PostMessageInput}
        onClick={() => {
          appStartWritingClick({ boardId })
          openComposeModal({ openContentPicker: false })
        }}
      >
        <span className={styles.PostMessageInputText}>
          <Trans i18nKey="message_input_text" />
        </span>

        <Tooltip
          className={styles.AttachButton}
          as="div"
          data={
            <TooltipContent className={styles.AttachTooltipContent}>
              <Trans i18nKey={'message_attach_content_button'} />
            </TooltipContent>
          }
        >
          <ButtonIcon
            size={38}
            round
            className={styles.AttachIconButton}
            onClick={(e) => {
              e.stopPropagation()
              openComposeModal({ openContentPicker: true })
              messageAttachClickEvent({ boardId })
            }}
          >
            <IconWrapper size={20} className={styles.AttachIcon}>
              <AttachIcon />
            </IconWrapper>
          </ButtonIcon>
        </Tooltip>
      </div>
    </div>
  )
}
