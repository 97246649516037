import React, { HTMLAttributes } from 'react'
import { Menu } from 'boards-web-ui'

import styles from './ModalMenu.module.css'

type SelectListProps<T> = {
  items: T[]
  title?: string
  renderItem: (item: T, index: number) => React.ReactNode
} & HTMLAttributes<HTMLDivElement>

const ModalMenu = <T,>({
  items,
  title,
  renderItem,
  ...rest
}: SelectListProps<T>) => (
  <div className={styles.Root} {...rest}>
    {title && <h3 className={styles.Title}>{title}</h3>}
    <Menu className={styles.List}>
      {items.map((item, index) => renderItem(item, index))}
    </Menu>
  </div>
)

export default ModalMenu
