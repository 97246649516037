import React from 'react'

import { ButtonNew, SendMessageIcon, FeedbackIcon } from 'boards-web-ui'
import useBoard from '@hooks/useBoard'
import { useUpdatesTools } from './hooks/useUpdatesTools'
import { useUpdatesEvents } from '../analytics/useUpdatesEvents'
import { useUpdatesTranslations } from './hooks/useUpdatesTranslations'
import MessagesTooltip from '../../../ui/components/MessagesTooltip'
import { useProfileInformation } from '../profile/hooks/useProfileInformation'
import useUserFeatures, { Features } from '../../../hooks/useUserFeatures'

import styles from './UpdatesTools.module.css'

export const UpdatesTools = () => {
  const { postUpdate } = useUpdatesTranslations()
  const { isOpen, isUnAvailable, disabled, toggle } = useUpdatesTools()
  const { openUpdatesClick } = useUpdatesEvents()
  const { hideMessagesTooltip, profile } = useProfileInformation()
  const { tryFree } = useUserFeatures(Features.messages)
  const { isEditor, isOwnBoard } = useBoard()

  if (isUnAvailable) {
    return null
  }

  const onToggle = () => {
    if (!isOpen) {
      openUpdatesClick()
    }
    toggle()
  }

  return (
    <div className={styles.ParentContainer}>
      {tryFree && !profile.uiState.freemiumMessagesHintShown && (
        <MessagesTooltip onClose={hideMessagesTooltip} />
      )}
      <ButtonNew
        color="secondary"
        onClick={onToggle}
        disabled={disabled}
        isActive={isOpen}
        startIcon={
          <div className={styles.IconContainer}>
            {isEditor || isOwnBoard ? (
              <SendMessageIcon className={styles.Icon} />
            ) : (
              <FeedbackIcon className={styles.Icon} />
            )}
          </div>
        }
      >
        {postUpdate()}
      </ButtonNew>
    </div>
  )
}
