import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'
import { useCallback } from 'react'

export const useAcademyBannerEvents = () => {
  const { trigger } = useLogEvents()

  const showBannerEvent = useCallback(
    () => trigger(EventCategories.APP_BANNER_ACADEMY_SHOW),
    [trigger],
  )

  const dismissBannerEvent = useCallback(
    () => trigger(EventCategories.APP_BANNER_ACADEMY_DISMISS),
    [trigger],
  )

  const clickBannerEvent = useCallback(
    () => trigger(EventCategories.APP_BANNER_ACADEMY_CLICK),
    [trigger],
  )

  return {
    showBannerEvent,
    dismissBannerEvent,
    clickBannerEvent,
  }
}
