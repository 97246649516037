import React, { useEffect } from 'react'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import useFirebaseListeners from '../../../../../_firebase/hooks/useFirebaseListeners'

const PageResetPassword: React.FC = () => {
  const { startClientConfigListener } = useFirebaseListeners()

  useEffect(() => {
    startClientConfigListener()
  }, [startClientConfigListener])

  return <ResetPasswordForm />
}

export default PageResetPassword
