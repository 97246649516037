import { theme, useDialog } from 'boards-web-ui'

import { Node } from '../../../models'
import EditLink from '../components/EditLink'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditLink: IUseSaveNode = (save) => {
  const { open } = useDialog()

  const editLink = (node: Node) => {
    open(
      <EditLink
        initialText={node.text || ''}
        initialTitle={node.title || ''}
        onSave={(n) => save(node.id, n)}
        edit
      />,
      {
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
      },
    )
  }

  return editLink
}

export default useEditLink
