import { useTranslation } from 'react-i18next'
import { theme, useDialog, useUpload } from 'boards-web-ui'

import { Node, NodeType } from 'app/models'
import useMediaPickerDialog from '@features/pickers/hooks/useMediaPickerDialog'
import { SourcePickerSingleSelectionModal } from '@features/pickers/sourcePicker/SourcePickerSingleSelectionModal'
import useMediaPickerEvents, {
  PickerActionTypeEnum,
  PickerClickValueEnum,
} from '@features/analytics/useMediaPickerEvents'
import useRouterParams from '@hooks/useRouterParams'

export type FilePickerHandler<Multiple extends boolean> = (
  file: Multiple extends false ? File : FileList,
) => void

interface UseFilePickerConfig<T extends Node, Multiple extends boolean> {
  acceptTypes: string
  enabledNodeTypes: NodeType[]
  fileType: T['type']
  isNew: boolean
  multiple: Multiple
  nodeType: NodeType
  onFilePicked: FilePickerHandler<Multiple>
  onNodePicked?: (node: T) => void
}
const useFileOrNodePicker = <T extends Node, Multiple extends boolean>({
  acceptTypes,
  enabledNodeTypes,
  fileType,
  isNew,
  multiple,
  nodeType,
  onFilePicked,
  onNodePicked,
}: UseFilePickerConfig<T, Multiple>) => {
  const { open, close } = useDialog()
  const { t } = useTranslation()
  const { boardId } = useRouterParams()
  const {
    mediaSourcePickerLoadEvent,
    mediaSourcePickerClickEvent,
    mediaPickerClickEvent,
    sourcePickerChooseBoardEvent,
  } = useMediaPickerEvents()

  const dialogCategory: Partial<Record<NodeType, string>> = {
    [NodeType.IMAGE]: t('destination_picker_copy_image'),
    [NodeType.VIDEO_STREAMING]: t('destination_picker_copy_video'),
    [NodeType.PDF]: t('destination_picker_copy_pdf'),
  }

  const dialogPickerTitle: Partial<Record<NodeType, string>> = {
    [NodeType.IMAGE]: t('image_selector_from_pc_image'),
    [NodeType.VIDEO_STREAMING]: t('image_selector_from_pc_video'),
    [NodeType.PDF]: t('image_selector_from_pc_pdf'),
  }

  const handleNodePicked = (node: Node) => {
    if (onNodePicked && node.type === fileType) {
      close()
      onNodePicked(node as T)

      mediaPickerClickEvent({
        keyId: node.id,
        type: isNew ? PickerActionTypeEnum.ADD : PickerActionTypeEnum.REPLACE,
      })
    }
  }

  const uploadFile = useUpload(onFilePicked, {
    accept: acceptTypes,
    multiple,
  })

  const handleOnGalleryClick = () => {
    uploadFile()

    mediaSourcePickerClickEvent({
      clickValue: PickerClickValueEnum.GALLERY,
    })
  }

  const handleOnSelectBoard = (newBoardId: string) =>
    sourcePickerChooseBoardEvent({ boardId: newBoardId })

  const handleOnFromBoardsClick = () => {
    mediaSourcePickerClickEvent({
      clickValue: PickerClickValueEnum.BOARD,
    })

    open(
      <SourcePickerSingleSelectionModal
        dialogCategory={dialogCategory[fileType] || ''}
        enabledNodeTypes={enabledNodeTypes}
        onClose={close}
        action={handleNodePicked}
        onSelectBoard={handleOnSelectBoard}
      />,
      {
        fullScreen: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        withFullScreenDialogContainer: false,
        withoutAnimation: true,
      },
    )
  }

  const openMediaPickerDialog = useMediaPickerDialog({
    title: dialogPickerTitle[fileType] || '',
    onFromBoardsClick: handleOnFromBoardsClick,
    onGalleryClick: handleOnGalleryClick,
  })

  const openFileOrNodePicker = () => {
    if (onNodePicked) {
      openMediaPickerDialog()

      mediaSourcePickerLoadEvent({
        boardId,
        contentType: nodeType,
        type: isNew ? PickerActionTypeEnum.ADD : PickerActionTypeEnum.REPLACE,
      })

      return
    }

    uploadFile()
  }

  return openFileOrNodePicker
}

export default useFileOrNodePicker
