import { TPageNodeProfile, useDialog } from 'boards-web-ui'

import EditPageProfile from '@features/nodeEditor/components/EditPageProfile'
import { IUseSaveNode } from '@features/nodeEditor/models/IUseEditNode'

import useFirebaseImageUploader from '../../../../_firebase/useFirebaseImageUploader'
import { Node } from '../../../models'

const useEditProfile: IUseSaveNode = (save) => {
  const { open, close } = useDialog()
  const firebaseImageUploadProvider = useFirebaseImageUploader()

  const editProfile = (initialState: Node) => {
    open(
      <EditPageProfile
        isNew={false}
        initialState={initialState as TPageNodeProfile}
        imageUploadProvider={firebaseImageUploadProvider}
        onSave={(data) => {
          save(initialState.id, data)
          close()
        }}
        onCancel={close}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return editProfile
}

export default useEditProfile
