import React from 'react'
import { Trans } from 'react-i18next'
import {
  AcademyIcon,
  ButtonIcon,
  CloseSmallIcon,
  NextIcon,
  PlayBtnDarkIcon,
} from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { Link } from 'react-router-dom'
import styles from './AcademyBanner.module.css'
import * as ROUTES from '../../../constants/routes'

interface Props {
  onCloseBanner: () => void
  onOpenAcademyPage: () => void
}
export const AcademyBanner = ({ onCloseBanner, onOpenAcademyPage }: Props) => (
  <div className={styles.Root}>
    <div className={styles.Header}>
      <ButtonIcon size={24} variant="gray" round onClick={onCloseBanner}>
        <IconWrapper size={16}>
          <CloseSmallIcon />
        </IconWrapper>
      </ButtonIcon>
    </div>

    <div className={styles.Content}>
      <div className={styles.Message}>
        <div className={styles.Title}>
          <IconWrapper className={styles.Icon} color="gray">
            <AcademyIcon />
          </IconWrapper>

          <Trans i18nKey="academy_title" />
        </div>

        <div className={styles.Text}>
          <Trans i18nKey="academy_banner_body" />
        </div>

        <Link
          to={ROUTES.ACADEMY}
          onClick={onOpenAcademyPage}
          className={styles.Forward}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans i18nKey="academy_banner_button" />
          <IconWrapper>
            <NextIcon />
          </IconWrapper>
        </Link>
      </div>

      <div className={styles.Cover}>
        <img
          className={styles.Banner}
          src="/features/layout/academyBanner.png"
          alt="Boards"
        />

        <div className={styles.PlayButton}>
          <IconWrapper size={30}>
            <PlayBtnDarkIcon />
          </IconWrapper>
        </div>
      </div>
    </div>
  </div>
)
