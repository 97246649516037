import { useTranslation } from 'react-i18next'
import { AudioPlayer } from 'boards-web-ui'
import useNodeEvents from '@features/analytics/useNodeEvents'
import useCopyShortcuts from '@hooks/useCopyShortcuts'

import { Folder, Node } from '../../../models'
import PreviewModal from './PreviewModal'

import styles from './PreviewAudio.module.css'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  isDisabledPreviewNavigation?: boolean
  canCopyContentOfSharedFolder?: boolean
  isReadOnly?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const PreviewAudio = ({
  node,
  prev,
  next,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  isDisabledPreviewNavigation,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation()
  const { audioPlayEvent } = useNodeEvents()
  const { copyNodeShortcut } = useCopyShortcuts()

  const handleOnPlayClick = () => audioPlayEvent(node)

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyNodeShortcut(node)}
      prev={prev}
      next={next}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      isDisabledPreviewNavigation={isDisabledPreviewNavigation}
      isReadOnly={isReadOnly}
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <AudioPlayer
          duration={node.duration}
          src={node.text}
          onPlayClick={handleOnPlayClick}
        />
      </div>
    </PreviewModal>
  )
}

export default PreviewAudio
