import { hash28 } from '@helpers/Base62'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { MessageProperties, Node, UpdatesMessage } from '../../../app/models'
import { transformNodeFolder } from './NodeFolderTransformer'

export const transformUpdateMessage = (doc: QueryDocumentSnapshot) => {
  const rawMessage = doc.data() as UpdatesMessage
  return {
    ...rawMessage,
    properties: transformMessageProperties(rawMessage.properties),
    processedContent: transformMessageContent(rawMessage),
  }
}

const transformMessageProperties = (
  properties: MessageProperties,
): MessageProperties => {
  return {
    ...properties,
    text: properties.text?.trim(),
  }
}

const transformMessageContent = (message: UpdatesMessage) => {
  if (!message.content || message.content.ids.length < 1) {
    return null
  }
  const attachedNodeId = message.content.ids[0]
  const attachedFolder = message.content.properties[attachedNodeId]?.folder

  const transformedNode = transformAttachmentToNode(message)
  return {
    folderId: attachedFolder,
    node: transformedNode,
    isDeletedOrMoved: attachedFolder == null,
  }
}

const transformAttachmentToNode = (message: UpdatesMessage): Node | null => {
  const result = message.content?.ids.map((id: string) => {
    const generateNodes = message.content?.properties[id]
    if (!generateNodes) {
      return null
    }
    const propertyId = hash28(generateNodes.text || '')

    const properties =
      message.urls?.properties && message.urls.properties[propertyId]

    return transformNodeFolder(id, generateNodes, properties as DocumentData)
  })

  if (!result || result.length === 0 || result[0] === null) {
    return null
  }

  return result[0] as Node
}
