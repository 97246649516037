import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useCreateMessage } from '@features/updates/hooks/useCreateMessage'
import useIsApiAvailableOrNotify from '@hooks/useIsApiAvailableOrNotify'
import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'
import useRouterParams from '@hooks/useRouterParams'
import { useProfileName } from '@features/profile/hooks/useProfileName'
import { updatesActions } from '../../../../actions'
import { Node as NodeModel } from '../../../models'

type PostMessageArgs = {
  title?: string
  text: string
  attachment: NodeModel | undefined
  keyId: string
}

const usePostMessage = () => {
  const { count } = useCreateMessage()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const dispatch = useDispatch()
  const { newMessageSend } = useUpdatesEvents()
  const { boardId } = useRouterParams()
  const { name } = useProfileName()

  const postMessage = useCallback(
    ({ title = '', text, attachment, keyId }: PostMessageArgs) => {
      if (!isApiAvailableOrNotify() || !boardId) return

      const getContentToApiCall = (): string[] => {
        if (!attachment) {
          return []
        }

        return [attachment.id]
      }

      dispatch(
        updatesActions.postUpdatesMessage({
          board: boardId,
          key: keyId,
          content: getContentToApiCall(),
          properties: {
            text,
            title,
          },
          senderName: name,
        }),
      )

      newMessageSend({
        boardId,
        value: keyId,
        title,
        body: text,
        membersCount: count,
        isFileAttached: Boolean(attachment),
        fileType: attachment ? [attachment.type] : undefined,
      })
    },
    [boardId, count, dispatch, isApiAvailableOrNotify, name, newMessageSend],
  )

  return { postMessage }
}

export default usePostMessage
