import React from 'react'
import { CampaignBanners } from '@features/campaign'
import MonetizationBanner from '@features/sidebar/components/MonetizationBanner'
import MobileBanner from '@features/sidebar/components/MobileBanner'
import ChromeExtensionBanner from '@features/sidebar/components/ChromeExtensionBanner'
import ReverseTrialCountdownBanner from '@features/reverseTrial/components/countdownBanner/ReverseTrialCountdownBanner'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useSidebarBannerAPI from '@features/sidebar/hooks/useSidebarBannerAPI'
import useCampaign from '@features/campaign/hooks/useCampaign'
import useSidebarBanners from '@features/sidebar/hooks/useSidebarBanners'
import { useAcademyBanner } from '@features/sidebar/hooks/useAcademyBanner'
import { AcademyBanner } from '@features/sidebar/components/AcademyBanner'

const BANNERS_LIMIT = 2

export const useSideBarBanners = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void
}) => {
  const { loaded, uiState, hasAndroidOrIOS } = useSidebarBanners()
  const {
    shouldShowAcademyBanner,
    onCloseBanner: onCloseAcademyBanner,
    onOpenAcademyPage,
  } = useAcademyBanner()

  const {
    trialExpirationDate,
    handleUserCloseCountdownBanner,
    shouldShowCountdownBanner,
  } = useReverseTrialContext()

  const {
    showBanner: showMonetizationBanner,
    closeBanner,
    isCountrySingleTier,
  } = useSidebarBannerAPI()

  const { banners, onCloseBanner } = useCampaign()

  const showChromeExtensionBanner =
    loaded &&
    !(
      localStorage.getItem('boards-chrome-extension-is-installed') === '1' ||
      uiState.chromeExtensionBannerShown
    )

  const showMobileBanner =
    loaded && !(hasAndroidOrIOS || uiState.installMobileAppHintShown)

  const showReverseTrialCountdownBanner =
    shouldShowCountdownBanner && trialExpirationDate instanceof Date

  const commonBanners = showReverseTrialCountdownBanner
    ? []
    : [
        shouldShowAcademyBanner
          ? {
              id: 'academyBanner',
              element: (
                <AcademyBanner
                  onCloseBanner={onCloseAcademyBanner}
                  onOpenAcademyPage={onOpenAcademyPage}
                />
              ),
            }
          : undefined,
        banners && Object.entries(banners).length
          ? {
              id: 'campaignBanners',
              element: (
                <CampaignBanners banners={banners} onClose={onCloseBanner} />
              ),
            }
          : undefined,
        showMonetizationBanner
          ? {
              id: 'monetizationBanner',
              element: (
                <MonetizationBanner
                  onClose={closeBanner}
                  isCountrySingleTier={isCountrySingleTier}
                />
              ),
            }
          : undefined,
        showMobileBanner
          ? {
              id: 'mobileBanner',
              element: <MobileBanner setOpen={setOpen} />,
            }
          : undefined,
        showChromeExtensionBanner
          ? {
              id: 'chromeExtensionBanner',
              element: <ChromeExtensionBanner />,
            }
          : undefined,
      ]

  const freeTrialIndicatorBanners = [
    showReverseTrialCountdownBanner
      ? {
          id: 'ReverseTrialCountdownBanner',
          element: (
            <ReverseTrialCountdownBanner
              dueDate={trialExpirationDate}
              handleUserCloseCountdownBanner={handleUserCloseCountdownBanner}
            />
          ),
        }
      : null,
  ]

  const bannersToShow = [...freeTrialIndicatorBanners, ...commonBanners]
    .filter(Boolean)
    .slice(0, BANNERS_LIMIT) as { id: string; element: JSX.Element }[]

  return { bannersToShow }
}
