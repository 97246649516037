import { useSelector } from 'react-redux'
import { useAuth } from '@hooks/useAuth'
import { AppState } from '../app/models/appState'

export const useSharedFolder = () => {
  const { user } = useAuth()
  const allFolders = useSelector((state: AppState) => state.folders)
  const sharedFolderState = useSelector(
    (state: AppState) => state.app.sharedFolder,
  )

  const foundCompositeBoardId = Object.keys(allFolders).find((x) => {
    const [, folderId] = x.split('-')
    const [, sharedBoardFolderId] = sharedFolderState.boardId.split('-')
    return folderId.includes(sharedBoardFolderId)
  })

  const isUserOwnerOfSharedBoard =
    user?.uid === foundCompositeBoardId?.split('-')[0]

  const isSharedFolderExistsInFolders = Boolean(foundCompositeBoardId)

  // if copy is allowed than user can add the same board/folder to account
  const showSharedFolderForExistingFolders =
    sharedFolderState.copyBoardAllowed || !isSharedFolderExistsInFolders

  const showSharedFolder = Boolean(
    sharedFolderState.shortcutId?.length > 0 &&
      sharedFolderState.boardId?.length > 0 &&
      (sharedFolderState?.folderId?.length ?? 0) > 0 &&
      user &&
      showSharedFolderForExistingFolders,
  )

  return {
    sharedFolderState,
    isSharedFolderExistsInFolders,
    foundCompositeBoardId,
    showSharedFolder,
    isUserOwnerOfSharedBoard,
  } as const
}
