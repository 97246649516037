import {
  FC,
  MutableRefObject,
  useRef,
  memo,
  useCallback,
  useEffect,
} from 'react'
import { AnimatedDialog, theme } from 'boards-web-ui'

import { ModalEditWithConfirmationAndTitle } from '../../../../components/ModalEditWithConfirmation'

import { useEditForms } from '../hooks'

import EditFormsHeaderControls from './EditFormsHeaderControls'
import EditFormsFooterControls from './EditFormsFooterControls'
import EditFormBody from './EditFormBody'
import styles from './EditForms.module.css'

const EditFormsDialog: FC = () => {
  const ref = useRef() as MutableRefObject<HTMLInputElement>
  const bottomElementRef = useRef() as MutableRefObject<HTMLInputElement>
  const { onFormCancel, action } = useEditForms()

  const scrollTo = useCallback(
    (position: number = ref?.current?.scrollHeight || 0) => {
      const timer = setTimeout(() => {
        if (ref.current) {
          // eslint-disable-next-line
          ref.current.scrollTop = position
        }
        clearTimeout(timer)
      }, 50)
    },
    [ref],
  )

  useEffect(() => {
    scrollTo(0)
  }, [action?.id, scrollTo])

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: theme.theme.colors.dialogBackgroundDark90,
        onOverlayClick: onFormCancel,
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          ref={ref}
          headerControls={<EditFormsHeaderControls onCancel={onFormCancel} />}
          footerControls={<EditFormsFooterControls />}
          className={styles.EditFormRoot}
          responsiveHeight
          withBorders
        >
          <div className={styles.FormWrapper}>
            <EditFormBody onAfterAddAdditionalField={scrollTo} />
            <div ref={bottomElementRef} />
          </div>
        </ModalEditWithConfirmationAndTitle>
      }
    />
  )
}

export default memo(EditFormsDialog)
