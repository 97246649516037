export type ActionHead = {
  folder: string
  action: string
  actor: string
  function?: string
  node?: string
}
export type BoardAction = {
  id: string
  undoHead?: ActionHead
  redoHead?: ActionHead
}

type BoardOptions = {
  copyAllowed: boolean
  shareAllowed: boolean
  insightsAllowed: boolean
  writeAllowed: boolean
  shareFolderAllowed: boolean
  notCountedTowardsLimit: boolean
  formsAllowed: boolean
  checkoutAllowed: boolean
}

export enum BoardType {
  PAGES = 'pages',
}

export type Board = {
  isPageBoard: boolean
  id: string
  rootFolderId: string
  icon: string
  title: string
  isOwn: boolean
  isActive?: boolean
  receivedContent: boolean
  action: BoardAction
  options: BoardOptions
  key?: string
  summary: {
    messages: {
      threads: number
      total: number
    }
    permissions: {
      read: number
      share: number
      total: number
      write: number
    }
  }
}
