import { useCallback } from 'react'

import { useLogEvents } from '@features/analytics/useLogEvents'
import {
  AnalyticsNodeType,
  EventCategories,
} from '@features/analytics/models/logEvents'
import { NodeType } from 'app/models'

export enum PickerActionTypeEnum {
  ADD = 'add',
  REPLACE = 'replace',
}

export enum PickerClickValueEnum {
  GALLERY = 'gallery',
  BOARD = 'board',
}

interface FileOrNodePickerLoadProps {
  boardId: string
  contentType: NodeType
  type: PickerActionTypeEnum
}

interface FileOrNodePickerClickProps {
  clickValue: PickerClickValueEnum
}

interface SourcePickerChooseBoardProps {
  boardId: string
}

interface SourcePickerChooseFileProps {
  type: PickerActionTypeEnum
  keyId: string
}

const useMediaPickerEvents = () => {
  const { trigger } = useLogEvents()

  const mediaSourcePickerLoadEvent = useCallback(
    ({ boardId, type, contentType }: FileOrNodePickerLoadProps) =>
      trigger(EventCategories.APP_MEDIA_SOURCE_PICKER_LOAD, {
        board_id: boardId,
        content_type: AnalyticsNodeType[contentType],
        type,
      }),
    [trigger],
  )

  const mediaSourcePickerClickEvent = useCallback(
    ({ clickValue }: FileOrNodePickerClickProps) =>
      trigger(EventCategories.APP_MEDIA_SOURCE_PICKER_CLICK, {
        click_value: clickValue,
      }),
    [trigger],
  )

  const sourcePickerChooseBoardEvent = useCallback(
    ({ boardId }: SourcePickerChooseBoardProps) =>
      trigger(EventCategories.APP_SOURCE_PICKER_CHOOSE_BOARD, {
        board_id: boardId,
      }),
    [trigger],
  )

  const mediaPickerClickEvent = useCallback(
    ({ type, keyId }: SourcePickerChooseFileProps) =>
      trigger(EventCategories.APP_MEDIA_PICKER_CLICK, {
        type,
        keyId,
      }),
    [trigger],
  )

  return {
    mediaSourcePickerLoadEvent,
    mediaSourcePickerClickEvent,
    sourcePickerChooseBoardEvent,
    mediaPickerClickEvent,
  } as const
}

export default useMediaPickerEvents
