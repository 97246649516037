import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { QUERY_PARAMS } from '../app/constants/routes'
import { CUSTOMER_IO_LINK_TRACKING_URL } from '../api/CustomerIoAPI'

export const trackCioLink = async (linkId: string): Promise<void> => {
  const trackingUrl = `${CUSTOMER_IO_LINK_TRACKING_URL}/${linkId}`
  await fetch(trackingUrl, {
    method: 'POST',
  })
}

const useCioLinks = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const linkId = useMemo(
    () => searchParams.get(QUERY_PARAMS.cioLinkId),
    [searchParams],
  )

  const removeCioSearchParams = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.cioLinkId)
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (!linkId) {
      return
    }

    trackCioLink(linkId)
      .catch(() => {
        // No action required; error is logged in monitoring tools.
      })
      .finally(() => {
        removeCioSearchParams()
      })
  }, [linkId, removeCioSearchParams])
}

export default useCioLinks
