import { useLayoutEffect } from 'react'

const useBodyResizeHandler = (
  handler: (width?: number, height?: number) => void,
  triggerOnMount: boolean = false,
) => {
  useLayoutEffect(() => {
    function trigger() {
      handler(window.innerWidth, window.innerHeight)
    }

    if (triggerOnMount) {
      trigger()
    }

    window.addEventListener('resize', trigger)
    return () => window.removeEventListener('resize', trigger)

    // eslint-disable-next-line
  }, [])
}

export default useBodyResizeHandler
