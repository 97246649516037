import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

export const useAttachContentToMessagesEvents = () => {
  const { trigger } = useLogEvents()

  const contentAttachClickEvent = ({ boardId }: { boardId: string }) => {
    trigger(EventCategories.APP_POST_MESSAGE_ATTACH_CONTENT_CLICK, {
      board_id: boardId,
    })
  }

  const messageAttachClickEvent = ({ boardId }: { boardId: string }) => {
    trigger(EventCategories.APP_START_WRITE_MESSAGE_ATTACH_CLICK, {
      board_id: boardId,
    })
  }

  const contentAttachedEvent = ({
    boardId,
    keyId,
    fileType,
  }: {
    boardId: string
    fileType: string[]
    keyId: string
  }) => {
    trigger(EventCategories.APP_POST_MESSAGE_CONTENT_ATTACHED, {
      key_attached_count: fileType.length,
      board_id: boardId,
      key_type: fileType,
      key_id: keyId,
    })
  }

  return {
    contentAttachClickEvent,
    contentAttachedEvent,
    messageAttachClickEvent,
  }
}
