export const PLAN_NAMES = {
  business: 'business-plan',
  free: 'free',
  reverseTrial: 'free-plan',
}

export const REVERSE_TRIAL_LOCAL_STORAGE_KEY = 'reverseTrialUserPreferences'

export enum USER_PREFERENCE_FIELDS {
  countdown = 'hasUserClosedCountdownTimer',
  toastMessage = 'shouldShowReverseTrialToastMessage',
}

export type ReverseTrialLocalStorageData = {
  userId: string
  [USER_PREFERENCE_FIELDS.countdown]?: boolean
  [USER_PREFERENCE_FIELDS.toastMessage]?: boolean
}

export type ReverseTrialUserFlags = {
  daysAvailable?: number
  daysReceived?: number
}

export type FirebaseTimestamp = {
  seconds: number
  nanoseconds: number
}

export type ActivationFirebase = {
  expires?: FirebaseTimestamp
  isReverseTrial?: boolean
  plan: string
}

export type Activation = {
  expires?: Date
  isReverseTrial?: boolean
  plan: string
}

export type DaysLeftIndicatorData = {
  daysLeft: number
  shouldShowDays: boolean
}

export type ReverseTrialContextProps = {
  trialExpirationDate: Date | undefined
  trialDaysAvailable: number | undefined
  trialDaysReceived: number | undefined
  handleUserCloseCountdownBanner: () => void
  shouldShowStartTrialScreen: boolean
  shouldShowEndOfTrialScreen: boolean
  shouldShowCountdownBanner: boolean
  shouldShowSubscribeInPlansButton: boolean
  shouldShowFreeTrialIndicator: boolean
  shouldShowFreePlanIndicator: boolean
  getDaysLeftIndicatorData: () => DaysLeftIndicatorData
  handleStartReverseTrial: (callback?: () => void) => void
  disableEndOfReverseTrialScreen: () => void
  isFreePlanUser: boolean
  isUserInTrialMode: boolean
  isRemoteConfigLoading: boolean
  reverseTrialStartDaysThreshold: number
}
