import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { ButtonNew } from 'boards-web-ui'

import { SimpleDialog } from './SimpleDialog'

import styles from './ConfirmDialog.module.css'

type Props = {
  icon?: ReactNode
  title: string
  message?: ReactNode | string
  cancelText?: string
  confirmText: string
  confirmButtonType?: 'primary' | 'secondary' | 'warning'
  className?: string
  stacked?: boolean
  onCancel?: () => void
  onConfirm: () => void
}
const ConfirmDialog: React.FC<Props> = ({
  icon,
  title,
  message,
  cancelText,
  confirmText,
  confirmButtonType,
  className,
  stacked,
  onCancel,
  onConfirm,
}) => {
  return (
    <SimpleDialog
      className={clsx(styles.Root, className)}
      icon={icon}
      title={title}
      message={
        message ? <p className={styles.Centred}>{message}</p> : undefined
      }
    >
      <div className={clsx(styles.Controls, stacked && styles.StackedControls)}>
        {cancelText && (
          <ButtonNew
            color="secondary"
            size="large"
            onClick={onCancel}
            fullWidth={stacked}
          >
            {cancelText}
          </ButtonNew>
        )}
        <ButtonNew
          color={confirmButtonType || 'primary'}
          size="large"
          onClick={onConfirm}
          fullWidth={stacked}
        >
          {confirmText}
        </ButtonNew>
      </div>
    </SimpleDialog>
  )
}

export default ConfirmDialog
