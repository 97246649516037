import { FC, ReactElement } from 'react'
import { ContentPickersEnum } from '@features/pickers'
import { PickerModal } from '@ui/components/PickerModal'

import { SelectContentDialogBody } from './SelectContentDialogBody'
import { SelectContentDialogHeader } from './SelectContentDialogHeader'
import { Node, NodeType } from '../../../models'

interface Props {
  dialogCategory: string | ReactElement
  enabledNodeTypes: NodeType[]
  action: (node: Node) => void
  onClose: () => void
  onSelectBoard?: (boardId: string) => void
}

export const SourcePickerSingleSelectionModal: FC<Props> = ({
  dialogCategory,
  enabledNodeTypes,
  action,
  onClose,
  onSelectBoard,
}) => (
  <PickerModal
    picker={ContentPickersEnum.SourcePicker}
    header={<SelectContentDialogHeader category={dialogCategory} />}
    onClose={onClose}
  >
    <SelectContentDialogBody
      nodes={[]}
      toggleNode={action}
      enabledNodeTypes={enabledNodeTypes}
      onSelectBoard={onSelectBoard}
    />
  </PickerModal>
)
