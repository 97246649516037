import clsx from 'clsx'
import NodeSelectionRadio from './components/NodeSelectionRadio'

import styles from './NodeSelection.module.css'

export interface NodeSelectionProps {
  isSelected: boolean
  withMargin?: boolean
  withRadioBtn?: boolean
  showRadioBtnOnHover?: boolean
  withDarkOverlay?: boolean
  disabled?: boolean
  onClick?: () => void
  onSelect?: () => void
  children: React.ReactElement
}

const NodeSelection = ({
  disabled,
  isSelected,
  withMargin,
  withRadioBtn,
  withDarkOverlay,
  showRadioBtnOnHover,
  onSelect,
  onClick,
  children,
}: NodeSelectionProps) => {
  return (
    <div
      className={clsx([
        styles.Root,
        styles.WithOverlay,
        disabled && styles.Disabled,
        isSelected && styles.Selected,
        withMargin && styles.WithMargin,
        withDarkOverlay && styles.WithDarkOverlay,
        showRadioBtnOnHover && styles.ShowRadioBtnOnHover,
      ])}
    >
      <div className={styles.Overlay} onClick={onClick} />
      {children}
      {onClick && withRadioBtn && (
        <NodeSelectionRadio
          className={styles.Radio}
          classNameUnselected={styles.RadioUnselected}
          classNameUnselectedHover={styles.RadioUnselectedHover}
          selected={isSelected}
          onClick={onClick}
          onSelect={onSelect}
        />
      )}
    </div>
  )
}

export default NodeSelection
