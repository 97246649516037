import { FC, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import {
  ButtonNew,
  Grid,
  Overlay,
  PremiumCrownIcon,
  PremiumIndicationIcon,
  Spinner,
} from 'boards-web-ui'
import { useWindowSize } from 'usehooks-ts'

import Carousel from '@features/templateGallery/components/Carousel'
import MenuItem from '@features/templateGallery/components/MenuItem'
import Template from '@features/templateGallery/components/Template'
import { TemplateCategoryModel } from '@features/templateGallery/models/Template'
import useTemplatePickerEvents from '@features/analytics/useTemplatePickerEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { getTemplateIdUpdatedUrl } from '@features/templateGallery/helpers/templateUrlHelper'
import { IconWrapper } from '@ui/components/IconWrapper'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'

import { CSS_TRANSITION_TIME } from 'constants/CSSTransitionTime'
import Box from 'elements/Box'
import { QUERY_PARAMS } from '../../../constants/routes'
import { Folder } from '../../../models'

import styles from './TemplateGallery.module.css'

const TEMPLATE_PREVIEW_ASPECT_RATIO = 0.509 // Aspect ratio of preview thumbnail
const FIXED_UI_HEIGHT = 370 // Height of fixed UI part in pixels
const MIN_HEIGHT = 300 // Min height for thumbnails
const MAX_HEIGHT = 601 // Max height for thumbnails

export const findCategoryByTemplate = (
  templateId: string,
  allCategories: TemplateCategoryModel[],
): TemplateCategoryModel | undefined => {
  return allCategories.find((categoryItem) =>
    categoryItem.templates.some((template) => template.id === templateId),
  )
}

interface Props {
  isLoading: boolean
  isCopyingInProgress: boolean
  isPagesBoardRoute: boolean
  boardId: string
  folderId: string
  allCategories: TemplateCategoryModel[]
  onCloseTemplateGallery: () => void
  onStartFromScratchClick: () => void
  onCopyTemplateToBoard: (
    selectedPage: Folder,
    templateCategory: TemplateCategoryModel,
  ) => void
}
const TemplateGallery: FC<Props> = ({
  isLoading,
  isCopyingInProgress,
  isPagesBoardRoute,
  boardId,
  folderId,
  allCategories,
  onCloseTemplateGallery,
  onStartFromScratchClick,
  onCopyTemplateToBoard,
}) => {
  const {
    profile: { plan },
  } = useProfileInformation()
  const { pageTemplateCategoryClickEvent } = useTemplatePickerEvents()
  const navigate = useNavigate()
  const { templateId: currentTemplateId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentCategoryId = searchParams.get(QUERY_PARAMS.category)
  const [show, setShow] = useState(false)

  const { height = 0 } = useWindowSize()

  const availableHeight = Math.max(height - FIXED_UI_HEIGHT, MIN_HEIGHT)
  const thumbnailHeight = MAX_HEIGHT < availableHeight ? MAX_HEIGHT : availableHeight;
  const itemWidth = Math.round(thumbnailHeight * TEMPLATE_PREVIEW_ASPECT_RATIO)
  const itemHeight = Math.round(itemWidth / TEMPLATE_PREVIEW_ASPECT_RATIO)

  const currentCategory = useMemo(
    () => allCategories?.find((c) => c.id === currentCategoryId),
    [allCategories, currentCategoryId],
  )

  const allTemplatesOfCurrentCategory = useMemo(
    () => currentCategory?.templates || [],
    [currentCategory],
  )

  useEffect(() => {
    setShow(true)
  }, [setShow])

  /** Set initial category */
  useEffect(
    () => {
      if (!allCategories?.length) return

      const foundCategoryByTemplate = currentTemplateId
        ? findCategoryByTemplate(currentTemplateId, allCategories)
        : undefined

      const categoryIdToSet = foundCategoryByTemplate
        ? foundCategoryByTemplate.id
        : currentCategoryId || allCategories[0]?.id

      updateSearchParams([
        {
          key: QUERY_PARAMS.category,
          value: categoryIdToSet || '',
        },
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCategories],
  )

  const updateSearchParams = (
    params: {
      key: string
      value: string
    }[],
  ): void => {
    const updatedParams = new URLSearchParams(searchParams)

    params.forEach(({ key, value }) => {
      updatedParams.set(key, value)
    })

    setSearchParams(updatedParams)
  }

  const handleCategoryClick = (templateCategory: TemplateCategoryModel) => {
    setSearchParams({ [QUERY_PARAMS.category]: templateCategory.id })
    pageTemplateCategoryClickEvent(templateCategory.nameFormatted)
  }

  return (
    <div>
      <CSSTransition
        in={show}
        classNames="create-page"
        timeout={CSS_TRANSITION_TIME}
      >
        <Overlay index={-9} position="fixed" color={'rgba(0, 0, 0, 0.2)'}>
          {(isLoading || isCopyingInProgress) && (
            <div className={styles.SpinnerBackground} />
          )}
          <div className={styles.Root}>
            <div className={styles.Header}>
              <CloseButtonWithTooltip
                onClose={() => {
                  setShow(false)
                  setTimeout(() => {
                    onCloseTemplateGallery()
                  }, CSS_TRANSITION_TIME)
                }}
                gray
              />
            </div>
            <Grid>
              <h1 className={styles.Title}>
                <Trans i18nKey={'page_template_gallery_header'} />{' '}
              </h1>
              {!plan.features.themes && (
                <IconWrapper size={22}>
                  <PremiumIndicationIcon />
                </IconWrapper>
              )}
            </Grid>
            <h4 className={styles.SubTitle}>
              <Trans i18nKey={'page_template_gallery_subtitle'} />
            </h4>
            <div className={styles.Menu}>
              {allCategories?.map((templateCategory) => (
                <MenuItem
                  key={templateCategory.id}
                  active={currentCategoryId === templateCategory.id}
                  onClick={() => handleCategoryClick(templateCategory)}
                >
                  {templateCategory.nameFormatted}
                </MenuItem>
              ))}
            </div>
            <div
              className={styles.CarouselWrapper}
              style={{ height: itemHeight }}
            >
              <Spinner
                className={styles.Spinner}
                size="large"
                spinning={isLoading || isCopyingInProgress}
              >
                <Carousel itemWidth={itemWidth}>
                  {currentCategory &&
                    allTemplatesOfCurrentCategory.map((template) => (
                      <Template
                        key={template.id}
                        template={template}
                        itemWidth={itemWidth}
                        itemHeight={itemHeight}
                        onClick={() => {
                          navigate(
                            getTemplateIdUpdatedUrl({
                              templateId: template.id,
                              searchParams,
                              isPagesBoardRoute,
                              boardId,
                              folderId,
                            }),
                          )
                        }}
                      />
                    ))}
                </Carousel>
              </Spinner>
            </div>

            <Box mt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
              <span className={styles.BottomDescriptionText}>
                <Trans
                  i18nKey={
                    'page_template_gallery_start_from_scratch_header_desktop'
                  }
                />
              </span>
            </Box>
            <Box mt={20} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonNew
                color={'secondary'}
                size={'large'}
                startIcon={
                  !plan.features.themes && (
                    <IconWrapper>
                      <PremiumCrownIcon />
                    </IconWrapper>
                  )
                }
                onClick={onStartFromScratchClick}
              >
                <Trans i18nKey={'page_template_gallery_start_from_scratch'} />
              </ButtonNew>
            </Box>
          </div>
        </Overlay>
      </CSSTransition>
      <Outlet
        context={{
          category: currentCategory,
          allTemplates: allTemplatesOfCurrentCategory,
          templateId: currentTemplateId || '',
          isPagesBoardRoute,
          boardId,
          folderId,
          plan,
          onCopyTemplateToBoard,
        }}
      />
    </div>
  )
}

export default TemplateGallery
