import { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField, Spacer } from 'boards-web-ui'

import useFocusState from '@hooks/useFocusState'

import { useCheckout } from '../hooks'

import styles from './CheckoutResponses.module.css'

const CheckoutResponsesEmail: FC = () => {
  const { t } = useTranslation()
  const {
    isSubmitted,
    responsesEmail,
    responsesEmailError,
    onResponsesEmailChange,
  } = useCheckout()
  const { isFocused, onBlur, ...focusHandlers } = useFocusState()

  const [responsesEmailState, setResponsesEmailState] = useState<string>(
    responsesEmail || '',
  )

  useEffect(() => {
    setResponsesEmailState(responsesEmail || '')
  }, [responsesEmail])

  return (
    <div className={styles.Responses}>
      <h3 className={styles.SectionTitle}>
        {t('forms_collect_responses_title')}
      </h3>

      <Spacer height={15} />
      <InputField
        redVariant={isSubmitted && !isFocused && !!responsesEmailError}
        value={responsesEmailState}
        label={t('forms_responses_will_be_sent_to')}
        placeholder={t('button_email_button_placeholder')}
        errorMessage={isSubmitted && !isFocused ? responsesEmailError : ''}
        onChange={(e) => setResponsesEmailState(e.target.value)}
        onBlur={(e) => {
          onBlur()
          if (e.target.value !== responsesEmail) {
            onResponsesEmailChange(e.target.value)
          }
        }}
        {...focusHandlers}
        withResetButton
      />
    </div>
  )
}

export default memo(CheckoutResponsesEmail)
