import { ReactNode, useMemo, useState } from 'react'
import { ProductData } from '@features/payments/utils/productTransformer'
import { useTranslation } from 'react-i18next'
import { calculateDiscount } from '@features/payments/utils/price'
import { TermWithDiscount } from '@features/payments/components/TermWithDiscount'
import usePaywallEvents from '@features/analytics/usePaywallEvents'

type TUsePaywallPlanTermSelection<T = string | number> = (params: {
  items?: ProductData[]
  currencySymbol?: string
}) => {
  currentPlan: ProductData | undefined
  selectedProductId: T
  termOptions: {
    label: ReactNode
    value: T
  }[]
  isMonthlyTermSelected: boolean
  discountForPlan: number
  billedText: string | undefined
  handleTermChange: (params: T) => void
}

export const usePaywallPlanTermSelection: TUsePaywallPlanTermSelection = ({
  items = [],
  currencySymbol,
}) => {
  const { t } = useTranslation()
  const [selectedTermState, setSelectedTermState] = useState<string | number>(
    '',
  )
  const oneMonthTermProductId = items[0]?.subscription?.productId
  const yearlyTermProduct = items.find(
    ({ subscription }) => subscription.period === 'year',
  )

  const selectedProductId =
    selectedTermState ||
    yearlyTermProduct?.subscription.productId ||
    oneMonthTermProductId

  const findCurrentPlanByProductId = (
    productId: string | number,
  ): ProductData | undefined =>
    items?.find(({ subscription }) => subscription.productId === productId)

  const currentPlan = findCurrentPlanByProductId(selectedProductId)

  const discountForPlan = useMemo(
    () =>
      calculateDiscount(currentPlan?.annualPrice || 0, items[0]?.price || 0),
    [currentPlan?.annualPrice, items],
  )

  const getTermEventName = (productId: string | number) => {
    const selectedPlan = findCurrentPlanByProductId(productId)

    if (selectedPlan?.subscription.period === 'year') {
      return 'yearly'
    }

    const periods = selectedPlan?.subscription?.numberOfPeriods
    return periods ? `${periods}_months` : 'monthly'
  }

  const termOptions = useMemo(() => {
    return items?.map(({ subscription, annualPrice }) => {
      const discount = calculateDiscount(annualPrice, items[0]?.price || 0)
      if (subscription.period === 'year') {
        return {
          label: (
            <TermWithDiscount
              text={t('yearly_toggle_label')}
              discount={discount}
            />
          ),

          value: subscription.productId,
        }
      }

      return {
        label: subscription.numberOfPeriods ? (
          <TermWithDiscount
            text={t('paywall_three_months_plan', {
              numberOfPeriods: subscription.numberOfPeriods,
            })}
            discount={discount}
          />
        ) : (
          t('monthly_toggle_label')
        ),
        value: subscription.productId,
      }
    })
  }, [items, t])

  const isMonthlyTermSelected = selectedProductId === oneMonthTermProductId

  const getBilledText = () => {
    const isYearlyTermSelected =
      selectedProductId === yearlyTermProduct?.subscription.productId

    if (isYearlyTermSelected) {
      return t('trial_paywall_general_yearly_additional_text', {
        currency: currencySymbol,
        price: currentPlan?.annualPrice,
      })
    }

    if (isMonthlyTermSelected) {
      return t('trial_paywall_velentines_marathon_monthly_additional_text')
    }

    return t('paywall_billed_three_months')
  }

  const { paywallCycleClickEvent } = usePaywallEvents()

  const handleTermChange = (productId: string | number) => {
    setSelectedTermState(productId)
    paywallCycleClickEvent(getTermEventName(productId))
  }

  return {
    currentPlan,
    selectedProductId,

    isMonthlyTermSelected,
    termOptions,
    discountForPlan,
    billedText: getBilledText(),
    handleTermChange,
  }
}
