import { useSelector } from 'react-redux'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppClientConfig } from '@hooks/useAppClientConfig'
import { UserAcademyType } from '@models/UserAcademy'
import { useThrottle } from '@hooks/useThrottle'
import { useToast } from '@hooks/useToast'
import { useAcademyPageErrorModal } from '@features/academy/AcademyPageErrorModal/useAcademyPageErrorModal'
import { random } from '@helpers/Base62'
import { useLogEvents } from '@features/analytics/useLogEvents'
import { AppState } from '../../models/appState'
import FirebaseAuthContext from '../../../_firebase/providers/FirebaseAuthContext'
import { CLIENT_CONFIG_FEATURES } from '../../models/clientConfig'
import { setAcademyProgress } from '../../../_firebase'

export const useIsAcademyEnabled = () => {
  const academyClientConfig =
    useAppClientConfig()?.[CLIENT_CONFIG_FEATURES.ACADEMY]

  return { isAcademyEnabled: Boolean(academyClientConfig?.isEnabled) }
}

const DELAY = 20 * 1000
const SUCCESS_LOAD_TIMEOUT = 5 * 1000
const HASH_LENGTH = 10
const IFRAME_DATA_EVENT = 'message'
const IFRAME_MOCKED_SRC = 'https://academy.boards.com'

enum IframeSearchParams {
  isDesktopApp = 'isDesktopApp',
  InitUserData = 'initUserData',
}

enum AcademyActionType {
  Progress = 'PROGRESS',
  Analytics = 'ANALYTICS',
}

export const useAcademyPage = () => {
  const toast = useToast()
  const { trigger } = useLogEvents()
  const academyClientConfig =
    useAppClientConfig()?.[CLIENT_CONFIG_FEATURES.ACADEMY]

  const { t } = useTranslation()
  const { isLoggedIn } = useContext(FirebaseAuthContext)
  const [iframeKeyHash, setIframeKeyHash] = useState<string | null>(
    random(HASH_LENGTH),
  )

  const handleReloadIframe = () => {
    setIframeKeyHash(random(HASH_LENGTH))
  }
  const { openAcademyPageErrorModal } = useAcademyPageErrorModal({
    onReloadClick: handleReloadIframe,
  })

  // the onload event can be triggered after postMessage is received
  // that is why we need this extra flag
  const [isLoaded, setIsLoaded] = useState(false)
  const successLoadTimout = useRef<null | number>(null)

  const { progress } = useSelector((state: AppState) => state.app.academy)

  const [progressState, setProgressState] = useState<
    UserAcademyType | undefined
  >()
  const throttledProgress = useThrottle<UserAcademyType | undefined>(
    progressState,
    DELAY,
  )

  useEffect(() => {
    if (!throttledProgress) {
      return
    }
    setAcademyProgress(throttledProgress)
  }, [throttledProgress, progressState])

  useEffect(() => {
    const handleClearTimeout = () => {
      if (successLoadTimout.current) {
        clearTimeout(successLoadTimout.current)
      }
    }

    const handler = (event: MessageEvent) => {
      try {
        const parsedData =
          typeof event?.data === 'string' ? JSON.parse(event.data) : event?.data

        if (parsedData?.loaded === true) {
          setIsLoaded(true)

          handleClearTimeout()
        }

        if (parsedData?.actionType === AcademyActionType.Progress) {
          setProgressState({ progress: parsedData?.actionData?.userProgress })
        }

        if (parsedData?.actionType === AcademyActionType.Analytics) {
          if (parsedData?.actionData.event) {
            const props = parsedData?.actionData.properties || {}
            trigger(parsedData.actionData.event, props)
          }
        }
      } catch {
        toast(t('optimistic_ui_failed'))
      }
    }

    window.addEventListener(IFRAME_DATA_EVENT, handler)

    return () => {
      window.removeEventListener(IFRAME_DATA_EVENT, handler)
      handleClearTimeout()
    }
  }, [successLoadTimout, t, toast, trigger])

  const iframeSrc = useRef<string | null>(null)

  useEffect(() => {
    if (iframeSrc.current) {
      return
    }

    const urlBase =
      process.env.NODE_ENV === 'development'
        ? IFRAME_MOCKED_SRC
        : academyClientConfig?.url

    const initParams = `${IframeSearchParams.InitUserData}=${encodeURIComponent(
      JSON.stringify({
        isLoggedIn,
        userProgress: {
          ...progress,
        },
      }),
    )}`

    iframeSrc.current = `${urlBase}/?${IframeSearchParams.isDesktopApp}=true&${initParams}`
  }, [academyClientConfig?.url, isLoggedIn, progress])

  const handleIframeLoad = useCallback(() => {
    if (isLoaded) {
      successLoadTimout.current = null
      return
    }

    successLoadTimout.current = window.setTimeout(() => {
      openAcademyPageErrorModal()
    }, SUCCESS_LOAD_TIMEOUT)
  }, [openAcademyPageErrorModal, isLoaded])

  return {
    iframeSrc: iframeSrc.current,
    handleReloadIframe,
    handleIframeLoad,
    iframeKeyHash,
    isLoaded,
  }
}
