import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckoutFormProducts,
  CheckoutFormProduct,
  Icon,
  DeleteBigIcon,
} from 'boards-web-ui'

import { newKey } from '@helpers/NodeIdGenerator'
import useConfirmDialog from '@hooks/useConfirmDialog'

import { DEFAULT_NEW_PRODUCT } from '../constants'

import styles from '../components/CheckoutProducts.module.css'

const useCheckoutProducts = (
  initialState: CheckoutFormProducts = [DEFAULT_NEW_PRODUCT],
) => {
  const { t } = useTranslation()
  const confirmDialog = useConfirmDialog()

  const [products, setProducts] = useState<CheckoutFormProducts>(initialState)
  const isProductsValid = useMemo<boolean>(
    () =>
      !!products.length &&
      products.every((product) => Number(product.maxQuantity)),
    [products],
  )

  const onProductsReOrder = useCallback(
    (productsInNewOrder: CheckoutFormProducts) =>
      setProducts(productsInNewOrder),
    [setProducts],
  )

  const onProductAdd = useCallback(
    (
      product: CheckoutFormProduct = { ...DEFAULT_NEW_PRODUCT, id: newKey() },
    ) => {
      setProducts((_products) => [..._products, product])
      return product
    },
    [setProducts],
  )

  const onProductDelete = useCallback(
    (product: CheckoutFormProduct) => {
      confirmDialog({
        icon: (
          <Icon className={styles.DeleteProductIcon}>
            <DeleteBigIcon />
          </Icon>
        ),
        className: styles.DeleteProductPopUp,
        title: t('product_delete_dialog_title'),
        message: t('product_delete_dialog_subtitle'),
        cancelText: t('action_cancel'),
        confirmText: t('action_delete'),
        confirmButtonType: 'warning',
        onConfirm: () => {
          setProducts((_products) =>
            _products.filter((_product) => _product.id !== product.id),
          )
        },
      })
    },
    [confirmDialog, t, setProducts],
  )

  const onProductChange = useCallback(
    (product: Partial<CheckoutFormProduct>) => {
      if (!product) {
        return
      }

      setProducts((_products) =>
        _products.map((_product) =>
          _product.id === product.id
            ? {
                ..._product,
                ...product,
              }
            : _product,
        ),
      )
    },
    [setProducts],
  )

  return useMemo(
    () => ({
      products,
      isProductsValid,
      onProductsReOrder,
      onProductAdd,
      onProductDelete,
      onProductChange,
    }),
    [
      products,
      isProductsValid,
      onProductsReOrder,
      onProductAdd,
      onProductDelete,
      onProductChange,
    ],
  )
}

export default useCheckoutProducts
